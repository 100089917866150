import rollbar from './rollbar'
import { environment } from '@mortgage-pos/ui/env'
import * as LDClient from 'launchdarkly-js-client-sdk'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { CustomError } from '@mortgage-pos/error-handling'

import {
  ffKey,
  clientConfig,
  FEATURE_FLAG_TYPES,
  LOCAL_FF_OVERRIDES,
  hasLocalOverrideFlag,
  DEFAULT_FEATURE_FLAGS,
} from '@mortgage-pos/data'

/** Set variable to default flags for testing */
let launchDarklyFlags = DEFAULT_FEATURE_FLAGS

export function useVariation(flag: string, defaultValue?: any) {
  const flags = useFlags()

  if (process.env.NODE_ENV === 'test' || process.env.STORYBOOK === 'true') {
    return launchDarklyFlags[flag]
  }

  if (hasLocalOverrideFlag(flag)) {
    return LOCAL_FF_OVERRIDES[flag]
  }

  let flagValue = flags[flag]

  if (flagValue === undefined && defaultValue !== undefined) {
    flagValue = defaultValue
  }

  return flagValue
}

/**
 * Launch Darkly client.
 * Private to this file. Access through variation.
 * Add other private funcs as necessary
 */
const ldClient: null | LDClient.LDClient =
  process.env.NODE_ENV === 'test'
    ? null
    : LDClient.initialize(
        environment.launchDarkly.clientId,
        clientConfig.user,
        clientConfig.options
      )

/** Use this function for variations instead of the default one provided by launch darkly.
 * Without it, there is a race condition between the instantiation of the variation and the return call
 * from launch darkly.
 *
 * Defaults all feature flags on in local development.
 */
export async function variation(
  flag: string
): Promise<LDClient.LDFlagValue | boolean> {
  if (process.env.NODE_ENV === 'test') {
    return launchDarklyFlags[flag]
  }

  if (hasLocalOverrideFlag(flag)) {
    return LOCAL_FF_OVERRIDES[flag]
  }

  try {
    await ldClient.waitForInitialization()

    return await ldClient.variation(flag, DEFAULT_FEATURE_FLAGS[flag])
  } catch (error) {
    const errorObject = new CustomError(
      'LDVariationError',
      'Failed to get flag'
    )

    const errorMetadata = {
      flag,
      sensitive: { error, DEFAULT_FEATURE_FLAGS },
    }

    rollbar.error(errorObject, errorMetadata)

    return DEFAULT_FEATURE_FLAGS[flag]
  }
}

export async function variationByKey(flagKey: ffKey) {
  const flag = FEATURE_FLAG_TYPES[flagKey]

  if (hasLocalOverrideFlag(flag)) {
    return LOCAL_FF_OVERRIDES[flag]
  }

  return await variation(flag)
}

/**
 * Initialized launch darkly client. Called once in main.tsx
 */
export async function initializeLD(): Promise<void> {
  if (process.env.NODE_ENV === 'test') {
    return
  }

  try {
    await ldClient.waitForInitialization()
  } catch (error) {
    const errorObject = new CustomError(
      'InitializeLDError',
      'Failed to initialize LaunchDarkly client'
    )

    const errorMetadata = {
      sensitive: { error, DEFAULT_FEATURE_FLAGS },
    }

    rollbar.error(errorObject, errorMetadata)

    throw error
  }
}

/** Testing utilities */

export function setFeatureFlags(testFlags) {
  launchDarklyFlags = { ...DEFAULT_FEATURE_FLAGS, ...testFlags }
}

export function resetFeatureFlags() {
  launchDarklyFlags = DEFAULT_FEATURE_FLAGS
}
