import sanitizeHtml from 'sanitize-html'
import { nameCase } from '@foundernest/namecase'

export function sanitizeString(string: string): string {
  if (typeof string !== 'string' || string.length === 0) {
    return string
  }
  // Remove all HTML tags
  return sanitizeHtml(string, {
    allowedTags: [],
    allowedAttributes: {},
  })
}

export function capitalizeFirstLetter(string) {
  if (typeof string !== 'string' || string.length === 0) {
    return string
  }

  return string[0].toUpperCase() + string.slice(1)
}

export function spaceOutPhoneDigits(string: string): string {
  return string
    .replace(/[^0-9]/gm, '') // Remove special chars
    .replace(/[0-9]/gm, '$& ') // Add a space between chars
}

/**
 * Sanitizes a string to be safe for use in filenames by removing or replacing invalid characters
 * @param input The string to sanitize
 * @returns A filename-safe string
 */
export function sanitizeFilename(input: string): string {
  if (!input) return ''

  // Replace characters that are problematic in filenames
  return input
    .trim()
    .replace(/[\\/:*?"<>|'`~!@#$%^&+={}[\]]/g, '') // Remove invalid filename chars
    .replace(/\s+/g, '-') // Replace spaces with hyphens
    .replace(/--+/g, '-') // Replace multiple hyphens with a single one
    .replace(/^-|-$/g, '') // Remove leading/trailing hyphens
}

/**
 * Normalizes a name by properly capitalizing it according to standard name capitalization rules.
 *
 * This function uses the @foundernest/namecase library which handles various name capitalization
 * patterns including:
 * - Standard first letter capitalization (daniel → Daniel)
 * - Special prefixes like Mc/Mac (mcDonalds → McDonalds)
 * - Compound names with hyphens (LEIGH-WILLIAMS → Leigh-Williams)
 * - Names with apostrophes (O'CALLAGHAN → O'Callaghan)
 * - Preserving capitalization in names like "TestCo" → "TestCo"
 *
 * @param name The name to normalize
 * @returns The normalized name
 */
export function normalizeName(
  name: string | null | undefined
): string | null | undefined {
  if (typeof name !== 'string' || name.length === 0) {
    return name
  }

  // First apply the nameCase library's normalization
  const normalized = nameCase(name)

  // Special case for company-like names with internal capitalization (e.g., "TestCo")
  // If the original name already had the pattern of first letter capitalized and
  // an internal capital letter, preserve it exactly as is
  if (/^[A-Z][a-z]+[A-Z][a-z]*$/.test(name)) {
    return name
  }

  return normalized
}
