export interface ReoEntry {
  street?: string
  apt?: string
  city?: string
  state?: string
  zipCode?: string
  propertyOwners?: string
  numberOfUnits?: number
}

export interface FillReoParams {
  applicationId: number
  realEstateOwned: ReoEntry[]
  realEstateOwnedIds?: number[]
}

export enum ReoOwnerType {
  Primary = 'Primary',
  CoBorrower = 'Co-Borrower',
  Both = 'Both',
  PrimaryAndOther = 'PrimaryAndOther',
}

export const allowedReoOwnerTypes = [
  'Primary',
  'Co-Borrower',
  'Both',
  'PrimaryAndOther',
] as const

export enum RealEstateOwnedType {
  Primary = 'Primary',
  Secondary = 'Secondary',
  Investment = 'Investment',
}
