import { z } from 'zod'
import { RealEstateOwnedType } from './RealEstateOwned'
import { LoanFileSection, LoanFileSubSection } from './LoanFileReview'
// Update available sections to include co‑borrower groups
export const loanFileReviewSections = [
  'Personal Info',
  'Contact Info',
  'Citizenship',
  'Military Status',
  'Family Details',
  'Addresses',
  'Additional Properties',
  'Loan Details - Property',
  'Loan Details - Loan',
  'Loan Details - AVM',
  'Loan Details - Pricing',
  'Loan Details - NTB',
  'Employment',
  'Assets',
  'Liabilities',
  'Declarations & Demographics',
  'Co-Borrower Personal Info',
  'Co-Borrower Contact Info',
  'Co-Borrower Citizenship',
  'Co-Borrower Military Status',
  'Co-Borrower Family Details',
] as const

export const loanFileReviewSection = z.enum(loanFileReviewSections)
export type LoanFileReviewSection = z.infer<typeof loanFileReviewSection>

// This list allows us to enforce that all expected labels are defined in the mapping
// as well as the validation step
const allowedLabels = [
  'First Name',
  'Last Name',
  'Alias',
  'Date of Birth',
  'First Time Home Buyer',
  'Email Address',
  'Phone Number',
  'Citizenship Status',
  'Country of Citizenship',
  'Military Service',
  'Military Details',
  'Military Tour Date',
  'Marital Status',
  // 'Legal Spouse Information',
  'Dependents',
  'Family Member',
  'Forbearance',
  'Current Residence',
  'Residence History',
  'Mailing Address',
  'Property Entries',
  'Investment Property Financials',
  'Current Primary Property',
  'Subject Property',
  'Property Type',
  'Property Use',
  'Property Location',
  'Address Validation',
  'Loan Purpose',
  'Property Value',
  'Cash Out Amount',
  'Remaining Balance',
  'Purchase Price',
  'Down Payment',
  'Own Business',
  'AVM Run',
  'Estimated Value',
  'Year Purchased',
  'Year Built',
  'Pricing Scenario',
  'Net Tangible Benefit',
  'Employment History',
  'Co-Borrower Employment History',
  'Co-Borrower Residence History',
  'Current Employment',
  'Other Income',
  'Asset Entries',
  'Credit Report',
  'Co-Borrower Credit Report',
  'Other Liabilities',
  'Borrower Declarations and Demographics',
  'Co-Borrower Declarations and Demographics',
  'E-Signatures',
  'Conditions',
  'Handoff Sheet',
  'Credit Report Available in Encompass',
] as const

export type AllowedLoanValidationLabel = typeof allowedLabels[number]

// Define field metadata
export interface FieldMetadata {
  label: AllowedLoanValidationLabel
  description: string
  section: LoanFileSection
  subSection?: LoanFileSubSection
  severity: 'error' | 'warning' | 'info' | 'aus-blocking' | 'lock-blocking'
  requiredCondition?: (data: any) => boolean
  helpText?: string
  path?: string // Path to the field in the application data
}

// Define the field mappings. Note: any duplicate field names in the service are merged into a single entry.
export const loanFileReviewFieldMappings: Record<
  AllowedLoanValidationLabel,
  FieldMetadata
> = {
  // Personal Info
  'First Name': {
    label: 'First Name',
    description: 'Primary borrower first name',
    section: 'borrower',
    subSection: 'personal-info',
    severity: 'error',
    path: 'first_name',
    helpText: 'First name is required for all applications',
    requiredCondition: () => true, // Always required
  },
  'Last Name': {
    label: 'Last Name',
    description: 'Primary borrower last name',
    section: 'borrower',
    subSection: 'personal-info',
    severity: 'error',
    path: 'last_name',
    helpText: 'Last name is required for all applications',
    requiredCondition: () => true, // Always required
  },
  // New mapping for borrower alias
  Alias: {
    label: 'Alias',
    description: 'Primary borrower alias',
    section: 'borrower',
    subSection: 'personal-info',
    severity: 'info',
    path: 'name_aliases',
    helpText: 'Alias can be used as an alternative name for identification',
    requiredCondition: () => false, // Not required
  },
  'Date of Birth': {
    label: 'Date of Birth',
    description: 'Primary borrower date of birth',
    section: 'borrower',
    subSection: 'personal-info',
    severity: 'error',
    path: 'date_of_birth',
    helpText: 'Date of birth is required for all applications',
    requiredCondition: () => true, // Always required
  },
  'First Time Home Buyer': {
    label: 'First Time Home Buyer',
    description: 'First time home buyer status',
    section: 'borrower',
    subSection: 'personal-info',
    severity: 'warning',
    path: 'first_time_home_buyer',
    requiredCondition: (data) =>
      data.loan_purpose?.toLowerCase().includes('purchase'),
    helpText: 'Required for purchase loans',
  },

  // Contact Info
  'Email Address': {
    label: 'Email Address',
    description: 'Primary borrower email',
    section: 'borrower',
    subSection: 'contact-info',
    severity: 'error',
    path: 'email',
    helpText: 'Valid email address is required',
    requiredCondition: () => true, // Always required
  },
  'Phone Number': {
    label: 'Phone Number',
    description: 'Primary borrower phone',
    section: 'borrower',
    subSection: 'contact-info',
    severity: 'error',
    path: 'phone',
    helpText: 'Valid phone number is required',
    requiredCondition: () => true, // Always required
  },

  // Citizenship
  'Citizenship Status': {
    label: 'Citizenship Status',
    description: 'Borrower citizenship status',
    section: 'borrower',
    subSection: 'citizenship',
    severity: 'warning',
    path: 'declaration_citizen_status',
    helpText: 'Required for all borrowers',
    requiredCondition: () => false, // Required, but handled by presence check in service
  },
  'Country of Citizenship': {
    label: 'Country of Citizenship',
    description: 'Country of citizenship for non-US citizens',
    section: 'borrower',
    subSection: 'citizenship',
    severity: 'warning',
    path: 'country_of_citizenship',
    requiredCondition: (data) => data.declaration_citizen === false,
    helpText: 'Required for all non-US citizens',
  },

  // Military Status
  'Military Service': {
    label: 'Military Service',
    description: 'Military service status',
    section: 'borrower',
    subSection: 'military-status',
    severity: 'info',
    path: 'military_service_member',
    helpText: 'Indicates if borrower has military service history',
    requiredCondition: (data) => data.isVALoan,
  },
  'Military Details': {
    label: 'Military Details',
    description: 'Military service details',
    section: 'borrower',
    subSection: 'military-status',
    severity: 'warning',
    path: 'military_details',
    requiredCondition: (data) => data.declaration_military === true, // Corrected condition
    helpText: 'Required if borrower has military service',
  },
  // New mapping for Military Tour Date
  'Military Tour Date': {
    label: 'Military Tour Date',
    description: 'Date of military tour',
    section: 'borrower',
    subSection: 'military-status',
    severity: 'warning',
    path: 'military_service_tour_date',
    helpText: 'Required if military service includes active duty tours',
    requiredCondition: (data) => data.military_details?.includes('Active'),
  },

  // Family Details
  'Marital Status': {
    label: 'Marital Status',
    description: 'Borrower marital status',
    section: 'borrower',
    subSection: 'family-details',
    severity: 'warning',
    path: 'marital_status',
    helpText: 'Important for title and property rights',
    requiredCondition: () => false, // Required, but handled by presence check
  },
  // TODO: Ask Michelle about this field
  // 'Legal Spouse': {
  //   label: 'Legal Spouse Information',
  //   description: 'Legal spouse property rights',
  //   section: 'borrower',
  //   subSection: 'family-details',
  //   severity: 'warning',
  //   path: 'non_spouse_property_rights',
  //   requiredCondition: (data) => data.marital_status === 'Married',
  //   helpText: 'Required for married borrowers',
  // },
  Dependents: {
    label: 'Dependents',
    description: 'Number of dependents',
    section: 'borrower',
    subSection: 'family-details',
    severity: 'info',
    path: 'declaration_dependents',
    helpText: 'Important for debt-to-income calculations',
    requiredCondition: (data) => data.isFhaLoan || data.isVALoan,
  },
  // New mapping for Family Member field
  'Family Member': {
    label: 'Family Member',
    description: 'Indicates relation with seller for family members',
    section: 'borrower',
    subSection: 'family-details',
    severity: 'info',
    path: 'declaration_relation_with_seller',
    helpText: 'Family member information can affect eligibility',
    requiredCondition: () => false, // Required, but handled by presence check
  },
  // Consolidated mapping for Forbearance (used in both Family and Loan sections)
  Forbearance: {
    label: 'Forbearance',
    description: 'Forbearance information is required for refinance loans',
    section: 'loan-details',
    subSection: 'loan',
    severity: 'error',
    path: 'forbearance',
    helpText: 'Forbearance details should be provided when applicable',
    requiredCondition: (data) => data.isRefi, // Required for refi
  },

  // Addresses
  // New mapping for Current Residence
  'Current Residence': {
    label: 'Current Residence',
    description: 'Current residence must be present',
    section: 'addresses',
    subSection: 'residence-history',
    severity: 'error',
    helpText: 'The current residence information is mandatory',
    requiredCondition: () => true, // Always required
  },
  // New mapping for Residence History
  'Residence History': {
    label: 'Residence History',
    description: 'At least two years of residency history is required',
    section: 'addresses',
    subSection: 'residence-history',
    severity: 'aus-blocking',
    helpText: 'Residency should cover at least two years',
    requiredCondition: () => true, // Required, but handled by presence/complex logic
  },
  'Co-Borrower Residence History': {
    label: 'Co-Borrower Residence History',
    description: 'At least two years of residency history is required',
    section: 'addresses',
    subSection: 'residence-history',
    severity: 'aus-blocking',
    helpText: 'Residency should cover at least two years',
    requiredCondition: (data) => data.hasCoBorrower, // Only required if co-borrower exists and has employment
  },
  // New mapping for Mailing Address
  'Mailing Address': {
    label: 'Mailing Address',
    description: 'Mailing address must be provided',
    section: 'addresses',
    subSection: 'mailing-addresses',
    severity: 'warning',
    helpText: 'Please verify mailing address details',
    requiredCondition: () => true, // Required, but handled by presence check
  },

  // Additional Properties
  // New mapping for Property Entries
  'Property Entries': {
    label: 'Property Entries',
    description: 'Owned property details must be provided',
    section: 'owned-properties',
    severity: 'warning',
    helpText: 'Enter at least one property record',
    requiredCondition: (data) => data.isRefi,
  },
  // New mapping for Investment Property Financials
  'Investment Property Financials': {
    label: 'Investment Property Financials',
    description: 'Financial details for investment properties are required',
    section: 'owned-properties',
    severity: 'warning',
    helpText: 'Provide rental income and expenses information',
    requiredCondition: (data) =>
      !!data?.properties?.some(
        (property: { type: RealEstateOwnedType }) =>
          property.type === RealEstateOwnedType.Investment
      ),
  },
  // New mapping for Current Primary Property
  'Current Primary Property': {
    label: 'Current Primary Property',
    description: 'Identification of the current primary property is required',
    section: 'owned-properties',
    severity: 'warning',
    helpText: 'Indicate which property is the primary residence',
    requiredCondition: (data) => !!data?.properties?.length,
  },
  // New mapping for Subject Property
  'Subject Property': {
    label: 'Subject Property',
    description: 'Subject property must be identified',
    section: 'loan-details',
    subSection: 'property',
    severity: 'warning',
    helpText: 'The property being evaluated should be marked as subject',
    requiredCondition: (data) => !!data?.properties?.length,
  },

  // Loan Details - Property
  'Property Type': {
    label: 'Property Type',
    description: 'Type of property',
    section: 'loan-details',
    subSection: 'property',
    severity: 'error',
    path: 'property_type',
    helpText: 'Required for all loans',
    requiredCondition: () => true, // Always required
  },
  'Property Use': {
    label: 'Property Use',
    description: 'Intended use of property',
    section: 'loan-details',
    subSection: 'property',
    severity: 'error',
    path: 'property_use',
    helpText: 'Required for all loans',
    requiredCondition: () => true, // Always required
  },
  // New mapping for Property Location
  'Property Location': {
    label: 'Property Location',
    description: 'Property location is required for validation',
    section: 'loan-details',
    subSection: 'property',
    severity: 'warning',
    helpText: 'Enter the full property address',
    requiredCondition: () => true, // Required, but handled by presence check
  },

  // Address Validation field
  'Address Validation': {
    label: 'Address Validation',
    description:
      'Validate the property address with Google on the Loan Details page',
    section: 'loan-details',
    subSection: 'property',
    severity: 'aus-blocking',
    path: 'isAddressValidatedOrTBD',
    helpText: 'Validate the property address using Google services',
    // Required if address is present
    // If TBD address, then not required
    requiredCondition: (data) => data.hasAddress || !data.isTBDAddress,
  },

  // Loan Details - Loan
  'Loan Purpose': {
    label: 'Loan Purpose',
    description: 'Purpose of the loan',
    section: 'loan-details',
    subSection: 'loan',
    severity: 'error',
    path: 'loan_purpose',
    helpText: 'Required for all loans',
    requiredCondition: () => true, // Always required
  },
  // New mapping for Property Value
  'Property Value': {
    label: 'Property Value',
    description: 'Property value is required for refinance',
    section: 'loan-details',
    subSection: 'loan',
    severity: 'error',
    path: 'property_value',
    helpText: 'Provide the current estimated property value',
    requiredCondition: (data) => data.isRefi,
  },
  // New mapping for Cash Out Amount
  'Cash Out Amount': {
    label: 'Cash Out Amount',
    description: 'Optional cash out amount for purchase/refinance',
    section: 'loan-details',
    subSection: 'loan',
    severity: 'info',
    path: 'cash_out',
    helpText: 'Enter cash out amount if applicable',
    requiredCondition: () => false, // Not required
  },
  // New mapping for Remaining Balance
  'Remaining Balance': {
    label: 'Remaining Balance',
    description: 'Remaining balance is required for refinance',
    section: 'loan-details',
    subSection: 'loan',
    severity: 'error',
    path: 'remaining_balance',
    helpText: 'Provide the current remaining mortgage balance',
    requiredCondition: (data) => data.isRefi,
  },
  // New mapping for Purchase Price
  'Purchase Price': {
    label: 'Purchase Price',
    description: 'Purchase price is required for purchase loans',
    section: 'loan-details',
    subSection: 'loan',
    severity: 'error',
    path: 'property_purchase_price',
    helpText: 'Enter the agreed upon purchase price',
    requiredCondition: (data) => data.isPurchase,
  },
  // New mapping for Down Payment
  'Down Payment': {
    label: 'Down Payment',
    description: 'Down payment is required for purchase loans',
    section: 'loan-details',
    subSection: 'loan',
    severity: 'error',
    path: 'property_down_payment',
    helpText: 'Provide the down payment amount',
    requiredCondition: (data) => data.isPurchase,
  },
  // New mapping for Own Business
  'Own Business': {
    label: 'Own Business',
    description: 'Home business information must be provided',
    section: 'loan-details',
    subSection: 'loan',
    severity: 'warning',
    path: 'home_business',
    helpText: 'State whether the applicant owns a business',
    requiredCondition: () => false, // Required, but handled by presence check
  },

  // Loan Details - AVM
  // New mapping for AVM Run
  'AVM Run': {
    label: 'AVM Run',
    description: 'Run AVM on the Loan Details page',
    section: 'loan-details',
    subSection: 'avm',
    severity: 'aus-blocking',
    helpText: 'Trigger a re-run of the AVM if missing',
    path: 'avm.estimated_value',
    // Required if address is present
    // If TBD address, then not required
    requiredCondition: (data) => data.hasAddress || !data.isTBDAddress,
  },
  // New mapping for Estimated Value
  'Estimated Value': {
    label: 'Estimated Value',
    description: 'AVM estimated value is recommended for refinance',
    section: 'loan-details',
    subSection: 'avm',
    severity: 'warning',
    helpText: 'Include AVM estimated value when available',
    requiredCondition: () => false, // Recommended, but not strictly required
  },
  // New mapping for Year Purchased
  'Year Purchased': {
    label: 'Year Purchased',
    description: 'Year purchased is recommended for refinance',
    section: 'loan-details',
    subSection: 'avm',
    severity: 'warning',
    helpText: 'Provide the year the property was purchased',
    requiredCondition: () => false, // Recommended, but not strictly required
  },
  // New mapping for Year Built
  'Year Built': {
    label: 'Year Built',
    description: 'Year built is recommended for refinance',
    section: 'loan-details',
    subSection: 'avm',
    severity: 'warning',
    helpText: 'Enter the construction year of the property',
    requiredCondition: () => false, // Recommended, but not strictly required
  },

  // Loan Details - Pricing
  // New mapping for Pricing Scenario
  'Pricing Scenario': {
    label: 'Pricing Scenario',
    description: 'Pricing scenario has not been saved',
    section: 'loan-details',
    subSection: 'locked-rate',
    severity: 'warning',
    helpText: 'Select a pricing scenario for the loan',
    requiredCondition: () => false, // Required, but handled by presence check
  },

  // Loan Details - NTB
  // New mapping for Net Tangible Benefit
  'Net Tangible Benefit': {
    label: 'Net Tangible Benefit',
    description: 'NTB is required for this scenario',
    section: 'loan-details',
    subSection: 'net-tangible-benefits',
    severity: 'warning',
    helpText: 'Determine if NTB criteria are met',
    requiredCondition: (data) =>
      (data.isVALoan || data.isInCOMD) && data?.isRefi, // Required for VA loans or CO/MD. Refi only
  },

  // Employment
  // New mapping for Employment History
  'Employment History': {
    label: 'Employment History',
    description:
      'Two years of employment is required for at least one borrower. No borrower may have partial employment history (some history that is less than 2 years).',
    section: 'employment',
    subSection: 'primary-employment-income',
    severity: 'aus-blocking',
    helpText: 'Provide employment history covering at least two years',
    requiredCondition: () => true, // Always required if borrower has employment
  },
  // New mapping for Co-Borrower Employment History
  'Co-Borrower Employment History': {
    label: 'Co-Borrower Employment History',
    description:
      'Two years of employment is required for at least one borrower. It is ok have no employment history for one borrower.',
    section: 'employment',
    subSection: 'primary-employment-income',
    severity: 'aus-blocking',
    path: 'co.employmentHistory',
    helpText:
      'Provide co-borrower employment history covering at least two years',
    requiredCondition: (data) => data.hasCoBorrower, // Only required if co-borrower exists and has employment
  },
  // New mapping for Current Employment
  'Current Employment': {
    label: 'Current Employment',
    description: 'Current employment must be selected',
    section: 'employment',
    subSection: 'primary-employment-income',
    severity: 'error',
    helpText: 'Mark the current employment job',
    requiredCondition: () => true, // Required, but handled by presence check
  },
  // New mapping for Other Income
  'Other Income': {
    label: 'Other Income',
    description: 'Other income information is optional',
    section: 'employment',
    subSection: 'other-income',
    severity: 'info',
    helpText: 'Include any additional income sources',
    requiredCondition: () => false, // Not required
  },

  // Assets
  // New mapping for Asset Entries
  'Asset Entries': {
    label: 'Asset Entries',
    description: 'Asset entries are required for qualification',
    section: 'assets',
    severity: 'warning',
    helpText: 'List all assets available',
    requiredCondition: () => true, // Required, but handled by presence check
  },

  // Liabilities
  // New mapping for Credit Report
  'Credit Report': {
    label: 'Credit Report',
    description: 'Credit report has not been run',
    section: 'liabilities',
    subSection: 'credit-report-liabilities',
    severity: 'error',
    helpText: 'A credit report is required',
    requiredCondition: () => true, // Required, but handled by presence check
  },
  // New mapping for Co-Borrower Credit Report
  'Co-Borrower Credit Report': {
    label: 'Co-Borrower Credit Report',
    description: 'Co-borrower credit report has not been run',
    section: 'liabilities',
    subSection: 'credit-report-liabilities',
    severity: 'error',
    path: 'co.creditReport',
    helpText: 'A credit report is required for the co-borrower',
    requiredCondition: (data) => {
      // If there's no co-borrower, no credit report is required
      if (!data.hasCoBorrower) return false

      // If the primary borrower's credit report was pulled as joint,
      // we don't need a separate co-borrower credit report
      if (data.isJointCreditReport) return false

      // Otherwise, require a credit report for the co-borrower
      return true
    },
  },
  // New mapping for Other Liabilities
  'Other Liabilities': {
    label: 'Other Liabilities',
    description: 'Other liabilities information',
    section: 'liabilities',
    subSection: 'other-liabilities',
    severity: 'info',
    helpText: 'List any additional liabilities',
    requiredCondition: () => false, // Not required
  },

  // Declarations & Demographics - Consolidated fields
  'Borrower Declarations and Demographics': {
    label: 'Borrower Declarations and Demographics',
    description:
      'All required borrower declarations and demographic information',
    section: 'decs-and-dems',
    subSection: 'borrower-declarations',
    severity: 'error',
    helpText:
      'All declaration questions and demographic information must be provided by the borrower',
    requiredCondition: () => true, // Always required
  },

  'Co-Borrower Declarations and Demographics': {
    label: 'Co-Borrower Declarations and Demographics',
    description:
      'All required co-borrower declarations and demographic information',
    section: 'decs-and-dems',
    subSection: 'coBorrower-declarations',
    severity: 'error',
    helpText:
      'All declaration questions and demographic information must be provided by the co-borrower',
    requiredCondition: (data) => data.hasCoBorrower, // Only required if co-borrower exists
  },

  // Misc items
  'E-Signatures': {
    label: 'E-Signatures',
    description:
      'E-Signatures are required before a lock request can be submitted',
    section: 'misc',
    severity: 'lock-blocking',
    helpText:
      'All required e-signatures must be collected before proceeding with lock request',
    requiredCondition: () => true,
  },
  Conditions: {
    label: 'Conditions',
    description:
      'Send Client a link to get an Alfie Completed status and auto-fire initial conditions',
    section: 'misc',
    severity: 'lock-blocking',
    helpText: 'At least 2 conditions must exist on the application',
    requiredCondition: () => true,
  },
  'Handoff Sheet': {
    label: 'Handoff Sheet',
    description: 'Processing handoff sheet must be uploaded via the AUS page',
    section: 'misc',
    severity: 'lock-blocking',
    helpText: 'The handoff sheet can be uploaded from the AUS page',
    requiredCondition: () => true,
  },
  'Credit Report Available in Encompass': {
    label: 'Credit Report Available in Encompass',
    description: 'The hard credit report must be synced to Encompass',
    section: 'misc',
    severity: 'lock-blocking',
    helpText: 'Credit can be synced from the AUS page',
    requiredCondition: () => true,
  },
} as const
