import { Action, action, Thunk, thunk } from 'easy-peasy'

import incense from '@mortgage-pos/ui/incense'
import { borrowerPortalRequest } from '../services/http'
import { ApplicationAssetsRevisionObject } from '@mortgage-pos/db'

interface PlaidAccount {
  id: number
  name: string
  bankName: string
  accountNumber: string
  type: string
  currentBalance?: number
}

interface PlaidState {
  accounts: PlaidAccount[] | null
  linkToken: string | null
  isLoading: boolean
  error: string | null
  getConnectedAccounts: Thunk<PlaidState>
  setLinkToken: Action<PlaidState, string>
  setLoading: Action<PlaidState, boolean>
  setError: Action<PlaidState, string | null>
  setAccounts: Action<PlaidState, PlaidAccount[]>
  initializePlaid: Thunk<PlaidState>
}

const plaidStore: PlaidState = {
  accounts: null,
  linkToken: null,
  isLoading: false,
  error: null,

  // Actions
  setLinkToken: action((state, payload) => {
    state.linkToken = payload
  }),

  setLoading: action((state, payload) => {
    state.isLoading = payload
  }),

  setError: action((state, payload) => {
    state.error = payload
  }),

  setAccounts: action((state, payload) => {
    state.accounts = payload
  }),

  // Thunks
  initializePlaid: thunk(async (actions, _, { getState }) => {
    if (getState().isLoading) {
      return
    }

    actions.setLoading(true)
    try {
      const response = await borrowerPortalRequest('/plaid/link-token')
      actions.setLinkToken(response.data.linkToken)
    } catch (error) {
      actions.setError('Failed to initialize Plaid')
    } finally {
      actions.setLoading(false)
    }
  }),

  getConnectedAccounts: thunk(async (actions, _, { getState }) => {
    if (getState().isLoading) {
      return
    }

    actions.setLoading(true)

    try {
      const accounts = await getPlaidAccounts(getState().linkToken)
      actions.setAccounts(accounts)
    } catch (error) {
      incense(error)
        .details({
          name: 'PlaidGetConnectedAccountsError',
          message: 'Failed to get connected accounts',
        })
        .error()
    } finally {
      actions.setLoading(false)
    }
  }),
}

const mapAssetToPlaidAccount = (
  asset: ApplicationAssetsRevisionObject
): PlaidAccount => {
  return {
    id: asset.id,
    name: asset.account_name,
    bankName: asset.bank_name,
    accountNumber: asset.account_number,
    type: asset.type,
  }
}

async function getPlaidAccounts(linkToken?: string) {
  const { data } = await borrowerPortalRequest(
    linkToken ? `/plaid/accounts?linkToken=${linkToken}` : '/plaid/accounts',
    {},
    {},
    'get'
  )

  return (data?.accounts ?? []).map(mapAssetToPlaidAccount)
}

export default plaidStore
