import { thunk, Thunk } from 'easy-peasy'

import { StoreModel } from './index'
import { borrowerPortalRequest } from '../services/http'
import { PLAID_FLOW_TASK_NAME } from '@mortgage-pos/types/Plaid'

export interface TaskModel {
  addTaskByName: Thunk<TaskModel, { taskName: string }, object, StoreModel>
  updatePlaidTaskStatus: Thunk<
    TaskModel,
    { status: string },
    object,
    StoreModel
  >
}

const task = (): TaskModel => {
  return {
    addTaskByName: thunk(async (_, payload) => {
      const { taskName } = payload

      await borrowerPortalRequest(
        '/tasks',
        {
          taskName,
        },
        {
          'Content-Type': 'application/json',
        }
      )
    }),

    updatePlaidTaskStatus: thunk(async (_, payload) => {
      const { status } = payload

      await borrowerPortalRequest(
        '/tasks/by-name',
        {
          taskName: PLAID_FLOW_TASK_NAME,
          status,
        },
        {
          'Content-Type': 'application/json',
        },
        'patch'
      )
    }),
  }
}

export default task
