import { AccountBase, PlaidEnvironments, Products } from 'plaid'

export const PLAID_FLOW_TASK_NAME = 'plaid-flow'

export interface PlaidLinkConfig {
  token: string
  onSuccess: (publicToken: string, metadata: PlaidLinkOnSuccessMetadata) => void
  onExit: (error?: Error) => void
  onEvent: (eventName: string, metadata: any) => void
}

export interface PlaidLinkOnSuccessMetadata {
  institution: {
    name: string
    institution_id: string
  }
  accounts: AccountBase[]
  link_session_id: string
}

export interface PlaidConfig {
  clientId: string
  secret: string
  env: keyof typeof PlaidEnvironments
  products: Products[]
}
