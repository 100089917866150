import task, { TaskModel } from './task'
import rates, { RatesModel } from './rates'
import answers, { AnswersModel } from './answers'
import address, { AddressModel } from './address'
import { environment } from '@mortgage-pos/ui/env'
import tracking, { TrackingModel } from './tracking'
import { createStore, createTypedHooks } from 'easy-peasy'
import noTalkLock, { NoTalkLockModel } from './noTalkLock'
import loanOfficer, { LoanOfficerModel } from './loanOfficer'
import application, { ApplicationModel } from './application'
import pageSections, { PageSectionsModel } from './pageSections'
import propertyInfo, { PropertyInfoModel } from './propertyInfo'
import leComparison, { LEComparisonModel } from './leComparison'
import questionnaire, { QuestionnaireModel } from './questionnaire'
import licensedStates, { LicensedStatesModel } from './licensedStates'
import authentication, { AuthenticationModel } from './authentication'
import bankrateCompare, { BankrateCompareModel } from './bankrateCompare'
import alfalfaChromeExt, { AlfalfaChromeExtModel } from './alfalfaChromeExt'
import plaidStore from './plaid'

import alfalfaExtendedFeatures, {
  AlfalfaExtendedFeaturesModel,
} from './alfalfaExtendedFeatures'

import visibleIfConditions, {
  VisibleIfConditionsModel,
} from './visibleIfConditions'

export interface StoreModel {
  rates: RatesModel
  task: TaskModel
  answers: AnswersModel
  address: AddressModel
  tracking: TrackingModel
  noTalkLock: NoTalkLockModel
  loanOfficer: LoanOfficerModel
  application: ApplicationModel
  propertyInfo: PropertyInfoModel
  leComparison: LEComparisonModel
  pageSections: PageSectionsModel
  questionnaire: QuestionnaireModel
  authentication: AuthenticationModel
  licensedStates: LicensedStatesModel
  bankrateCompare: BankrateCompareModel
  alfalfaChromeExt: AlfalfaChromeExtModel
  visibleIfConditions: VisibleIfConditionsModel
  alfalfaExtendedFeatures: AlfalfaExtendedFeaturesModel
  plaid: typeof plaidStore
}

const model: StoreModel = {
  task: task(),
  rates: rates(),
  answers: answers(),
  address: address(),
  tracking: tracking(),
  noTalkLock: noTalkLock(),
  loanOfficer: loanOfficer(),
  application: application(),
  propertyInfo: propertyInfo(),
  leComparison: leComparison(),
  pageSections: pageSections(),
  questionnaire: questionnaire(),
  authentication: authentication(),
  licensedStates: licensedStates(),
  bankrateCompare: bankrateCompare(),
  alfalfaChromeExt: alfalfaChromeExt(),
  visibleIfConditions: visibleIfConditions(),
  alfalfaExtendedFeatures: alfalfaExtendedFeatures(),
  plaid: plaidStore,
}

const index = createStore(model, {
  name: 'mortgage-pos-store',
  devTools: environment.appEnv !== 'prod',
})

export default index

export const { useStoreState, useStoreActions, useStoreDispatch } =
  createTypedHooks<StoreModel>()
