import { StoreModel } from './index'
import rollbar from '@mortgage-pos/ui/services/rollbar'
import { Status } from '@mortgage-pos/types'
import { variation } from '@mortgage-pos/ui/services/featureFlags'
import { FEATURE_FLAG_TYPES } from '@mortgage-pos/data'
import { action, Action, Computed, computed, Thunk, thunk } from 'easy-peasy'

import {
  Page,
  SectionStatusCode,
  SectionStatusUpdate,
} from '@mortgage-pos/types'
import { isQuestionVisible } from '@mortgage-pos/ui/utils/visibleIf'
import { enableMapSet } from 'immer'

// This helps [Unit Tests] to add data to the redux store
enableMapSet()

type SectionStatusesList = Record<string, Set<SectionStatusCode>>
type PagesInEachSection = Record<string, string[]>

export interface PageSectionsModel {
  hasSetSplitFlowStatus: boolean
  sectionStatuses: SectionStatusesList
  pagesInEachSection: Computed<
    PageSectionsModel,
    PagesInEachSection,
    StoreModel
  >
  pageSectionsList: Computed<PageSectionsModel, string[], StoreModel>
  progressValue: Computed<PageSectionsModel, number, StoreModel>
  initializeSectionStatuses: Thunk<PageSectionsModel, string, any, StoreModel>
  setHasSetSplitFlowStatus: Action<PageSectionsModel, boolean>
  replaceSectionStatuses: Action<PageSectionsModel, SectionStatusesList>
  setSectionStatus: Action<PageSectionsModel, SectionStatusUpdate>
  completeAllSections: Thunk<PageSectionsModel, null, any, StoreModel>
  updateSectionStatuses: Thunk<PageSectionsModel, string, any, StoreModel>
  navigateToSection: Thunk<PageSectionsModel, string, any, StoreModel>
}

const pageSections = (): PageSectionsModel => {
  return {
    // Used for Alfie split-flow ONLY: This exists to prevent us from updating
    // statuses multiple times per flow. Section status updates should only
    // happen once per session per flow
    hasSetSplitFlowStatus: false,

    sectionStatuses: {},

    pageSectionsList: computed(
      [(_, storeState) => storeState.questionnaire.pages],
      (pages) => {
        const sectionsList: Set<string> = new Set()

        if (pages) {
          pages.forEach((page: Page) => {
            if (page.section) {
              sectionsList.add(page.section)
            }
          })
        }

        return Array.from(sectionsList)
      }
    ),

    /**
     * Maps an object that lists pages for each section:
     *   {key}: sectionName
     *   {value}: [pageNames]
     *
     * Example:
     * {
     *    'Property Info': ['addressAutoComplete', 'propertyAddress', etc.],
     *    'Personal Info': ['personalInfo', 'ssn', etc.]
     * }
     */
    pagesInEachSection: computed(
      [
        (state) => state.pageSectionsList,
        (_state, storeState) => storeState.questionnaire.pages,
      ],
      (pageSectionsList, pages) => {
        const pagesInEachSection: PagesInEachSection = {}

        pageSectionsList.forEach((sectionName) => {
          pagesInEachSection[sectionName] = pages.reduce(
            (pagesInSection, page): string[] => {
              if (page.section && page.section === sectionName) {
                pagesInSection.push(page.name)
              }

              return pagesInSection
            },
            []
          )
        })

        return pagesInEachSection
      }
    ),

    /**
     * Calculate the progress percentage based on what page the user is on.
     * This is a combination of the percentage of sections completed and the
     * percentage of pages completed for the currently active section
     *
     * @param pageSectionsList - list of all the sections
     * @param pagesInSection - list of pages in the active section
     * @param activeSection - name of the active section
     * @param activePage - name of the active page
     */
    progressValue: computed(
      [(state) => state, (_state, storeState) => storeState.questionnaire],
      (state, questionnaire) => {
        const { active, finished } = questionnaire

        if (!active || !active.section) {
          return 0
        }

        if (finished) {
          return 100
        }

        const { pageSectionsList, pagesInEachSection } = state
        const { name: activePage, section: activeSection } = active
        const pagesInSection = pagesInEachSection[activeSection]

        if (!pageSectionsList || !pagesInSection) {
          return 0
        }

        // Get indices for use in our calculations below
        const sectionIndex = pageSectionsList.findIndex(
          (sectionName) => sectionName === activeSection
        )

        const indexOfPageInSection = pagesInSection.findIndex(
          (pageName) => pageName === activePage
        )

        // If there are four sections, this will be 25% per section
        const sectionPercentScale = 100 / pageSectionsList.length

        // If there are four sections @ 25% each and five questions within the
        // currently active section, the scale will be 20% of 25% or 5% per page
        // within the current section
        const pagePercentScale =
          ((100 / pagesInSection.length) * sectionPercentScale) / 100

        // Calculate the actual percentage values
        const sectionPercent = sectionPercentScale * sectionIndex
        const pagePercent = pagePercentScale * indexOfPageInSection

        // Tabulate the total
        return Math.round(sectionPercent + pagePercent)
      }
    ),

    setHasSetSplitFlowStatus: action((state, hasSetItAlready) => {
      state.hasSetSplitFlowStatus = hasSetItAlready
    }),

    initializeSectionStatuses: thunk(
      async (
        { replaceSectionStatuses, setSectionStatus },
        initialSection: string,
        { getState, getStoreState }
      ) => {
        const { isAuthenticated } = getStoreState().authentication
        const { isSplitFlow, isPhoneFlow, isSsnFlow } =
          getStoreState().questionnaire
        const { pageSectionsList } = getState()
        const sectionStatuses: SectionStatusesList = {}

        if (!pageSectionsList.length) {
          return
        }

        if (isAuthenticated && pageSectionsList.includes('Create Account')) {
          pageSectionsList.splice(pageSectionsList.indexOf('Create Account'), 1)
        }

        const pageSectionsListIncludesPricing = pageSectionsList.some(
          (section) => section.toLowerCase().includes('pricing')
        )

        if (
          (await variation(FEATURE_FLAG_TYPES.SHOW_PRICING_STEP_LEFT_RAIL)) &&
          !pageSectionsListIncludesPricing &&
          !isSplitFlow &&
          !isPhoneFlow &&
          !isSsnFlow
        ) {
          pageSectionsList.push(CUSTOM_PRICING_SECTION)
        }

        pageSectionsList.forEach((sectionName) => {
          sectionStatuses[sectionName] = new Set()
        })

        replaceSectionStatuses(sectionStatuses)

        // Initialize first section as active/visited
        setSectionStatus({
          sectionName: initialSection,
          statusCode: SectionStatusCode.active,
        })
      }
    ),

    replaceSectionStatuses: action(
      (state, sectionStatuses: SectionStatusesList) => {
        state.sectionStatuses = sectionStatuses
      }
    ),

    setSectionStatus: action((state, sectionStatus: SectionStatusUpdate) => {
      const { sectionName, statusCode } = sectionStatus

      if (!sectionName || !state.sectionStatuses[sectionName]) {
        return
      }

      const updatedStatuses = { ...state.sectionStatuses }

      // Rm all active statuses ensuring only one active status at a time
      if (statusCode === SectionStatusCode.active) {
        Object.values(updatedStatuses).forEach((statusList) => {
          statusList.delete(SectionStatusCode.active)
        })

        // An active page must also have been visited
        updatedStatuses[sectionName].add(SectionStatusCode.visited)
      }

      updatedStatuses[sectionName].add(statusCode)

      state.sectionStatuses = updatedStatuses
    }),

    completeAllSections: thunk(
      ({ replaceSectionStatuses }, _, { getState, getStoreActions }) => {
        const { sectionStatuses } = getState()
        const { setFinished } = getStoreActions().questionnaire

        // Remove active from all sections
        const updatedStatuses = { ...sectionStatuses }

        Object.values(updatedStatuses).forEach((statusList) => {
          statusList.delete(SectionStatusCode.active)
          statusList.add(SectionStatusCode.complete)
        })

        replaceSectionStatuses(updatedStatuses)
        setFinished(true)
      }
    ),

    updateSectionStatuses: thunk(
      async (
        { setSectionStatus, setHasSetSplitFlowStatus },
        previousSection,
        { getState, getStoreState, getStoreActions }
      ) => {
        const { sectionStatuses, hasSetSplitFlowStatus } = getState()

        const { active, finished, statusesMap, isSplitFlow, isPhoneFlow } =
          getStoreState().questionnaire

        const { updateStatus } = getStoreActions().application

        if (!active.section) {
          return
        }

        // When moving to a new section, the previous one is assumed complete
        if (previousSection !== active.section) {
          setSectionStatus({
            sectionName: previousSection,
            statusCode: SectionStatusCode.complete,
          })
        }

        // Skip on pricing section
        // Pricing handles firing statuses internally based on rates/no rates/etc
        const isPricingSection = active.section?.toLowerCase() === 'pricing'

        if (!isPricingSection && !isSplitFlow && !isPhoneFlow) {
          updateStatus(mapSectionToApplicationStatus(active.section))
        }

        // Set touched, once per session, per flow
        if ((isSplitFlow || isPhoneFlow) && !hasSetSplitFlowStatus) {
          await updateStatus(statusesMap.touched)
          setHasSetSplitFlowStatus(true)
        }

        if (!finished) {
          setSectionStatus({
            sectionName: active.section,
            statusCode: SectionStatusCode.active,
          })
        }

        if (finished && sectionStatuses[CUSTOM_PRICING_SECTION]) {
          setSectionStatus({
            sectionName: previousSection,
            statusCode: SectionStatusCode.complete,
          })

          setSectionStatus({
            sectionName: 'Custom Pricing',
            statusCode: SectionStatusCode.active,
          })
        }
      }
    ),

    navigateToSection: thunk(
      (
        { setSectionStatus },
        sectionName,
        { getStoreState, getStoreActions }
      ) => {
        const { questionnaire, answers, visibleIfConditions } = getStoreState()
        const { pages } = questionnaire
        const { mergedAnswers } = answers
        const { setPageIndex } = getStoreActions().questionnaire

        const indexOfFirstVisiblePageInSection = pages.findIndex((page) => {
          const visibleIfCondition = page.visibleIfConstructor?.[0]

          return (
            (!visibleIfCondition ||
              isQuestionVisible(
                visibleIfCondition,
                mergedAnswers,
                visibleIfConditions
              )) &&
            page.section === sectionName
          )
        })

        if (indexOfFirstVisiblePageInSection > -1) {
          setPageIndex(indexOfFirstVisiblePageInSection)

          setSectionStatus({
            sectionName,
            statusCode: SectionStatusCode.active,
          })
        }
      }
    ),
  }
}

export default pageSections

const mapSectionToApplicationStatus = (section) => {
  const status = StatusMap[section]

  if (!status) {
    rollbar.error(
      `Application Status does not exist for questionnaire section: ${section}`
    )
  }

  return status
}

const StatusMap = {
  'Property Info': Status.QPropertyInfoSectionTouched,
  'Create Account': Status.QCreateAccountSectionTouched,
  'Personal Info': Status.QPersonalInfoSectionTouched,
  'Refinance Options': Status.QPersonalInfoSectionTouched,
  Employment: Status.QEmploymentSectionTouched,
  Expenses: Status.QExpensesSectionTouched,
  Declarations: Status.QDeclarationsSectionTouched,
}

export const CUSTOM_PRICING_SECTION = 'Custom Pricing'
