import { Rate, SectionFees, VeteranType } from './generated/graphql-types'
import { PricingExceptionDocumentType } from './PricingException'
export type FifteenSecsToSaveDocument = {
  apr: number
  fees: {
    applicationFee?: number
    lenderFee?: number
    originationFee?: number
    processingFee?: number
    underwritingFee?: number
    brokerFees?: number
  }
  originationCharges?: number
  term: number
  state: string
  product: string
  zipCode: string
  interest: number
  rateLock: boolean
  loanAmount: number
  dateCreated: string
  downPayment: number
  loanPurpose: string
  lenderCredits: number
  productFamily: string
  propertyValue: number
  loanAmountPoints: number
  monthlyPaymentPnI: number
  cashOut?: number
  cashOutDirection?: 'from' | 'to' | ''
  estimatedPayOff?: number
  hasStoredCreditScore?: boolean
  sectionBTotal?: number
  rateLockExpirationDate?: string
  lockPeriod?: number
  veteranType?: VeteranType
  veteranDisabled?: boolean
  firstTimeUsingVaLoan?: boolean
  documentType?: PricingExceptionDocumentType
}

enum FeeType {
  NotSpecified = 'NotSpecified',
  DollarAmount = 'DollarAmount',
  Percentage = 'Percentage',
}

export type FifteenSecondsToSaveFee = {
  amount: number
  description?: string
  feeSetId: number
  feeType: FeeType
  hudLine: number
  id: number
  includeInApr: boolean
  userId: number
}

export type Simplified15s2sRate = {
  superlative: string
  mqRate: Rate
  monthlyPayment: number
  rate: number
  apr: number
  points: number
  cashToClose: number
  eightYearCol: number
  loanTerm: string
  lenderBaseFees: SectionFees[]
  loanType?: string
  pointsFee?: number
  lenderCredit?: number
}

export enum FifteenSecondsToSaveSuperlative {
  BetterMonthlyPaymentRate = 'betterMonthlyPaymentRate',
  BetterUpFrontCostsRate = 'betterUpFrontCostsRate',
  Better8yearCostsRate = 'better8yearCostsRate',
  SimilarRate = 'similarRate',
  SimilarUpFrontCostsRate = 'similarUpFrontCostsRate',
}

export type FifteenSecsToSaveDbRate = {
  superlative: FifteenSecondsToSaveSuperlative
  rate: Rate
}

export enum FifteenSecondsToSaveHudLineItem {
  originationFee = 801,
  processingFee = 811,
  underwritingFee = 812,
}

export type FifteenSecondsToSaveFieldsForNoApp = {
  creditScore: number
  propertyType: string
  propertyUse: string
}
