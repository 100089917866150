export enum PricingExceptionSource {
  Automatic = 'Automatic',
  Manual = 'Manual',
}

export enum PricingExceptionDocumentType {
  LoanEstimate = 'LoanEstimate',
  FeeEstimate = 'FeeEstimate',
  ClosingDisclosure = 'ClosingDisclosure',
  Email = 'Email',
  Screenshot = 'Screenshot',
  Other = 'Other',
}

export interface AutoExceptionEligibilityResult {
  isEligible: boolean
  ineligibleReason?: string
  suggestedAmount: number
  calculatedRevenue: number
  autoAcceptLevel: number
  lockBonusUsedInCalculation: number
}
