export type Maybe<T> = T | null
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K]
}
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>
}
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>
}
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string
  String: string
  Boolean: boolean
  Int: number
  Float: number
  /** The `JSON` scalar type represents JSON values as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  JSON: any
}

export type AusAnalysisData = {
  __typename?: 'AUSAnalysisData'
  ausReportId?: Maybe<Scalars['Int']>
  createdAt?: Maybe<Scalars['String']>
  errorMessage?: Maybe<Scalars['String']>
  extractData?: Maybe<Scalars['JSON']>
  isSelected?: Maybe<Scalars['Boolean']>
}

export type AusAnalysisDataResponse = {
  __typename?: 'AUSAnalysisDataResponse'
  du?: Maybe<AusAnalysisData>
  duea?: Maybe<AusAnalysisData>
  lpa?: Maybe<AusAnalysisData>
}

export type AvmPropertyDetailsInput = {
  assessmentYear?: Maybe<Scalars['Int']>
  confidenceScore?: Maybe<Scalars['Int']>
  estimatedValue?: Maybe<Scalars['Int']>
  firstMortgageAmount?: Maybe<Scalars['Float']>
  firstMortgageType?: Maybe<Scalars['String']>
  highValue?: Maybe<Scalars['Int']>
  landUse?: Maybe<Scalars['String']>
  lowValue?: Maybe<Scalars['Int']>
  manualOverride?: Maybe<Scalars['Boolean']>
  ownerName1?: Maybe<Scalars['String']>
  ownerName2?: Maybe<Scalars['String']>
  ownerOccupiedIndicator?: Maybe<Scalars['String']>
  processedDate?: Maybe<Scalars['String']>
  propertyTax?: Maybe<Scalars['Float']>
  residentialUnits?: Maybe<Scalars['Int']>
  saleDate?: Maybe<Scalars['String']>
  salePrice?: Maybe<Scalars['Float']>
  totalAssessedValue?: Maybe<Scalars['Float']>
  yearBuilt?: Maybe<Scalars['Int']>
  yearPurchased?: Maybe<Scalars['Int']>
}

export type AvmPropertyDetailsSchema = {
  __typename?: 'AVMPropertyDetailsSchema'
  applicationId?: Maybe<Scalars['Int']>
  assessmentYear?: Maybe<Scalars['Int']>
  confidenceScore?: Maybe<Scalars['Int']>
  coreLogicPropertyId?: Maybe<Scalars['String']>
  createdAt?: Maybe<Scalars['String']>
  estimatedValue?: Maybe<Scalars['Int']>
  firstMortgageAmount?: Maybe<Scalars['Float']>
  firstMortgageType?: Maybe<Scalars['String']>
  highValue?: Maybe<Scalars['Int']>
  id?: Maybe<Scalars['Int']>
  landUse?: Maybe<Scalars['String']>
  lowValue?: Maybe<Scalars['Int']>
  manualOverride?: Maybe<Scalars['Boolean']>
  ownerName1?: Maybe<Scalars['String']>
  ownerName2?: Maybe<Scalars['String']>
  ownerOccupiedIndicator?: Maybe<Scalars['String']>
  processedDate?: Maybe<Scalars['String']>
  propertyTax?: Maybe<Scalars['Float']>
  residentialUnits?: Maybe<Scalars['Int']>
  saleDate?: Maybe<Scalars['String']>
  salePrice?: Maybe<Scalars['Float']>
  totalAssessedValue?: Maybe<Scalars['Float']>
  updatedAt?: Maybe<Scalars['String']>
  yearBuilt?: Maybe<Scalars['Int']>
  yearPurchased?: Maybe<Scalars['Int']>
}

export type AccountLastFourDigits = {
  __typename?: 'AccountLastFourDigits'
  lastFourDigits?: Maybe<Scalars['String']>
  name?: Maybe<Scalars['String']>
}

export type ActiveLeadSchema = {
  __typename?: 'ActiveLeadSchema'
  applicationId?: Maybe<Scalars['Int']>
  borrowerEmail?: Maybe<Scalars['String']>
  borrowerName?: Maybe<Scalars['String']>
  closingDate?: Maybe<Scalars['String']>
  contactStatus?: Maybe<Scalars['String']>
  daysToClose?: Maybe<Scalars['Int']>
  followUpDate?: Maybe<Scalars['String']>
  ibTextCount?: Maybe<Scalars['Int']>
  leadGoal?: Maybe<Scalars['String']>
  leadSource?: Maybe<Scalars['String']>
  leadStartedDate?: Maybe<Scalars['String']>
  lenderLoanId?: Maybe<Scalars['String']>
  loAssigned?: Maybe<Scalars['String']>
  loId?: Maybe<Scalars['Int']>
  loPriorityLevel?: Maybe<Scalars['String']>
  loanPurpose?: Maybe<Scalars['String']>
  meetingType?: Maybe<MeetingType>
  obCallsCount?: Maybe<Scalars['Int']>
  obTextCount?: Maybe<Scalars['Int']>
  phoneNumber?: Maybe<Scalars['String']>
  score?: Maybe<Scalars['Float']>
  state?: Maybe<Scalars['String']>
  status?: Maybe<Scalars['String']>
  tridDaysLeft?: Maybe<Scalars['Int']>
}

export type AddConditionInput = {
  description?: Maybe<Scalars['String']>
  documentRequests?: Maybe<Array<Maybe<DocumentRequestInput>>>
  expirationDate?: Maybe<Scalars['String']>
  title?: Maybe<Scalars['String']>
  type: ConditionType
  uuid?: Maybe<Scalars['String']>
}

export type AddressOutput = {
  __typename?: 'AddressOutput'
  apt?: Maybe<Scalars['String']>
  city?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['Int']>
  isAttached?: Maybe<Scalars['Boolean']>
  moveInDate?: Maybe<Scalars['String']>
  moveOutDate?: Maybe<Scalars['String']>
  numberOfStories?: Maybe<Scalars['Int']>
  state?: Maybe<Scalars['String']>
  street?: Maybe<Scalars['String']>
  type?: Maybe<AddressType>
  zipCode?: Maybe<Scalars['String']>
}

export type AddressRevisionSchema = {
  __typename?: 'AddressRevisionSchema'
  apt?: Maybe<Scalars['String']>
  city?: Maybe<Scalars['String']>
  county?: Maybe<Scalars['String']>
  createdAt?: Maybe<Scalars['String']>
  id: Scalars['Int']
  isAttached?: Maybe<Scalars['Boolean']>
  isMailingAddress?: Maybe<Scalars['Boolean']>
  moveInDate?: Maybe<Scalars['String']>
  moveOutDate?: Maybe<Scalars['String']>
  numberOfStories?: Maybe<Scalars['Int']>
  occupancyType?: Maybe<OccupancyType>
  rentAmount?: Maybe<Scalars['Float']>
  state?: Maybe<Scalars['String']>
  street?: Maybe<Scalars['String']>
  type?: Maybe<Scalars['String']>
  zip?: Maybe<Scalars['String']>
}

export enum AddressType {
  Current = 'current',
  Previous = 'previous',
}

export type AddressValidationInputSchema = {
  city?: Maybe<Scalars['String']>
  state?: Maybe<Scalars['String']>
  street?: Maybe<Array<Maybe<Scalars['String']>>>
  zip?: Maybe<Scalars['String']>
}

export type AddressValidationResponse = {
  __typename?: 'AddressValidationResponse'
  city?: Maybe<GoogleAddressComponent>
  county?: Maybe<Scalars['String']>
  fipsCode?: Maybe<Scalars['String']>
  isValid?: Maybe<Scalars['Boolean']>
  state?: Maybe<GoogleAddressComponent>
  street?: Maybe<GoogleAddressComponent>
  streetNumber?: Maybe<GoogleAddressComponent>
  streetTwo?: Maybe<GoogleAddressComponent>
  unconfirmedComponents?: Maybe<Array<Maybe<Scalars['String']>>>
  zip?: Maybe<GoogleAddressComponent>
}

export type Adjustment = {
  __typename?: 'Adjustment'
  adjustmentSourceType?: Maybe<AdjustmentSourceType>
  adjustmentType?: Maybe<AdjustmentType>
  amount?: Maybe<Scalars['Float']>
  filter?: Maybe<Scalars['String']>
  isCompAdjustment?: Maybe<Scalars['Boolean']>
  isHidden?: Maybe<Scalars['Boolean']>
  isStop?: Maybe<Scalars['Boolean']>
  title?: Maybe<Scalars['String']>
}

export type AdjustmentInput = {
  adjustmentSourceType?: Maybe<AdjustmentSourceType>
  adjustmentType?: Maybe<AdjustmentType>
  amount?: Maybe<Scalars['Float']>
  filter?: Maybe<Scalars['String']>
  isCompAdjustment?: Maybe<Scalars['Boolean']>
  isHidden?: Maybe<Scalars['Boolean']>
  isStop?: Maybe<Scalars['Boolean']>
  title?: Maybe<Scalars['String']>
}

export enum AdjustmentSourceType {
  BusinessRule = 'BusinessRule',
  PricingRule = 'PricingRule',
  RateSheet = 'RateSheet',
}

export enum AdjustmentType {
  Cap = 'Cap',
  Margin = 'Margin',
  MaxRebate = 'MaxRebate',
  NotSpecified = 'NotSpecified',
  PostCap = 'PostCap',
  PostMaxRebate = 'PostMaxRebate',
  PreCap = 'PreCap',
  Rate = 'Rate',
  Stop = 'Stop',
}

export type AdminLead = {
  __typename?: 'AdminLead'
  address?: Maybe<Scalars['String']>
  address2?: Maybe<Scalars['String']>
  applicationCreatedAt?: Maybe<Scalars['String']>
  applicationDeletedAt?: Maybe<Scalars['String']>
  applicationId?: Maybe<Scalars['Int']>
  applicationRevisionCreatedAt?: Maybe<Scalars['String']>
  applicationRevisionDeletedAt?: Maybe<Scalars['String']>
  applicationRevisionId?: Maybe<Scalars['Int']>
  applicationRevisionUpdatedAt?: Maybe<Scalars['String']>
  applicationUpdatedAt?: Maybe<Scalars['String']>
  bankrateLeadId?: Maybe<Scalars['String']>
  besmarteeLoanId?: Maybe<Scalars['String']>
  cashOut?: Maybe<Scalars['Int']>
  city?: Maybe<Scalars['String']>
  creditReportIdentifier?: Maybe<Scalars['String']>
  creditScore?: Maybe<Scalars['Int']>
  debtToIncome?: Maybe<Scalars['Float']>
  declarationAlimony?: Maybe<Scalars['Boolean']>
  declarationBankruptcy?: Maybe<Scalars['Boolean']>
  declarationBorrowedDown?: Maybe<Scalars['Boolean']>
  declarationBorrowedDownFundsAmount?: Maybe<Scalars['Int']>
  declarationCitizen?: Maybe<Scalars['Boolean']>
  declarationDependents?: Maybe<Scalars['Int']>
  declarationEthnicity?: Maybe<Array<Maybe<Scalars['String']>>>
  declarationFHALoan?: Maybe<Scalars['Boolean']>
  declarationForeclosures?: Maybe<Scalars['Boolean']>
  declarationGender?: Maybe<Array<Maybe<Scalars['String']>>>
  declarationHomeOwnership?: Maybe<Scalars['Boolean']>
  declarationJudgements?: Maybe<Scalars['Boolean']>
  declarationLawsuits?: Maybe<Scalars['Boolean']>
  declarationLoanDefaults?: Maybe<Scalars['Boolean']>
  declarationLoanForeclosures?: Maybe<Scalars['Boolean']>
  declarationMilitary?: Maybe<Scalars['Boolean']>
  declarationNoteEndorser?: Maybe<Scalars['Boolean']>
  declarationPrimaryResidence?: Maybe<Scalars['Boolean']>
  declarationPropertyTitle?: Maybe<Scalars['String']>
  declarationPropertyType?: Maybe<Scalars['String']>
  declarationRace?: Maybe<Array<Maybe<Scalars['String']>>>
  declarationResident?: Maybe<Scalars['Boolean']>
  declarationSchooling?: Maybe<Scalars['String']>
  declarationVALoan?: Maybe<Scalars['Boolean']>
  email?: Maybe<Scalars['String']>
  expenseFirstMortgage?: Maybe<Scalars['Float']>
  expenseHomeInsurance?: Maybe<Scalars['Float']>
  expenseMortgageInsurance?: Maybe<Scalars['Float']>
  expensePropertyTaxes?: Maybe<Scalars['Float']>
  expenseRent?: Maybe<Scalars['Float']>
  expenseSecondMortgage?: Maybe<Scalars['Float']>
  firstName?: Maybe<Scalars['String']>
  fullName?: Maybe<Scalars['String']>
  iceBox?: Maybe<Scalars['Boolean']>
  lastContactedAt?: Maybe<Scalars['String']>
  lastContactedType?: Maybe<Scalars['String']>
  lastName?: Maybe<Scalars['String']>
  lastRingcentralContactedAt?: Maybe<Scalars['String']>
  lastRingcentralContactedType?: Maybe<Scalars['String']>
  leadId?: Maybe<Scalars['Int']>
  lenderLoanId?: Maybe<Scalars['String']>
  loanAmount?: Maybe<Scalars['Float']>
  loanOfficerId?: Maybe<Scalars['Int']>
  loanOfficerName?: Maybe<Scalars['String']>
  loanPurpose?: Maybe<LoanPurpose>
  loanToValue?: Maybe<Scalars['Float']>
  maritalStatus?: Maybe<Scalars['String']>
  notes?: Maybe<Scalars['String']>
  phone?: Maybe<Scalars['String']>
  propertyDownPayment?: Maybe<Scalars['Float']>
  propertyPurchasePrice?: Maybe<Scalars['Float']>
  propertyType?: Maybe<Scalars['String']>
  propertyUse?: Maybe<Scalars['String']>
  propertyValue?: Maybe<Scalars['Int']>
  propertyYearBuilt?: Maybe<Scalars['Int']>
  propertyYearPurchased?: Maybe<Scalars['Int']>
  proxyPhone?: Maybe<Scalars['String']>
  remainingBalance?: Maybe<Scalars['Float']>
  source?: Maybe<Scalars['String']>
  state?: Maybe<Scalars['String']>
  status?: Maybe<Scalars['String']>
  tridDaysLeft?: Maybe<Scalars['Int']>
  zip?: Maybe<Scalars['String']>
}

export type AdminLoanOfficer = {
  __typename?: 'AdminLoanOfficer'
  NMLS?: Maybe<Scalars['String']>
  calendlyLink?: Maybe<Scalars['String']>
  email?: Maybe<Scalars['String']>
  encompassId?: Maybe<Scalars['String']>
  headshot?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['Int']>
  isLoanCoordinator?: Maybe<Scalars['Boolean']>
  isManager?: Maybe<Scalars['Boolean']>
  loanCoordinatorId?: Maybe<Scalars['Int']>
  name?: Maybe<Scalars['String']>
  phone?: Maybe<Scalars['String']>
  slackUserId?: Maybe<Scalars['String']>
  slackUsername?: Maybe<Scalars['String']>
  workdayId?: Maybe<Scalars['String']>
}

export type AdvertiserLogo = {
  __typename?: 'AdvertiserLogo'
  large?: Maybe<Scalars['String']>
  medium?: Maybe<Scalars['String']>
  small?: Maybe<Scalars['String']>
}

export type AdvertiserNote = {
  __typename?: 'AdvertiserNote'
  isHyperLinkable?: Maybe<Scalars['Boolean']>
  text?: Maybe<Scalars['String']>
}

export type AdvertiserReviews = {
  __typename?: 'AdvertiserReviews'
  averageRating?: Maybe<Scalars['Float']>
  count?: Maybe<Scalars['Int']>
}

export type ApplicationAnswers = {
  abadDisclosure?: Maybe<Scalars['Boolean']>
  addDependentAges?: Maybe<Array<Maybe<DependentAge>>>
  affiliatedBusinessArrangementDisclosure?: Maybe<Scalars['Boolean']>
  agentCompany?: Maybe<Scalars['String']>
  agentEmail?: Maybe<Scalars['String']>
  agentFirstName?: Maybe<Scalars['String']>
  agentLastName?: Maybe<Scalars['String']>
  agentPhone?: Maybe<Scalars['String']>
  applicationId?: Maybe<Scalars['Int']>
  apt?: Maybe<Scalars['String']>
  autoCompleteAddressFields?: Maybe<Scalars['String']>
  buyBeforeYouSellAmount?: Maybe<Scalars['Float']>
  buyBeforeYouSellApproved?: Maybe<Scalars['Boolean']>
  cashOut?: Maybe<Scalars['Float']>
  cashOutOptions?: Maybe<Scalars['String']>
  city?: Maybe<Scalars['String']>
  closingDate?: Maybe<Scalars['String']>
  coBorrowerAddresses?: Maybe<Array<Maybe<CoBorrowerAddress>>>
  coBorrowerAffiliatedBusinessArrangementDisclosure?: Maybe<Scalars['Boolean']>
  coBorrowerCreditScore?: Maybe<Scalars['Int']>
  coBorrowerDeclarationAlimony?: Maybe<Scalars['Boolean']>
  coBorrowerDeclarationBankrupt?: Maybe<Scalars['Boolean']>
  coBorrowerDeclarationBankruptcyType?: Maybe<BankruptcyTypes>
  coBorrowerDeclarationCitizen?: Maybe<Scalars['Boolean']>
  coBorrowerDeclarationConveyedTitle?: Maybe<Scalars['Boolean']>
  coBorrowerDeclarationDependents?: Maybe<Scalars['Int']>
  coBorrowerDeclarationDownPaymentBorrowed?: Maybe<Scalars['Boolean']>
  coBorrowerDeclarationDownPaymentBorrowedAmount?: Maybe<Scalars['Int']>
  coBorrowerDeclarationEndorserNote?: Maybe<Scalars['Boolean']>
  coBorrowerDeclarationFHALoan?: Maybe<Scalars['Boolean']>
  coBorrowerDeclarationFederalDebt?: Maybe<Scalars['Boolean']>
  coBorrowerDeclarationForeclosed?: Maybe<Scalars['Boolean']>
  coBorrowerDeclarationForeclosedDirect?: Maybe<Scalars['Boolean']>
  coBorrowerDeclarationLawsuit?: Maybe<Scalars['Boolean']>
  coBorrowerDeclarationMilitary?: Maybe<Scalars['Boolean']>
  coBorrowerDeclarationNewCredit?: Maybe<Scalars['Boolean']>
  coBorrowerDeclarationOutstandingJudgements?: Maybe<Scalars['Boolean']>
  coBorrowerDeclarationOwnership?: Maybe<Scalars['Boolean']>
  coBorrowerDeclarationPrimaryResidence?: Maybe<Scalars['Boolean']>
  coBorrowerDeclarationPropertyTitle?: Maybe<Scalars['String']>
  coBorrowerDeclarationPropertyType?: Maybe<Scalars['String']>
  coBorrowerDeclarationRelationWithSeller?: Maybe<Scalars['Boolean']>
  coBorrowerDeclarationResident?: Maybe<Scalars['Boolean']>
  coBorrowerDeclarationSchoolingYears?: Maybe<Scalars['String']>
  coBorrowerDeclarationSubjectToLien?: Maybe<Scalars['Boolean']>
  coBorrowerDeclarationVALoan?: Maybe<Scalars['Boolean']>
  coBorrowerDemographics?: Maybe<DemographicsAnswers>
  coBorrowerEthnicity?: Maybe<Array<Maybe<Scalars['String']>>>
  coBorrowerGender?: Maybe<Array<Maybe<Scalars['String']>>>
  coBorrowerGrossAnnualIncome?: Maybe<Scalars['Float']>
  coBorrowerIncomeSources?: Maybe<Array<Maybe<IncomeSource>>>
  coBorrowerMonthlyDebtPayments?: Maybe<Scalars['Float']>
  coBorrowerMoveInDate?: Maybe<Scalars['String']>
  coBorrowerOtherAnnualIncomeAmount?: Maybe<Scalars['Float']>
  coBorrowerOtherLiabilities?: Maybe<Scalars['Boolean']>
  coBorrowerOtherLiabilitiesGroup?: Maybe<Array<Maybe<LiabilityAnswers>>>
  coBorrowerPersonalInfo?: Maybe<Array<Maybe<CoBorrowerPersonalInfo>>>
  coBorrowerRace?: Maybe<Array<Maybe<Scalars['String']>>>
  coBorrowerSoftCreditConsent?: Maybe<Scalars['Boolean']>
  coBorrowerSubmissionAuthorization?: Maybe<Scalars['Boolean']>
  coBorrowerTcpaDisclosure?: Maybe<Scalars['Boolean']>
  coBorrowerTermsAndCreditDisclosure?: Maybe<Scalars['Boolean']>
  coBorrowerTermsOfUsePrivacyPolicyAndElectronicConsentAgreement?: Maybe<
    Scalars['Boolean']
  >
  coBorrowerTotalAssets?: Maybe<Scalars['Float']>
  coBorrowerTwoYearsResidence?: Maybe<Scalars['Boolean']>
  coBorrowerUndisclosedBorrowedFundsAmount?: Maybe<Scalars['Float']>
  coBorrowerUndisclosedBorrowedFundsIndicator?: Maybe<Scalars['Boolean']>
  countryOfCitizenship?: Maybe<Scalars['String']>
  creditScore?: Maybe<Scalars['Int']>
  dateOfBirth?: Maybe<Scalars['String']>
  declarationAlimony?: Maybe<Scalars['Boolean']>
  declarationBankrupt?: Maybe<Scalars['Boolean']>
  declarationBankruptcyType?: Maybe<BankruptcyTypes>
  declarationCitizen?: Maybe<Scalars['Boolean']>
  declarationCitizenStatus?: Maybe<Scalars['String']>
  declarationConveyedTitle?: Maybe<Scalars['Boolean']>
  declarationDependents?: Maybe<Scalars['Int']>
  declarationDownPaymentBorrowed?: Maybe<Scalars['Boolean']>
  declarationDownPaymentBorrowedAmount?: Maybe<Scalars['Int']>
  declarationEndorserNote?: Maybe<Scalars['Boolean']>
  declarationFHALoan?: Maybe<Scalars['Boolean']>
  declarationFederalDebt?: Maybe<Scalars['Boolean']>
  declarationForeclosed?: Maybe<Scalars['Boolean']>
  declarationForeclosedDirect?: Maybe<Scalars['Boolean']>
  declarationLawsuit?: Maybe<Scalars['Boolean']>
  declarationMilitary?: Maybe<Scalars['Boolean']>
  declarationNewCredit?: Maybe<Scalars['Boolean']>
  declarationOutstandingJudgements?: Maybe<Scalars['Boolean']>
  declarationOwnership?: Maybe<Scalars['Boolean']>
  declarationPrimaryResidence?: Maybe<Scalars['Boolean']>
  declarationPropertyTitle?: Maybe<Scalars['String']>
  declarationPropertyType?: Maybe<Scalars['String']>
  declarationRelationWithSeller?: Maybe<Scalars['Boolean']>
  declarationResident?: Maybe<Scalars['Boolean']>
  declarationSchoolingYears?: Maybe<Scalars['String']>
  declarationSubjectToLien?: Maybe<Scalars['Boolean']>
  declarationVALoan?: Maybe<Scalars['Boolean']>
  demographics?: Maybe<DemographicsAnswers>
  desiredRateType?: Maybe<Scalars['String']>
  doesCoBorrowerHaveSameAddress?: Maybe<Scalars['Boolean']>
  email?: Maybe<Scalars['String']>
  escrow?: Maybe<Scalars['Boolean']>
  escrowRollIn?: Maybe<Scalars['Boolean']>
  estimatedValue?: Maybe<Scalars['Float']>
  ethnicity?: Maybe<Array<Maybe<Scalars['String']>>>
  expenseFirstMortgage?: Maybe<Scalars['Float']>
  expenseHOAdues?: Maybe<Scalars['Float']>
  expenseHomeownerInsurance?: Maybe<Scalars['Float']>
  expenseMortgageInsurance?: Maybe<Scalars['Float']>
  expensePropertyTax?: Maybe<Scalars['Float']>
  expenseRent?: Maybe<Scalars['Float']>
  expenseSecondMortgage?: Maybe<Scalars['Float']>
  firstName?: Maybe<Scalars['String']>
  firstTimeHomeBuyer?: Maybe<Scalars['Boolean']>
  forbearance?: Maybe<Scalars['Boolean']>
  gender?: Maybe<Array<Maybe<Scalars['String']>>>
  grossAnnualIncome?: Maybe<Scalars['Float']>
  hasCoBorrower?: Maybe<Scalars['Boolean']>
  hasResidedTwoYears?: Maybe<Scalars['Boolean']>
  haveDependents?: Maybe<Scalars['Boolean']>
  homeBusiness?: Maybe<Scalars['Boolean']>
  incomeSources?: Maybe<Array<Maybe<IncomeSource>>>
  isCurrentAddress?: Maybe<Scalars['Boolean']>
  isTakingCashOut?: Maybe<Scalars['Boolean']>
  lastName?: Maybe<Scalars['String']>
  loanPurpose?: Maybe<Scalars['String']>
  maritalStatus?: Maybe<Scalars['String']>
  militaryDetails?: Maybe<Array<Maybe<Scalars['String']>>>
  militaryServiceMember?: Maybe<Scalars['String']>
  militaryServiceTourDate?: Maybe<Scalars['String']>
  monthlyDebtPayments?: Maybe<Scalars['Float']>
  moreThanOneCoBorrower?: Maybe<Scalars['Boolean']>
  nonSpousePropertyRights?: Maybe<Scalars['Boolean']>
  nonSpousePropertyRightsState?: Maybe<Scalars['String']>
  nonSpousePropertyRightsType?: Maybe<Scalars['String']>
  originalCost?: Maybe<Scalars['Int']>
  otherAnnualIncomeAmount?: Maybe<Scalars['Float']>
  personalInfoDisclosure?: Maybe<Scalars['Boolean']>
  phone?: Maybe<Scalars['String']>
  phonePin?: Maybe<Scalars['String']>
  propertyAttachment?: Maybe<Scalars['String']>
  propertyCondoDesignType?: Maybe<Scalars['String']>
  propertyDownPayment?: Maybe<Scalars['Float']>
  propertyNumberOfStories?: Maybe<Scalars['String']>
  propertyPurchasePrice?: Maybe<Scalars['Float']>
  propertyType?: Maybe<Scalars['String']>
  propertyUse?: Maybe<Scalars['String']>
  purchaseKnownAddress?: Maybe<Scalars['Boolean']>
  purchaseStage?: Maybe<Scalars['String']>
  race?: Maybe<Array<Maybe<Scalars['String']>>>
  realEstateOwned?: Maybe<RealEstateOwnedAnswer>
  realEstateOwnedIds?: Maybe<Array<Maybe<Scalars['Int']>>>
  refiReason?: Maybe<Scalars['String']>
  remainingBalance?: Maybe<Scalars['Float']>
  rollInFees?: Maybe<Scalars['Boolean']>
  schooling?: Maybe<Scalars['String']>
  state?: Maybe<Scalars['String']>
  street?: Maybe<Scalars['String']>
  submissionAuthorization?: Maybe<Scalars['Boolean']>
  suffix?: Maybe<Scalars['String']>
  tcpaDisclosure?: Maybe<Scalars['Boolean']>
  termsAndCreditDisclosure?: Maybe<Scalars['Boolean']>
  termsOfUsePrivacyPolicyAndElectronicConsentAgreement?: Maybe<
    Scalars['Boolean']
  >
  timeInHome?: Maybe<Scalars['String']>
  titleHolderLegalNames?: Maybe<Array<Maybe<Scalars['String']>>>
  totalAssets?: Maybe<Scalars['Float']>
  totalMonthlyExpenses?: Maybe<Scalars['Float']>
  typeProperty?: Maybe<Scalars['String']>
  undisclosedBorrowedFundsAmount?: Maybe<Scalars['Float']>
  undisclosedBorrowedFundsIndicator?: Maybe<Scalars['Boolean']>
  yearBuilt?: Maybe<Scalars['Int']>
  yearPurchased?: Maybe<Scalars['Int']>
  zipCode?: Maybe<Scalars['String']>
}

export type ApplicationAnswersOutput = {
  __typename?: 'ApplicationAnswersOutput'
  addDependentAges?: Maybe<Array<Maybe<DependentAgeOutput>>>
  additionalIncome?: Maybe<Scalars['String']>
  addresses?: Maybe<Array<Maybe<AddressOutput>>>
  affiliatedBusinessArrangementDisclosure?: Maybe<Scalars['Boolean']>
  agentCompany?: Maybe<Scalars['String']>
  agentEmail?: Maybe<Scalars['String']>
  agentFirstName?: Maybe<Scalars['String']>
  agentLastName?: Maybe<Scalars['String']>
  agentPhone?: Maybe<Scalars['String']>
  annualGrossIncome?: Maybe<Scalars['Int']>
  applicationId?: Maybe<Scalars['Int']>
  apt?: Maybe<Scalars['String']>
  auth0Id?: Maybe<Scalars['String']>
  autoCompleteAddressFields?: Maybe<Scalars['String']>
  cashOut?: Maybe<Scalars['Float']>
  cashOutOptions?: Maybe<Scalars['String']>
  city?: Maybe<Scalars['String']>
  closingDate?: Maybe<Scalars['String']>
  coBorrowerAdditionalIncome?: Maybe<Scalars['String']>
  coBorrowerAddresses?: Maybe<Array<Maybe<CoBorrowerAddressOutput>>>
  coBorrowerAffiliatedBusinessArrangementDisclosure?: Maybe<Scalars['Boolean']>
  coBorrowerCountryOfCitizenship?: Maybe<Scalars['String']>
  coBorrowerCreditScore?: Maybe<Scalars['Int']>
  coBorrowerDeclarationAlimony?: Maybe<Scalars['String']>
  coBorrowerDeclarationBankrupt?: Maybe<Scalars['String']>
  coBorrowerDeclarationBankruptcyType?: Maybe<BankruptcyTypes>
  coBorrowerDeclarationCitizen?: Maybe<Scalars['String']>
  coBorrowerDeclarationConveyedTitle?: Maybe<Scalars['String']>
  coBorrowerDeclarationDependents?: Maybe<Scalars['Int']>
  coBorrowerDeclarationDownPaymentBorrowed?: Maybe<Scalars['String']>
  coBorrowerDeclarationDownPaymentBorrowedAmount?: Maybe<Scalars['Int']>
  coBorrowerDeclarationEndorserNote?: Maybe<Scalars['String']>
  coBorrowerDeclarationFHALoan?: Maybe<Scalars['String']>
  coBorrowerDeclarationFederalDebt?: Maybe<Scalars['String']>
  coBorrowerDeclarationForeclosed?: Maybe<Scalars['String']>
  coBorrowerDeclarationForeclosedDirect?: Maybe<Scalars['String']>
  coBorrowerDeclarationLawsuit?: Maybe<Scalars['String']>
  coBorrowerDeclarationMilitary?: Maybe<Scalars['String']>
  coBorrowerDeclarationNewCredit?: Maybe<Scalars['String']>
  coBorrowerDeclarationOutstandingJudgements?: Maybe<Scalars['String']>
  coBorrowerDeclarationOwnership?: Maybe<Scalars['String']>
  coBorrowerDeclarationPrimaryResidence?: Maybe<Scalars['String']>
  coBorrowerDeclarationPropertyTitle?: Maybe<Scalars['String']>
  coBorrowerDeclarationPropertyType?: Maybe<Scalars['String']>
  coBorrowerDeclarationResident?: Maybe<Scalars['String']>
  coBorrowerDeclarationSchoolingYears?: Maybe<Scalars['String']>
  coBorrowerDeclarationSubjectToLien?: Maybe<Scalars['String']>
  coBorrowerDeclarationVALoan?: Maybe<Scalars['String']>
  coBorrowerDemographics?: Maybe<Array<Maybe<DemographicsOutput>>>
  coBorrowerEthnicity?: Maybe<Array<Maybe<Scalars['String']>>>
  coBorrowerGender?: Maybe<Array<Maybe<Scalars['String']>>>
  coBorrowerId?: Maybe<Scalars['Int']>
  coBorrowerIncomeSources?: Maybe<Array<Maybe<IncomeSourceOutput>>>
  coBorrowerMoveInDate?: Maybe<Scalars['String']>
  coBorrowerOtherIncomes?: Maybe<Array<Maybe<IncomeSourceOutput>>>
  coBorrowerOtherLiabilities?: Maybe<Scalars['String']>
  coBorrowerOtherLiabilitiesGroup?: Maybe<Array<Maybe<LiabilityOutput>>>
  coBorrowerPersonalInfo?: Maybe<Array<Maybe<CoBorrowerPersonalInfoOutput>>>
  coBorrowerRace?: Maybe<Array<Maybe<Scalars['String']>>>
  coBorrowerTcpaDisclosure?: Maybe<Scalars['Boolean']>
  coBorrowerTermsAndCreditAuthorization?: Maybe<Scalars['Boolean']>
  coBorrowerTermsOfUsePrivacyPolicyAndElectronicConsentAgreement?: Maybe<
    Scalars['Boolean']
  >
  coBorrowerTwoYearsResidence?: Maybe<Scalars['String']>
  coBorrowerUndisclosedBorrowedFundsAmount?: Maybe<Scalars['Float']>
  coBorrowerUndisclosedBorrowedFundsIndicator?: Maybe<Scalars['String']>
  countryOfCitizenship?: Maybe<Scalars['String']>
  creditScore?: Maybe<Scalars['Int']>
  dateOfBirth?: Maybe<Scalars['String']>
  declarationAlimony?: Maybe<Scalars['String']>
  declarationBankrupt?: Maybe<Scalars['String']>
  declarationBankruptcyType?: Maybe<BankruptcyTypes>
  declarationCitizen?: Maybe<Scalars['String']>
  declarationCitizenStatus?: Maybe<Scalars['String']>
  declarationConveyedTitle?: Maybe<Scalars['String']>
  declarationDependents?: Maybe<Scalars['Int']>
  declarationDownPaymentBorrowed?: Maybe<Scalars['String']>
  declarationDownPaymentBorrowedAmount?: Maybe<Scalars['Int']>
  declarationEndorserNote?: Maybe<Scalars['String']>
  declarationFHALoan?: Maybe<Scalars['String']>
  declarationFederalDebt?: Maybe<Scalars['String']>
  declarationForeclosed?: Maybe<Scalars['String']>
  declarationForeclosedDirect?: Maybe<Scalars['String']>
  declarationLawsuit?: Maybe<Scalars['String']>
  declarationMilitary?: Maybe<Scalars['String']>
  declarationNewCredit?: Maybe<Scalars['String']>
  declarationOutstandingJudgements?: Maybe<Scalars['String']>
  declarationOwnership?: Maybe<Scalars['String']>
  declarationPrimaryResidence?: Maybe<Scalars['String']>
  declarationPropertyTitle?: Maybe<Scalars['String']>
  declarationPropertyType?: Maybe<Scalars['String']>
  declarationResident?: Maybe<Scalars['String']>
  declarationSchoolingYears?: Maybe<Scalars['String']>
  declarationSubjectToLien?: Maybe<Scalars['String']>
  declarationVALoan?: Maybe<Scalars['String']>
  demographics?: Maybe<Array<Maybe<DemographicsOutput>>>
  doesCoBorrowerHaveSameAddress?: Maybe<Scalars['String']>
  email?: Maybe<Scalars['String']>
  escrow?: Maybe<Scalars['String']>
  estimatedValue?: Maybe<Scalars['Float']>
  ethnicity?: Maybe<Array<Maybe<Scalars['String']>>>
  expenseFirstMortgage?: Maybe<Scalars['Float']>
  expenseHOAdues?: Maybe<Scalars['Float']>
  expenseHomeownerInsurance?: Maybe<Scalars['Float']>
  expenseMortgageInsurance?: Maybe<Scalars['Float']>
  expensePropertyTax?: Maybe<Scalars['Float']>
  expenseRent?: Maybe<Scalars['Float']>
  expenseSecondMortgage?: Maybe<Scalars['Float']>
  firstName?: Maybe<Scalars['String']>
  forbearance?: Maybe<Scalars['String']>
  gender?: Maybe<Array<Maybe<Scalars['String']>>>
  hasCoBorrower?: Maybe<Scalars['String']>
  hasResidedTwoYears?: Maybe<Scalars['String']>
  hasSavedCoBorrowerSSN?: Maybe<Scalars['Boolean']>
  hasSavedSSN?: Maybe<Scalars['Boolean']>
  haveDependents?: Maybe<Scalars['String']>
  homeBusiness?: Maybe<Scalars['String']>
  incomeSources?: Maybe<Array<Maybe<IncomeSourceOutput>>>
  isCurrentAddress?: Maybe<Scalars['String']>
  isTakingCashOut?: Maybe<Scalars['String']>
  language?: Maybe<Array<Maybe<Scalars['String']>>>
  lastName?: Maybe<Scalars['String']>
  loanPurpose?: Maybe<Scalars['String']>
  maritalStatus?: Maybe<Scalars['String']>
  militaryDetails?: Maybe<Array<Maybe<Scalars['String']>>>
  militaryServiceMember?: Maybe<Scalars['String']>
  militaryServiceTourDate?: Maybe<Scalars['String']>
  monthlyDebts?: Maybe<Scalars['Int']>
  moveInDate?: Maybe<Scalars['String']>
  nonSpousePropertyRights?: Maybe<Scalars['String']>
  nonSpousePropertyRightsState?: Maybe<Scalars['String']>
  nonSpousePropertyRightsType?: Maybe<Scalars['String']>
  otherIncomes?: Maybe<Array<Maybe<IncomeSourceOutput>>>
  otherLiabilities?: Maybe<Scalars['String']>
  otherLiabilitiesGroup?: Maybe<Array<Maybe<LiabilityOutput>>>
  phone?: Maybe<Scalars['String']>
  propertyAttachment?: Maybe<Scalars['String']>
  propertyCity?: Maybe<Scalars['String']>
  propertyDownPayment?: Maybe<Scalars['Float']>
  propertyNumberOfStories?: Maybe<Scalars['String']>
  propertyPurchasePrice?: Maybe<Scalars['Float']>
  propertyState?: Maybe<Scalars['String']>
  propertyType?: Maybe<Scalars['String']>
  propertyUse?: Maybe<Scalars['String']>
  propertyZip?: Maybe<Scalars['String']>
  purchaseKnownAddress?: Maybe<Scalars['String']>
  purchaseStage?: Maybe<Scalars['String']>
  race?: Maybe<Array<Maybe<Scalars['String']>>>
  realEstateOwned?: Maybe<Array<Maybe<RealEstateOwnedOutput>>>
  realEstateOwnedIds?: Maybe<Array<Maybe<Scalars['Int']>>>
  refiReason?: Maybe<Scalars['String']>
  remainingBalance?: Maybe<Scalars['Float']>
  rollInFees?: Maybe<Scalars['String']>
  state?: Maybe<Scalars['String']>
  street?: Maybe<Scalars['String']>
  submissionAuthorization?: Maybe<Scalars['Boolean']>
  tcpaDisclosure?: Maybe<Scalars['Boolean']>
  termsAndCreditAuthorization?: Maybe<Scalars['Boolean']>
  termsOfUsePrivacyPolicyAndElectronicConsentAgreement?: Maybe<
    Scalars['Boolean']
  >
  timeInHome?: Maybe<Scalars['String']>
  titleHolderLegalNames?: Maybe<Array<Maybe<Scalars['String']>>>
  totalAssets?: Maybe<Scalars['Int']>
  undisclosedBorrowedFundsAmount?: Maybe<Scalars['Float']>
  undisclosedBorrowedFundsIndicator?: Maybe<Scalars['String']>
  userId?: Maybe<Scalars['Int']>
  yearBuilt?: Maybe<Scalars['Int']>
  yearPurchased?: Maybe<Scalars['Int']>
  zipCode?: Maybe<Scalars['String']>
}

export type ApplicationDemographics = {
  __typename?: 'ApplicationDemographics'
  americanIndianTribeDesc?: Maybe<Scalars['String']>
  asianOtherSubtypeDesc?: Maybe<Scalars['String']>
  asianSubtype?: Maybe<Array<Maybe<Scalars['String']>>>
}

export type ApplicationDetailsSchema = {
  __typename?: 'ApplicationDetailsSchema'
  addresses?: Maybe<Array<Maybe<AddressRevisionSchema>>>
  agreedToAgentConsent?: Maybe<Scalars['Boolean']>
  alfieRate?: Maybe<RateSelectionSchema>
  assets?: Maybe<Array<Maybe<AssetRevisionSchema>>>
  assignedLoanOfficer?: Maybe<LoanOfficerAdminInfo>
  assignedProcessor?: Maybe<ProcessorInfo>
  ausDocuments?: Maybe<Array<Maybe<AusReportSchema>>>
  avmPropertyDetails?: Maybe<AvmPropertyDetailsSchema>
  beSmarteeLoanId?: Maybe<Scalars['String']>
  borrowerPortalStatus?: Maybe<Scalars['String']>
  coBorrowerApplication?: Maybe<CoBorrowerApplicationDetailsSchema>
  conditionallyApprovedOn?: Maybe<Scalars['String']>
  conditions?: Maybe<Array<Maybe<ConditionSchema>>>
  contactStatus?: Maybe<Scalars['String']>
  createdAt?: Maybe<Scalars['String']>
  creditAuthorized?: Maybe<Scalars['Boolean']>
  creditData?: Maybe<CreditDataSchema>
  creditReportIdentifier?: Maybe<Scalars['String']>
  demographics?: Maybe<DemographicsRevisionSchema>
  disclosureTracking?: Maybe<Array<Maybe<DisclosureTrackingSchema>>>
  documents?: Maybe<Array<Maybe<DocumentRequestSchema>>>
  elloStatus?: Maybe<Scalars['String']>
  encompassLoanReference?: Maybe<ApplicationExternalReferenceSchema>
  fullLiabilities?: Maybe<LiabilitySchema>
  fullStoryLink?: Maybe<Scalars['String']>
  id: Scalars['Int']
  incomes?: Maybe<Array<Maybe<IncomeRevisionSchema>>>
  isExternallyManagedLead?: Maybe<Scalars['Boolean']>
  isIceBox?: Maybe<Scalars['Boolean']>
  isProtected?: Maybe<Scalars['Boolean']>
  lastBorrowerContacted?: Maybe<ContactInfo>
  lastRingCentralContacted?: Maybe<ContactInfo>
  leadDetails?: Maybe<BankrateLead>
  leadType?: Maybe<Scalars['String']>
  lenderLoanId?: Maybe<Scalars['String']>
  liabilities?: Maybe<Array<Maybe<LiabilityRevisionSchema>>>
  loan?: Maybe<EncompassLoanImportSchema>
  loanOfficerAssignedAt?: Maybe<Scalars['String']>
  loanOriginationFee?: Maybe<Scalars['Float']>
  lockedRate?: Maybe<LockedRateSchema>
  messageThreads?: Maybe<Array<Maybe<MessageThreadSchema>>>
  notes?: Maybe<Array<Maybe<ApplicationNotesSchema>>>
  ntlDisqualifiedReason?: Maybe<Scalars['String']>
  prelockedRate?: Maybe<LockedRateSchema>
  prequalCreditReportIdentifier?: Maybe<Scalars['String']>
  prequalRevision?: Maybe<PrequalRevisionSchema>
  pricingException?: Maybe<PricingExceptionSchema>
  pricingScenario?: Maybe<ApplicationPricing>
  realEstateAgent?: Maybe<SageContactSchema>
  relatedApps?: Maybe<Array<Maybe<UserApplicationSchema>>>
  reminder?: Maybe<ApplicationReminderSchema>
  reo?: Maybe<Array<Maybe<RealEstateOwnedSchema>>>
  reportingInfo?: Maybe<Array<Maybe<ApplicationReportingSchema>>>
  revision?: Maybe<ApplicationRevisionDetailsSchema>
  source?: Maybe<Scalars['String']>
  status?: Maybe<Scalars['String']>
  statusCreatedAt?: Maybe<Scalars['String']>
  submittedToUnderwritingOn?: Maybe<Scalars['String']>
  tasks?: Maybe<Array<Maybe<ApplicationTask>>>
  tridDueDate?: Maybe<Scalars['String']>
  tridMetadata?: Maybe<TridMetadataSchema>
  user?: Maybe<User>
  userId?: Maybe<Scalars['Int']>
  uuid: Scalars['String']
  withdrawRequestedAt?: Maybe<Scalars['String']>
}

export type ApplicationDetailsStatusSchema = {
  __typename?: 'ApplicationDetailsStatusSchema'
  applicationId?: Maybe<Scalars['Int']>
  applicationRevisionId?: Maybe<Scalars['Int']>
  createdAt?: Maybe<Scalars['String']>
  id: Scalars['Int']
  status?: Maybe<Scalars['String']>
  statusSource?: Maybe<Scalars['String']>
}

export type ApplicationExternalEventSchema = {
  __typename?: 'ApplicationExternalEventSchema'
  applicationId?: Maybe<Scalars['Int']>
  createdAt?: Maybe<Scalars['String']>
  decryptedError?: Maybe<Scalars['JSON']>
  errorKey?: Maybe<Scalars['String']>
  externalServiceName?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['Int']>
  jobId?: Maybe<Scalars['Int']>
  name?: Maybe<Scalars['String']>
  status?: Maybe<Scalars['String']>
  updatedAt?: Maybe<Scalars['String']>
}

export type ApplicationExternalReferenceSchema = {
  __typename?: 'ApplicationExternalReferenceSchema'
  applicationId?: Maybe<Scalars['Int']>
  createdAt?: Maybe<Scalars['String']>
  deletedAt?: Maybe<Scalars['String']>
  externalFieldName?: Maybe<Scalars['String']>
  externalId?: Maybe<Scalars['String']>
  externalServiceName?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['Int']>
  lockReason?: Maybe<Scalars['String']>
  syncLocked?: Maybe<Scalars['Boolean']>
  updatedAt?: Maybe<Scalars['String']>
}

export type ApplicationLiabilitiesSchema = {
  __typename?: 'ApplicationLiabilitiesSchema'
  accountNumber?: Maybe<Scalars['String']>
  apt?: Maybe<Scalars['String']>
  city?: Maybe<Scalars['String']>
  creditLine?: Maybe<Scalars['Float']>
  creditor?: Maybe<Scalars['String']>
  liabilityType?: Maybe<Scalars['String']>
  monthlyExpense?: Maybe<Scalars['Float']>
  mortgageType?: Maybe<Scalars['String']>
  otherDescription?: Maybe<Scalars['String']>
  ownershipType?: Maybe<Scalars['String']>
  remainingTermMonths?: Maybe<Scalars['Int']>
  state?: Maybe<Scalars['String']>
  street?: Maybe<Scalars['String']>
  toBePaidOff?: Maybe<Scalars['Boolean']>
  unpaidBalance?: Maybe<Scalars['Float']>
  zip?: Maybe<Scalars['String']>
}

export type ApplicationLoAssignmentSchema = {
  __typename?: 'ApplicationLoAssignmentSchema'
  applicationId?: Maybe<Scalars['Int']>
  createdAt?: Maybe<Scalars['String']>
  deletedAt?: Maybe<Scalars['String']>
  id: Scalars['Int']
  loanOfficerId?: Maybe<Scalars['Int']>
  updatedAt?: Maybe<Scalars['String']>
}

export type ApplicationLoanOfficerActionRevisionRequest = {
  applicationId: Scalars['Int']
}

export type ApplicationLoanOfficerActionRevisionResponse = {
  __typename?: 'ApplicationLoanOfficerActionRevisionResponse'
  actionStatus?: Maybe<Scalars['String']>
  applicationId?: Maybe<Scalars['Int']>
  contactStatus?: Maybe<Scalars['String']>
  createdAt?: Maybe<Scalars['String']>
  createdBy?: Maybe<Scalars['Int']>
  deletedAt?: Maybe<Scalars['String']>
  id: Scalars['Int']
  nextRevision?: Maybe<Scalars['Int']>
  updatedAt?: Maybe<Scalars['String']>
}

export type ApplicationNoteSaveRequest = {
  applicationId: Scalars['Int']
  applicationRevisionId?: Maybe<Scalars['Int']>
  authorFullName?: Maybe<Scalars['String']>
  note: Scalars['String']
  retoolId?: Maybe<Scalars['Int']>
}

export type ApplicationNotesSchema = {
  __typename?: 'ApplicationNotesSchema'
  applicationId?: Maybe<Scalars['Int']>
  applicationRevisionId?: Maybe<Scalars['Int']>
  authorName?: Maybe<Scalars['String']>
  createdAt?: Maybe<Scalars['String']>
  deletedAt?: Maybe<Scalars['String']>
  id: Scalars['Int']
  notes?: Maybe<Scalars['String']>
  retoolId?: Maybe<Scalars['Int']>
}

export type ApplicationPricing = {
  __typename?: 'ApplicationPricing'
  address?: Maybe<Scalars['String']>
  allLenders?: Maybe<Scalars['Boolean']>
  applicationId: Scalars['Int']
  cashOut?: Maybe<Scalars['Int']>
  city?: Maybe<Scalars['String']>
  coBorrowerName?: Maybe<Scalars['String']>
  company?: Maybe<Scalars['String']>
  costOfLoanYears?: Maybe<Scalars['Int']>
  creditScore?: Maybe<Scalars['Int']>
  debtToIncome?: Maybe<Scalars['Int']>
  defaultLockPeriod?: Maybe<Scalars['Int']>
  defaultQuotingChannel?: Maybe<Scalars['String']>
  escrowOptions?: Maybe<EscrowOptions>
  firstTimeUsingVaLoan?: Maybe<Scalars['Boolean']>
  incomeType?: Maybe<Scalars['String']>
  leadSource?: Maybe<Scalars['String']>
  loanAmount?: Maybe<Scalars['Float']>
  loanOfficerId?: Maybe<Scalars['Int']>
  loanPurpose?: Maybe<LoanPurpose>
  loanTerm?: Maybe<ProductTerm>
  loanTerms?: Maybe<Array<Maybe<ProductTerm>>>
  loanToValue?: Maybe<Scalars['Float']>
  lockFormRequestType?: Maybe<LockRequestType>
  lockId?: Maybe<Scalars['String']>
  lockPeriod?: Maybe<Scalars['Int']>
  monthlyInsurance?: Maybe<Scalars['Float']>
  name?: Maybe<Scalars['String']>
  points?: Maybe<PointEnum>
  product?: Maybe<Scalars['String']>
  productClass?: Maybe<Scalars['String']>
  productFamilies?: Maybe<Array<Maybe<ProductFamily>>>
  productFamily?: Maybe<ProductFamily>
  propertyDownPayment?: Maybe<Scalars['Float']>
  propertyPurchasePrice?: Maybe<Scalars['Float']>
  propertyTax?: Maybe<Scalars['Float']>
  propertyType?: Maybe<PropertyType>
  propertyUse?: Maybe<PropertyUse>
  propertyValue?: Maybe<Scalars['Int']>
  quotingChannel?: Maybe<QuotingChannel>
  remainingBalance?: Maybe<Scalars['Float']>
  rollInEscrow?: Maybe<Scalars['Float']>
  rollInFee?: Maybe<Scalars['Int']>
  selfEmployed?: Maybe<Scalars['Boolean']>
  state?: Maybe<StateAbbreviation>
  subordinateLien?: Maybe<Scalars['Int']>
  subordinateLienCreditLimit?: Maybe<Scalars['Float']>
  useFeesInChannel?: Maybe<Scalars['Boolean']>
  veteranDisabled?: Maybe<Scalars['Boolean']>
  veteranType?: Maybe<VeteranType>
  waiveEscrow?: Maybe<Scalars['Boolean']>
  zip?: Maybe<Scalars['String']>
}

export type ApplicationReminderSaveRequest = {
  applicationId: Scalars['Int']
  reminderDate: Scalars['String']
  reminderType: Scalars['String']
}

export type ApplicationReminderSchema = {
  __typename?: 'ApplicationReminderSchema'
  applicationId: Scalars['Int']
  createdAt?: Maybe<Scalars['String']>
  deletedAt?: Maybe<Scalars['String']>
  id: Scalars['Int']
  reminderDate: Scalars['String']
  reminderType: Scalars['String']
}

export type ApplicationReportingSchema = {
  __typename?: 'ApplicationReportingSchema'
  actionDate?: Maybe<Scalars['String']>
  actionTaken?: Maybe<Scalars['String']>
  applicationId?: Maybe<Scalars['Int']>
  ausRunDate?: Maybe<Scalars['String']>
  clearToCloseOn?: Maybe<Scalars['String']>
  closingLoanOfficerId?: Maybe<Scalars['Int']>
  conditionallyApprovedOn?: Maybe<Scalars['String']>
  contractContingencyDate?: Maybe<Scalars['String']>
  contractSigned?: Maybe<Scalars['String']>
  createdAt?: Maybe<Scalars['String']>
  creditReportFee?: Maybe<Scalars['Int']>
  creditedUnderwriter?: Maybe<Scalars['String']>
  deletedAt?: Maybe<Scalars['String']>
  deniedOn?: Maybe<Scalars['String']>
  disbursementDate?: Maybe<Scalars['String']>
  dti?: Maybe<Scalars['Float']>
  edisclosureSignedOn?: Maybe<Scalars['String']>
  estClosingDate?: Maybe<Scalars['String']>
  extendedOn?: Maybe<Scalars['String']>
  extensionAmount?: Maybe<Scalars['Int']>
  extensionCount?: Maybe<Scalars['Int']>
  fundedOn?: Maybe<Scalars['String']>
  gainLossPercentage?: Maybe<Scalars['Float']>
  gainLossTotal?: Maybe<Scalars['Int']>
  id: Scalars['Int']
  isEmployeeLoan?: Maybe<Scalars['Boolean']>
  isFloat?: Maybe<Scalars['Boolean']>
  lenderAddress?: Maybe<Scalars['String']>
  lenderEmail?: Maybe<Scalars['String']>
  lenderLoanId?: Maybe<Scalars['String']>
  lenderName?: Maybe<Scalars['String']>
  lenderPhone?: Maybe<Scalars['String']>
  loanAmount?: Maybe<Scalars['Int']>
  loanProduct?: Maybe<Scalars['String']>
  lockExpirationDate?: Maybe<Scalars['String']>
  lockPeriod?: Maybe<Scalars['String']>
  lockedOn?: Maybe<Scalars['String']>
  ltv?: Maybe<Scalars['Float']>
  openingLoanOfficerId?: Maybe<Scalars['Int']>
  originalLockPeriod?: Maybe<Scalars['Int']>
  partnerCompensationFinal?: Maybe<Scalars['Int']>
  partnerCompensationInitial?: Maybe<Scalars['Int']>
  preapprovalCreditOnly?: Maybe<Scalars['String']>
  preapprovalDate?: Maybe<Scalars['String']>
  preapprovalPortfolioLoan?: Maybe<Scalars['String']>
  preapprovalUwOnly?: Maybe<Scalars['String']>
  pricingExceptionAmount?: Maybe<Scalars['Int']>
  processorId?: Maybe<Scalars['Int']>
  propertyInspectionWaiverEligible?: Maybe<Scalars['Boolean']>
  relocked?: Maybe<Scalars['Boolean']>
  revenueAtLock?: Maybe<Scalars['Int']>
  sageExtensionCost?: Maybe<Scalars['Int']>
  salesContractDate?: Maybe<Scalars['String']>
  submittedToUnderwritingOn?: Maybe<Scalars['String']>
  toleranceCures?: Maybe<Scalars['Int']>
  totalExtensionCost?: Maybe<Scalars['Int']>
  updatedAt?: Maybe<Scalars['String']>
  updatedBy?: Maybe<Scalars['String']>
  withdrawnOn?: Maybe<Scalars['String']>
}

export type ApplicationRevisionDetailsSchema = {
  __typename?: 'ApplicationRevisionDetailsSchema'
  abadDisclosure?: Maybe<Scalars['Boolean']>
  address?: Maybe<Scalars['String']>
  address2?: Maybe<Scalars['String']>
  amortizationType?: Maybe<Scalars['String']>
  annualHouseholdIncome?: Maybe<Scalars['Float']>
  applicationId?: Maybe<Scalars['Int']>
  appraisalFee?: Maybe<Scalars['Float']>
  cashOut?: Maybe<Scalars['Float']>
  city?: Maybe<Scalars['String']>
  closingDate?: Maybe<Scalars['String']>
  closingDisclosureReceivedDate?: Maybe<Scalars['String']>
  closingDisclosureSentDate?: Maybe<Scalars['String']>
  contractMustCloseBy?: Maybe<Scalars['String']>
  countryOfCitizenship?: Maybe<Scalars['String']>
  county?: Maybe<Scalars['String']>
  createdAt?: Maybe<Scalars['String']>
  creditScore?: Maybe<Scalars['Int']>
  dateOfBirth?: Maybe<Scalars['String']>
  debtToIncome?: Maybe<Scalars['Float']>
  declarationAlimony?: Maybe<Scalars['Boolean']>
  declarationBankrupcy?: Maybe<Scalars['Boolean']>
  declarationBankruptcyType?: Maybe<Scalars['String']>
  declarationBorrowedDown?: Maybe<Scalars['Boolean']>
  declarationBorrowedDownFundsAmount?: Maybe<Scalars['Float']>
  declarationCitizen?: Maybe<Scalars['Boolean']>
  declarationCitizenStatus?: Maybe<Scalars['String']>
  declarationConveyedTitle?: Maybe<Scalars['Boolean']>
  declarationEthnicity?: Maybe<Array<Maybe<Scalars['String']>>>
  declarationFhaLoan?: Maybe<Scalars['Boolean']>
  declarationForeclosures?: Maybe<Scalars['Boolean']>
  declarationGender?: Maybe<Array<Maybe<Scalars['String']>>>
  declarationHomeOwnership?: Maybe<Scalars['Boolean']>
  declarationJudgements?: Maybe<Scalars['Boolean']>
  declarationLawsuits?: Maybe<Scalars['Boolean']>
  declarationLoanDefaults?: Maybe<Scalars['Boolean']>
  declarationLoanForeclosures?: Maybe<Scalars['Boolean']>
  declarationMilitary?: Maybe<Scalars['Boolean']>
  declarationNoteEndorser?: Maybe<Scalars['Boolean']>
  declarationPrimaryResidence?: Maybe<Scalars['Boolean']>
  declarationPropertyTitle?: Maybe<Scalars['String']>
  declarationPropertyType?: Maybe<Scalars['String']>
  declarationRace?: Maybe<Array<Maybe<Scalars['String']>>>
  declarationRelationWithSeller?: Maybe<Scalars['Boolean']>
  declarationResident?: Maybe<Scalars['Boolean']>
  declarationSchooling?: Maybe<Scalars['String']>
  declarationSubjectToLien?: Maybe<Scalars['Boolean']>
  declarationUndisclosedCreditApplicationIndicator?: Maybe<Scalars['Boolean']>
  declarationVaLoan?: Maybe<Scalars['Boolean']>
  dependentsAges?: Maybe<Array<Maybe<Scalars['Int']>>>
  desiredRateType?: Maybe<Scalars['String']>
  dueDiligence?: Maybe<Scalars['Float']>
  earnestMoney?: Maybe<Scalars['Float']>
  email?: Maybe<Scalars['String']>
  escrow?: Maybe<Scalars['Boolean']>
  expectedRentalIncome?: Maybe<Scalars['Float']>
  expenseFirstMortgage?: Maybe<Scalars['Float']>
  expenseHoaDues?: Maybe<Scalars['Float']>
  expenseHomeInsurance?: Maybe<Scalars['Float']>
  expenseMortgageInsurance?: Maybe<Scalars['Float']>
  expensePropertyTax?: Maybe<Scalars['Float']>
  expenseRent?: Maybe<Scalars['Float']>
  expenseSecondMortgage?: Maybe<Scalars['Float']>
  fipsCode?: Maybe<Scalars['String']>
  firstName?: Maybe<Scalars['String']>
  firstTimeHomeBuyer?: Maybe<Scalars['Boolean']>
  forbearance?: Maybe<Scalars['Boolean']>
  frozenCreditCheck?: Maybe<Scalars['Boolean']>
  hasCompletedDisclosures?: Maybe<Scalars['Boolean']>
  hasRefinancedBefore?: Maybe<Scalars['Boolean']>
  homeBusiness?: Maybe<Scalars['Boolean']>
  iceBox?: Maybe<Scalars['Boolean']>
  id: Scalars['Int']
  interestRate?: Maybe<Scalars['Float']>
  isCurrentAddress?: Maybe<Scalars['Boolean']>
  isCurrentPropertyListed?: Maybe<Scalars['Boolean']>
  isRollInEscrow?: Maybe<Scalars['Boolean']>
  jointApplicantIntention?: Maybe<Scalars['Boolean']>
  lastName?: Maybe<Scalars['String']>
  latestLoanAmount?: Maybe<Scalars['Float']>
  latestLoanDate?: Maybe<Scalars['String']>
  leadGoal?: Maybe<Scalars['String']>
  loDisclosureAcknowledgedBy?: Maybe<Scalars['String']>
  loDisclosureAcknowledgedDate?: Maybe<Scalars['String']>
  loPriorityLevel?: Maybe<Scalars['String']>
  loanAmount?: Maybe<Scalars['Float']>
  loanPurpose?: Maybe<Scalars['String']>
  loanToValue?: Maybe<Scalars['Float']>
  maritalStatus?: Maybe<Scalars['String']>
  militaryDetails?: Maybe<Array<Maybe<Scalars['String']>>>
  militaryServiceMember?: Maybe<Scalars['String']>
  militaryServiceTourDate?: Maybe<Scalars['String']>
  monthlyDebtPayments?: Maybe<Scalars['Float']>
  moveExplanation?: Maybe<Scalars['String']>
  nameAliases?: Maybe<Array<Maybe<NameAlias>>>
  needToSellBeforePurchase?: Maybe<Scalars['Boolean']>
  nextRevision?: Maybe<Scalars['Int']>
  nonSpousePropertyRights?: Maybe<Scalars['Boolean']>
  nonSpousePropertyRightsState?: Maybe<Scalars['String']>
  nonSpousePropertyRightsType?: Maybe<Scalars['String']>
  notes?: Maybe<Scalars['String']>
  originalLoanTerm?: Maybe<Scalars['Float']>
  phone?: Maybe<Scalars['String']>
  preapprovedWithOtherLender?: Maybe<Scalars['Boolean']>
  previousCashOut?: Maybe<Scalars['Boolean']>
  previousRefinance?: Maybe<Scalars['Boolean']>
  previousRefinanceCloseDate?: Maybe<Scalars['String']>
  promotionMentioned?: Maybe<Scalars['Boolean']>
  propertyAttachment?: Maybe<Scalars['String']>
  propertyCondoDesignType?: Maybe<Scalars['String']>
  propertyDownPayment?: Maybe<Scalars['Float']>
  propertyInspectionWaiver?: Maybe<Scalars['Boolean']>
  propertyNumberOfStories?: Maybe<Scalars['String']>
  propertyPurchasePrice?: Maybe<Scalars['Float']>
  propertyType?: Maybe<Scalars['String']>
  propertyUse?: Maybe<Scalars['String']>
  propertyValue?: Maybe<Scalars['Float']>
  propertyYearBuilt?: Maybe<Scalars['Int']>
  propertyYearPurchased?: Maybe<Scalars['Int']>
  proposedExpenseFirstMortgage?: Maybe<Scalars['Float']>
  proposedExpenseHoaDues?: Maybe<Scalars['Float']>
  proposedExpenseHomeInsurance?: Maybe<Scalars['Float']>
  proposedExpenseMortgageInsurance?: Maybe<Scalars['Float']>
  proposedExpensePropertyTax?: Maybe<Scalars['Float']>
  proposedExpenseSecondMortgage?: Maybe<Scalars['Float']>
  purchaseStage?: Maybe<Scalars['String']>
  refiReason?: Maybe<Scalars['String']>
  refinanceExplanation?: Maybe<Scalars['String']>
  remainingBalance?: Maybe<Scalars['Float']>
  revisionType?: Maybe<Scalars['String']>
  rollInFees?: Maybe<Scalars['Boolean']>
  secondPhone?: Maybe<Scalars['String']>
  sellerCredit?: Maybe<Scalars['Float']>
  ssnAlias?: Maybe<Scalars['String']>
  state?: Maybe<Scalars['String']>
  submissionAuthorization?: Maybe<Scalars['Boolean']>
  suffix?: Maybe<Scalars['String']>
  tcpaDisclosure?: Maybe<Scalars['Boolean']>
  termsAndCreditAuthorization?: Maybe<Scalars['Boolean']>
  termsOfUseDisclosure?: Maybe<Scalars['Boolean']>
  tilIntlDisclosureProvidedDate?: Maybe<Scalars['String']>
  timeInHome?: Maybe<Scalars['String']>
  titleHolderLegalNames?: Maybe<Array<Maybe<Scalars['String']>>>
  titleMannerHeld?: Maybe<Scalars['String']>
  totalAssets?: Maybe<Scalars['Float']>
  undisclosedBorrowedFundsAmount?: Maybe<Scalars['Float']>
  undisclosedBorrowedFundsIndicator?: Maybe<Scalars['Boolean']>
  zip?: Maybe<Scalars['String']>
}

export type ApplicationRevisionSchema = {
  __typename?: 'ApplicationRevisionSchema'
  address?: Maybe<Scalars['String']>
  address2?: Maybe<Scalars['String']>
  applicationId?: Maybe<Scalars['Int']>
  cashOut?: Maybe<Scalars['Int']>
  city?: Maybe<Scalars['String']>
  createdAt?: Maybe<Scalars['String']>
  creditScore?: Maybe<Scalars['Int']>
  dateOfBirth?: Maybe<Scalars['String']>
  debtToIncome?: Maybe<Scalars['Int']>
  declarationAlimony?: Maybe<Scalars['Boolean']>
  declarationBankrupcy?: Maybe<Scalars['Boolean']>
  declarationBorrowedDown?: Maybe<Scalars['Boolean']>
  declarationBorrowedDownFundsAmount?: Maybe<Scalars['Int']>
  declarationCitizen?: Maybe<Scalars['Boolean']>
  declarationDependents?: Maybe<Scalars['Int']>
  declarationEthnicity?: Maybe<Array<Maybe<Scalars['String']>>>
  declarationFhaLoan?: Maybe<Scalars['Boolean']>
  declarationForeclosures?: Maybe<Scalars['Boolean']>
  declarationGender?: Maybe<Array<Maybe<Scalars['String']>>>
  declarationHomeOwnership?: Maybe<Scalars['Boolean']>
  declarationJudgements?: Maybe<Scalars['Boolean']>
  declarationLawsuits?: Maybe<Scalars['Boolean']>
  declarationLoanDefaults?: Maybe<Scalars['Boolean']>
  declarationLoanForeclosures?: Maybe<Scalars['Boolean']>
  declarationMilitary?: Maybe<Scalars['Boolean']>
  declarationNoteEndorser?: Maybe<Scalars['Boolean']>
  declarationPrimaryResidence?: Maybe<Scalars['Boolean']>
  declarationPropertyTitle?: Maybe<Scalars['String']>
  declarationPropertyType?: Maybe<Scalars['String']>
  declarationRace?: Maybe<Array<Maybe<Scalars['String']>>>
  declarationResident?: Maybe<Scalars['Boolean']>
  declarationSchooling?: Maybe<Scalars['String']>
  declarationVaLoan?: Maybe<Scalars['Boolean']>
  deletedAt?: Maybe<Scalars['String']>
  email?: Maybe<Scalars['String']>
  escrow?: Maybe<Scalars['Boolean']>
  expenseFirstMortgage?: Maybe<Scalars['Int']>
  expenseHoaDues?: Maybe<Scalars['Int']>
  expenseHomeInsurance?: Maybe<Scalars['Int']>
  expenseMortgageInsurance?: Maybe<Scalars['Int']>
  expensePropertyTaxes?: Maybe<Scalars['Int']>
  expenseRent?: Maybe<Scalars['Int']>
  expenseSecondMortgage?: Maybe<Scalars['Int']>
  firstName?: Maybe<Scalars['String']>
  forbearance?: Maybe<Scalars['Boolean']>
  iceBox?: Maybe<Scalars['Boolean']>
  id: Scalars['Int']
  isCurrentAddress?: Maybe<Scalars['Boolean']>
  lastName?: Maybe<Scalars['String']>
  loanAmount?: Maybe<Scalars['Int']>
  loanPurpose?: Maybe<Scalars['String']>
  loanToValue?: Maybe<Scalars['Int']>
  maritalStatus?: Maybe<Scalars['String']>
  militaryDetails?: Maybe<Array<Maybe<Scalars['String']>>>
  militaryServiceMember?: Maybe<Scalars['String']>
  militaryServiceTourDate?: Maybe<Scalars['String']>
  nextRevision?: Maybe<Scalars['Int']>
  notes?: Maybe<Scalars['String']>
  phone?: Maybe<Scalars['String']>
  propertyAttachment?: Maybe<Scalars['String']>
  propertyDownPayment?: Maybe<Scalars['Int']>
  propertyNumberOfStories?: Maybe<Scalars['String']>
  propertyPurchasePrice?: Maybe<Scalars['Int']>
  propertyType?: Maybe<Scalars['String']>
  propertyUse?: Maybe<Scalars['String']>
  propertyValue?: Maybe<Scalars['Int']>
  propertyYearBuilt?: Maybe<Scalars['Int']>
  propertyYearPurchased?: Maybe<Scalars['Int']>
  refiReason?: Maybe<Scalars['String']>
  remainingBalance?: Maybe<Scalars['Int']>
  revisionType?: Maybe<Scalars['String']>
  rollInFees?: Maybe<Scalars['Boolean']>
  ssnAlias?: Maybe<Scalars['String']>
  state?: Maybe<Scalars['String']>
  timeInHome?: Maybe<Scalars['String']>
  titleHolderLegalNames?: Maybe<Array<Maybe<Scalars['String']>>>
  updatedAt?: Maybe<Scalars['String']>
  zip?: Maybe<Scalars['String']>
}

export type ApplicationSchema = {
  __typename?: 'ApplicationSchema'
  bankrateLeadId?: Maybe<Scalars['String']>
  besmarteeLoanId?: Maybe<Scalars['String']>
  createdAt?: Maybe<Scalars['String']>
  creditReportIdentifier?: Maybe<Scalars['String']>
  deletedAt?: Maybe<Scalars['String']>
  experienceType?: Maybe<Scalars['String']>
  id: Scalars['Int']
  leadId?: Maybe<Scalars['Int']>
  lenderLoanId?: Maybe<Scalars['String']>
  name?: Maybe<Scalars['String']>
  ntlDisqualifiedReason?: Maybe<Scalars['String']>
  updatedAt?: Maybe<Scalars['String']>
  userId?: Maybe<Scalars['Int']>
}

export type ApplicationStatusSchema = {
  __typename?: 'ApplicationStatusSchema'
  applicationId?: Maybe<Scalars['Int']>
  applicationRevisionId?: Maybe<Scalars['Int']>
  createdAt?: Maybe<Scalars['String']>
  deletedAt?: Maybe<Scalars['String']>
  id: Scalars['Int']
  status?: Maybe<Scalars['String']>
  statusSource?: Maybe<Scalars['String']>
  updatedAt?: Maybe<Scalars['String']>
}

export type ApplicationTask = {
  __typename?: 'ApplicationTask'
  applicationId?: Maybe<Scalars['Int']>
  createdAt?: Maybe<Scalars['String']>
  id: Scalars['Int']
  status?: Maybe<Scalars['String']>
  task?: Maybe<Task>
  taskId?: Maybe<Scalars['Int']>
  userId?: Maybe<Scalars['Int']>
}

export type ArmDetails = {
  __typename?: 'ArmDetails'
  index?: Maybe<Scalars['Float']>
  indexType?: Maybe<Scalars['String']>
  initialCap?: Maybe<Scalars['Float']>
  lifetimeCap?: Maybe<Scalars['Float']>
  margin?: Maybe<Scalars['Float']>
  periodicCap?: Maybe<Scalars['Float']>
  rateAdjustmentTerm?: Maybe<Scalars['Int']>
  rateFixedTerm?: Maybe<Scalars['Int']>
}

export type ArmDetailsInput = {
  index?: Maybe<Scalars['Float']>
  indexType?: Maybe<Scalars['String']>
  initialCap?: Maybe<Scalars['Float']>
  lifetimeCap?: Maybe<Scalars['Float']>
  margin?: Maybe<Scalars['Float']>
  periodicCap?: Maybe<Scalars['Float']>
  rateAdjustmentTerm?: Maybe<Scalars['Int']>
  rateFixedTerm?: Maybe<Scalars['Int']>
}

export type AssetInput = {
  accountName?: Maybe<Scalars['String']>
  accountNumber?: Maybe<Scalars['String']>
  bankName?: Maybe<Scalars['String']>
  currentBalance?: Maybe<Scalars['Float']>
  deposited?: Maybe<Scalars['Boolean']>
  gifterRelationship?: Maybe<GifterRelationship>
  id?: Maybe<Scalars['Int']>
  marketValue?: Maybe<Scalars['Float']>
  numberOfShares?: Maybe<Scalars['Int']>
  ownershipClassification?: Maybe<OwnershipClassification>
  totalValue?: Maybe<Scalars['Float']>
  type?: Maybe<AssetType>
}

export type AssetRevisionSchema = {
  __typename?: 'AssetRevisionSchema'
  accountName?: Maybe<Scalars['String']>
  accountNumber?: Maybe<Scalars['String']>
  bankName?: Maybe<Scalars['String']>
  createdAt?: Maybe<Scalars['String']>
  currentBalance?: Maybe<Scalars['Float']>
  deposited?: Maybe<Scalars['Boolean']>
  gifterRelationship?: Maybe<GifterRelationship>
  id?: Maybe<Scalars['Int']>
  marketValue?: Maybe<Scalars['Float']>
  numberOfShares?: Maybe<Scalars['Int']>
  ownershipClassification?: Maybe<OwnershipClassification>
  source?: Maybe<Scalars['String']>
  totalValue?: Maybe<Scalars['Float']>
  type?: Maybe<AssetType>
}

export enum AssetType {
  Bonds = 'Bonds',
  BorrowerPaidOutsideClosing = 'BorrowerPaidOutsideClosing',
  BridgeLoanProceeds = 'BridgeLoanProceeds',
  CashOnHand = 'CashOnHand',
  CashValueOfLifeInsurance = 'CashValueOfLifeInsurance',
  CertificateOfDepositTimeDeposit = 'CertificateOfDepositTimeDeposit',
  Checking = 'Checking',
  EarnestMoneyCashDepositTowardPurchase = 'EarnestMoneyCashDepositTowardPurchase',
  EmployerAssistance = 'EmployerAssistance',
  Gift = 'Gift',
  GiftOfCash = 'GiftOfCash',
  GiftOfPropertyEquity = 'GiftOfPropertyEquity',
  Grant = 'Grant',
  IndividualDevelopmentAccount = 'IndividualDevelopmentAccount',
  LeasePurchaseFund = 'LeasePurchaseFund',
  LotEquity = 'LotEquity',
  MoneyMarket = 'MoneyMarket',
  MutualFund = 'MutualFund',
  OtherLiquidAssets = 'OtherLiquidAssets',
  OtherNonLiquidAssets = 'OtherNonLiquidAssets',
  OtherPurchaseCredit = 'OtherPurchaseCredit',
  ProceedsFromRealEstateProperty = 'ProceedsFromRealEstateProperty',
  ProceedsFromSale = 'ProceedsFromSale',
  RelocationFunds = 'RelocationFunds',
  RentCredit = 'RentCredit',
  Retirement = 'Retirement',
  Savings = 'Savings',
  SecuredBorrowedFundsNotDeposited = 'SecuredBorrowedFundsNotDeposited',
  StockOptions = 'StockOptions',
  Stocks = 'Stocks',
  SweatEquity = 'SweatEquity',
  TradeEquity = 'TradeEquity',
  TrustAccount = 'TrustAccount',
  UnsecuredBorrowedFunds = 'UnsecuredBorrowedFunds',
}

export type AttachLenderLoanIdRequest = {
  applicationId: Scalars['Int']
  lenderLoanId: Scalars['String']
  lenderName: Scalars['String']
  loanOfficerId: Scalars['Int']
  processorId: Scalars['Int']
  requestType: LockRequestType
}

export type AttachmentSchema = {
  __typename?: 'AttachmentSchema'
  createdAt?: Maybe<Scalars['String']>
  deletedAt?: Maybe<Scalars['String']>
  documentRequestId?: Maybe<Scalars['Int']>
  encompassId?: Maybe<Scalars['String']>
  fileName?: Maybe<Scalars['String']>
  ignoreReason?: Maybe<Scalars['String']>
  isAccepted?: Maybe<Scalars['Boolean']>
  type?: Maybe<Scalars['String']>
  updatedAt?: Maybe<Scalars['String']>
  uuid?: Maybe<Scalars['String']>
}

export type AusReportSchema = {
  __typename?: 'AusReportSchema'
  applicationId?: Maybe<Scalars['Int']>
  createdAt?: Maybe<Scalars['String']>
  externalId?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['Int']>
  reportDocumentUuid?: Maybe<Scalars['String']>
  reportFileType?: Maybe<Scalars['String']>
  reportSource?: Maybe<Scalars['String']>
  reportType?: Maybe<Scalars['String']>
  status?: Maybe<Scalars['String']>
  updatedAt?: Maybe<Scalars['String']>
}

export type Auth0AccountCreationResponse = {
  __typename?: 'Auth0AccountCreationResponse'
  auth0Id: Scalars['String']
  successful: Scalars['Boolean']
  token?: Maybe<Auth0Token>
}

export type Auth0Token = {
  __typename?: 'Auth0Token'
  access_token?: Maybe<Scalars['String']>
  expires_in?: Maybe<Scalars['Int']>
  token_type?: Maybe<Scalars['String']>
}

export type AuthorSchema = {
  __typename?: 'AuthorSchema'
  id?: Maybe<Scalars['Int']>
  name?: Maybe<Scalars['String']>
  participantId?: Maybe<Scalars['Int']>
}

export type AutoZeroInput = {
  maxCashToClose?: Maybe<Scalars['Int']>
  maxLoanToValue?: Maybe<Scalars['Float']>
  maxThreshold?: Maybe<Scalars['Float']>
  minCashToClose?: Maybe<Scalars['Int']>
  rateRequest: RatesRequest
  selectedLoanTerm: ProductTerm
  selectedRate: Scalars['Float']
}

export type AutoZeroResponse = {
  __typename?: 'AutoZeroResponse'
  amountAdded: Scalars['Float']
  cashToClose: Scalars['Float']
  loanToValue: Scalars['Float']
  successfulMatch: Scalars['Boolean']
  totalRollInFees: Scalars['Float']
}

export type AvmPropertyDataRequest = {
  applicationId?: Maybe<Scalars['Int']>
  apt?: Maybe<Scalars['String']>
  city: Scalars['String']
  state: Scalars['String']
  street: Scalars['String']
  zipCode: Scalars['String']
}

export type AvmPropertyDataResult = {
  __typename?: 'AvmPropertyDataResult'
  avmMonthlyPropertyTax?: Maybe<Scalars['Float']>
  avmPropertyType?: Maybe<Scalars['String']>
  avmPropertyValue?: Maybe<Scalars['Int']>
  avmSaleDate?: Maybe<Scalars['String']>
}

export type BankrateAdvertiser = {
  __typename?: 'BankrateAdvertiser'
  city?: Maybe<Scalars['String']>
  description?: Maybe<Scalars['String']>
  email?: Maybe<Scalars['String']>
  features?: Maybe<Array<Maybe<Scalars['String']>>>
  hoursOfOperation?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['Int']>
  isFeatured?: Maybe<Scalars['Boolean']>
  isFuse?: Maybe<Scalars['Boolean']>
  lenderCredit?: Maybe<Scalars['Float']>
  logo?: Maybe<AdvertiserLogo>
  name?: Maybe<Scalars['String']>
  nmlsLicense?: Maybe<Scalars['String']>
  note?: Maybe<AdvertiserNote>
  phone?: Maybe<Scalars['String']>
  reviews?: Maybe<AdvertiserReviews>
  score?: Maybe<Scalars['Int']>
  seoName?: Maybe<Scalars['String']>
  specials?: Maybe<Array<Maybe<Scalars['String']>>>
  state?: Maybe<Scalars['String']>
  stateLicense?: Maybe<Scalars['String']>
  surveyInstitutionId?: Maybe<Scalars['Int']>
}

export type BankrateAnswers = {
  __typename?: 'BankrateAnswers'
  cashOut?: Maybe<Scalars['Int']>
  city?: Maybe<Scalars['String']>
  currentMortgageBalance?: Maybe<Scalars['Int']>
  currentMortgageRate?: Maybe<Scalars['Float']>
  declarationMilitary?: Maybe<Scalars['String']>
  email?: Maybe<Scalars['String']>
  estimatedValue?: Maybe<Scalars['Int']>
  firstName?: Maybe<Scalars['String']>
  grossAnnualIncome?: Maybe<Scalars['Int']>
  id?: Maybe<Scalars['String']>
  isTakingCashOut?: Maybe<Scalars['String']>
  lastName?: Maybe<Scalars['String']>
  loanPurpose?: Maybe<Scalars['String']>
  monthlyDebtsAmount?: Maybe<Scalars['Int']>
  phone?: Maybe<Scalars['String']>
  previouslySelectedRate?: Maybe<BankrateSelectedRateAnswer>
  propertyCity?: Maybe<Scalars['String']>
  propertyDownPayment?: Maybe<Scalars['Float']>
  propertyPurchasePrice?: Maybe<Scalars['Float']>
  propertyState?: Maybe<Scalars['String']>
  propertyType?: Maybe<Scalars['String']>
  propertyUse?: Maybe<Scalars['String']>
  propertyZip?: Maybe<Scalars['String']>
  remainingBalance?: Maybe<Scalars['Int']>
  state?: Maybe<StateAbbreviation>
  street?: Maybe<Scalars['String']>
  totalAssets?: Maybe<Scalars['Int']>
  zipCode?: Maybe<Scalars['String']>
}

export type BankrateArmDetails = {
  __typename?: 'BankrateArmDetails'
  index?: Maybe<Scalars['Float']>
  initialCap?: Maybe<Scalars['Float']>
  lifetimeCap?: Maybe<Scalars['Float']>
  margin?: Maybe<Scalars['Float']>
  periodicCap?: Maybe<Scalars['Float']>
  rateAdjustmentTerm?: Maybe<Scalars['Int']>
  rateFixedTerm?: Maybe<Scalars['Int']>
}

export type BankrateCompareLeadInformation = {
  apt?: Maybe<Scalars['String']>
  cashOut?: Maybe<Scalars['Float']>
  city?: Maybe<Scalars['String']>
  declarationFHALoan?: Maybe<Scalars['Boolean']>
  declarationMilitary?: Maybe<Scalars['Boolean']>
  declarationVALoan?: Maybe<Scalars['Boolean']>
  email?: Maybe<Scalars['String']>
  estimatedValue?: Maybe<Scalars['Float']>
  firstName?: Maybe<Scalars['String']>
  lastName?: Maybe<Scalars['String']>
  leadSource: Scalars['String']
  leadType?: Maybe<LeadTypes>
  loanOfficerId?: Maybe<Scalars['Int']>
  loanPurpose?: Maybe<LoanPurposes>
  phone?: Maybe<Scalars['String']>
  propertyDownPayment?: Maybe<Scalars['Float']>
  propertyPurchasePrice?: Maybe<Scalars['Float']>
  propertyType?: Maybe<PropertyTypes>
  propertyUse?: Maybe<PropertyUses>
  rate?: Maybe<CompareRateInput>
  remainingBalance?: Maybe<Scalars['Float']>
  sourceLeadId: Scalars['String']
  state?: Maybe<Scalars['String']>
  street?: Maybe<Scalars['String']>
  zipCode?: Maybe<Scalars['String']>
}

export type BankrateFees = {
  __typename?: 'BankrateFees'
  HUDLine?: Maybe<Scalars['Int']>
  amount?: Maybe<Scalars['Float']>
  description?: Maybe<Scalars['String']>
  required?: Maybe<Scalars['Boolean']>
}

export type BankrateLead = {
  __typename?: 'BankrateLead'
  agentCompany?: Maybe<Scalars['String']>
  agentEmail?: Maybe<Scalars['String']>
  agentFirstName?: Maybe<Scalars['String']>
  agentLastName?: Maybe<Scalars['String']>
  agentPhone?: Maybe<Scalars['String']>
  annualGrossIncome?: Maybe<Scalars['Int']>
  assetsAmount?: Maybe<Scalars['Int']>
  averageMonthlyExpense?: Maybe<Scalars['Int']>
  averageMonthlyIncome?: Maybe<Scalars['Int']>
  bankrateAprSelection?: Maybe<Scalars['Float']>
  bankrateLeadId: Scalars['String']
  bankrateProductSelection?: Maybe<Scalars['String']>
  bankrateRateSelection?: Maybe<Scalars['Float']>
  cashOutAmount?: Maybe<Scalars['Int']>
  creditScore?: Maybe<Scalars['Int']>
  currentAddress?: Maybe<Scalars['String']>
  currentCity?: Maybe<Scalars['String']>
  currentState?: Maybe<Scalars['String']>
  debtToIncome?: Maybe<Scalars['Int']>
  deviceType?: Maybe<Scalars['String']>
  downPaymentPercent?: Maybe<Scalars['Float']>
  email?: Maybe<Scalars['String']>
  employmentStatus?: Maybe<Scalars['String']>
  estimatedPropertyValue?: Maybe<Scalars['Int']>
  existingRateType?: Maybe<Scalars['Float']>
  externalChannel?: Maybe<Scalars['String']>
  feesAmount?: Maybe<Scalars['Float']>
  firstMortgageBalance?: Maybe<Scalars['Float']>
  firstMortgageRate?: Maybe<Scalars['Float']>
  firstName?: Maybe<Scalars['String']>
  hasFHALoan?: Maybe<Scalars['Boolean']>
  hasFiledBankruptcy?: Maybe<Scalars['Boolean']>
  hasLatePayment?: Maybe<Scalars['Boolean']>
  hasProofOfIncome?: Maybe<Scalars['Boolean']>
  hasSecondMortgage?: Maybe<Scalars['Boolean']>
  housePurchaseYear?: Maybe<Scalars['Int']>
  isPrimaryLead?: Maybe<Scalars['Boolean']>
  lastName?: Maybe<Scalars['String']>
  leadSource?: Maybe<Scalars['String']>
  loanAmount?: Maybe<Scalars['Float']>
  loanOfficerNmls?: Maybe<Scalars['String']>
  loanType?: Maybe<Scalars['String']>
  military?: Maybe<Scalars['Boolean']>
  monthlyDebtsAmount?: Maybe<Scalars['Int']>
  offerTags?: Maybe<Array<Maybe<Scalars['String']>>>
  otherIncomeSource?: Maybe<Scalars['Float']>
  phone?: Maybe<Scalars['String']>
  propertyAddress?: Maybe<Scalars['String']>
  propertyCity?: Maybe<Scalars['String']>
  propertyStateAbr?: Maybe<StateAbbreviation>
  propertyType?: Maybe<PropertyType>
  propertyUse?: Maybe<PropertyUse>
  propertyZip?: Maybe<Scalars['String']>
  purchasePrice?: Maybe<Scalars['Int']>
  purchaseStep?: Maybe<Scalars['String']>
  refUrl?: Maybe<Scalars['String']>
  userAgent?: Maybe<Scalars['String']>
  verified?: Maybe<Scalars['Boolean']>
  zip?: Maybe<Scalars['String']>
}

export type BankrateLeadRequest = {
  agentCompany?: Maybe<Scalars['String']>
  agentEmail?: Maybe<Scalars['String']>
  agentFirstName?: Maybe<Scalars['String']>
  agentLastName?: Maybe<Scalars['String']>
  agentPhone?: Maybe<Scalars['String']>
  annualGrossIncome?: Maybe<Scalars['Int']>
  assetsAmount?: Maybe<Scalars['Int']>
  averageMonthlyExpense?: Maybe<Scalars['Int']>
  averageMonthlyIncome?: Maybe<Scalars['Int']>
  bankrateAprSelection?: Maybe<Scalars['Float']>
  bankrateLeadId: Scalars['String']
  bankrateProductSelection?: Maybe<Scalars['String']>
  bankrateRateSelection?: Maybe<Scalars['Float']>
  /** Cash out only specified for Refinance loanTypes */
  cashOutAmount?: Maybe<Scalars['Int']>
  creditScore?: Maybe<Scalars['Int']>
  currentAddress?: Maybe<Scalars['String']>
  currentCity?: Maybe<Scalars['String']>
  currentState?: Maybe<Scalars['String']>
  debtToIncome?: Maybe<Scalars['Int']>
  deviceType?: Maybe<Scalars['String']>
  /** Down payment only specified for Purchase loanTypes */
  downPaymentPercent?: Maybe<Scalars['Float']>
  email?: Maybe<Scalars['String']>
  employmentStatus?: Maybe<EmploymentStatus>
  /** Estimated property value only specified for Refinance loanTypes */
  estimatedPropertyValue?: Maybe<Scalars['Int']>
  /** Existing rate type only specified for Refinance loanTypes */
  existingRateType?: Maybe<ExistingRateType>
  externalChannel?: Maybe<Scalars['String']>
  feesAmount?: Maybe<Scalars['Float']>
  /** First mortgage balance only specified for Refinance loanTypes */
  firstMortgageBalance?: Maybe<Scalars['Float']>
  /** First mortgage rate only specified for Refinance loanTypes */
  firstMortgageRate?: Maybe<Scalars['Float']>
  firstName?: Maybe<Scalars['String']>
  hasFHALoan?: Maybe<Scalars['Boolean']>
  hasFiledBankruptcy?: Maybe<Scalars['Boolean']>
  hasLatePayment?: Maybe<Scalars['Boolean']>
  hasProofOfIncome?: Maybe<Scalars['Boolean']>
  hasSecondMortgage?: Maybe<Scalars['Boolean']>
  housePurchaseYear?: Maybe<Scalars['Int']>
  isPrimaryLead?: Maybe<Scalars['Boolean']>
  lastName?: Maybe<Scalars['String']>
  leadSource?: Maybe<Scalars['String']>
  loanAmount?: Maybe<Scalars['Float']>
  /** Bankrate Pro specific fields */
  loanOfficerNmls?: Maybe<Scalars['String']>
  loanType?: Maybe<LoanType>
  military?: Maybe<Scalars['Boolean']>
  monthlyDebtsAmount?: Maybe<Scalars['Int']>
  offerTags?: Maybe<Array<Maybe<Scalars['String']>>>
  otherIncomeSource?: Maybe<Scalars['Float']>
  phone?: Maybe<Scalars['String']>
  propertyAddress?: Maybe<Scalars['String']>
  propertyCity?: Maybe<Scalars['String']>
  propertyStateAbr?: Maybe<StateAbbreviation>
  propertyType?: Maybe<PropertyType>
  propertyUse?: Maybe<PropertyUse>
  propertyZip?: Maybe<Scalars['String']>
  /** Purchase price only specified for Purchase loanTypes */
  purchasePrice?: Maybe<Scalars['Int']>
  purchaseStep?: Maybe<Scalars['String']>
  refUrl?: Maybe<Scalars['String']>
  userAgent?: Maybe<Scalars['String']>
  verified?: Maybe<Scalars['Boolean']>
  zip?: Maybe<Scalars['String']>
}

export type BankrateOffer = {
  __typename?: 'BankrateOffer'
  adSetId?: Maybe<Scalars['String']>
  advertiser?: Maybe<BankrateAdvertiser>
  apr?: Maybe<Scalars['Float']>
  armDetails?: Maybe<BankrateArmDetails>
  click_link?: Maybe<Scalars['String']>
  creative?: Maybe<BapCreative>
  date?: Maybe<Scalars['String']>
  displayTargets?: Maybe<Array<Maybe<Scalars['String']>>>
  eightYearCost?: Maybe<Scalars['Float']>
  estimatedPayment?: Maybe<Scalars['Float']>
  fees?: Maybe<Array<Maybe<BankrateFees>>>
  fiveYearCost?: Maybe<Scalars['Float']>
  id?: Maybe<Scalars['String']>
  isFeatured?: Maybe<Scalars['Boolean']>
  isPaid?: Maybe<Scalars['Boolean']>
  lockDays?: Maybe<Scalars['Int']>
  points?: Maybe<Scalars['Float']>
  product?: Maybe<BankrateProduct>
  rate?: Maybe<Scalars['Float']>
  source?: Maybe<Scalars['String']>
  tags?: Maybe<Array<Maybe<Scalars['String']>>>
  tracking?: Maybe<TrackingLink>
  type?: Maybe<Scalars['String']>
  upFrontCosts?: Maybe<Scalars['Float']>
}

export type BankrateProduct = {
  __typename?: 'BankrateProduct'
  fixedMonths?: Maybe<Scalars['Int']>
  id?: Maybe<Scalars['Int']>
  isFHA?: Maybe<Scalars['Boolean']>
  isInterestOnly?: Maybe<Scalars['Boolean']>
  isVA?: Maybe<Scalars['Boolean']>
  name?: Maybe<Scalars['String']>
  points?: Maybe<Scalars['Float']>
  pointsBand?: Maybe<Scalars['String']>
  purpose?: Maybe<Scalars['String']>
  size?: Maybe<Scalars['String']>
  term?: Maybe<Scalars['String']>
  type?: Maybe<Scalars['String']>
}

export type BankrateRate = {
  __typename?: 'BankrateRate'
  apr?: Maybe<Scalars['Float']>
  bankrateAdvertiser?: Maybe<BankrateAdvertiser>
  closingCost?: Maybe<Scalars['Float']>
  costOfLoan8Years?: Maybe<Scalars['Float']>
  discountPoints?: Maybe<Scalars['Float']>
  principleAndInterestPayment?: Maybe<Scalars['Float']>
  rate?: Maybe<Scalars['Float']>
  term?: Maybe<Scalars['String']>
  trackingLink?: Maybe<Scalars['String']>
}

export type BankrateSelectedRateAnswer = {
  __typename?: 'BankrateSelectedRateAnswer'
  loanAmount: Scalars['Int']
  rate: Scalars['Float']
  termInMonths: Scalars['Int']
}

export enum BankruptcyTypes {
  ChapterEleven = 'ChapterEleven',
  ChapterSeven = 'ChapterSeven',
  ChapterThirteen = 'ChapterThirteen',
  ChapterTwelve = 'ChapterTwelve',
}

export type BapCreative = {
  __typename?: 'BapCreative'
  advertiserDisplayName?: Maybe<Scalars['String']>
  description?: Maybe<Scalars['String']>
  hoursOfOperation?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['String']>
  landingUrl?: Maybe<Scalars['String']>
  name?: Maybe<Scalars['String']>
  type?: Maybe<Scalars['String']>
  uuid?: Maybe<Scalars['String']>
}

export type BorrowerInfoSchema = {
  __typename?: 'BorrowerInfoSchema'
  firstName?: Maybe<Scalars['String']>
  lastName?: Maybe<Scalars['String']>
}

export type BulkReassignResponse = {
  __typename?: 'BulkReassignResponse'
  errorCount: Scalars['Int']
  responseMessage?: Maybe<Scalars['String']>
  successCount: Scalars['Int']
}

export type CallFrom = {
  __typename?: 'CallFrom'
  extensionNumber?: Maybe<Scalars['String']>
  location?: Maybe<Scalars['String']>
  phoneNumber?: Maybe<Scalars['String']>
}

export type CallLog = {
  __typename?: 'CallLog'
  action?: Maybe<Scalars['String']>
  direction?: Maybe<Scalars['String']>
  duration?: Maybe<Scalars['Float']>
  from?: Maybe<CallFrom>
  id?: Maybe<Scalars['String']>
  recording?: Maybe<CallRecording>
  result?: Maybe<Scalars['String']>
  sessionId?: Maybe<Scalars['String']>
  startTime?: Maybe<Scalars['String']>
  telephonySessionId?: Maybe<Scalars['String']>
  to?: Maybe<CallTo>
  type?: Maybe<Scalars['String']>
}

export type CallRecording = {
  __typename?: 'CallRecording'
  contentUri?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['String']>
  type?: Maybe<Scalars['String']>
  uri?: Maybe<Scalars['String']>
}

export type CallTo = {
  __typename?: 'CallTo'
  extensionNumber?: Maybe<Scalars['String']>
  location?: Maybe<Scalars['String']>
  phoneNumber?: Maybe<Scalars['String']>
}

export type ChecklistTask = {
  __typename?: 'ChecklistTask'
  applicationId?: Maybe<Scalars['Int']>
  field?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['Int']>
  lastUpdated?: Maybe<Scalars['String']>
  result?: Maybe<Scalars['String']>
  status?: Maybe<Scalars['String']>
}

export type ClosingFeeAnswer = {
  answer: Scalars['String']
  questionName: Scalars['String']
}

export type CoBorrowerAddress = {
  apt?: Maybe<Scalars['String']>
  city?: Maybe<Scalars['String']>
  county?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['Int']>
  moveInDate?: Maybe<Scalars['String']>
  moveOutDate?: Maybe<Scalars['String']>
  occupancyType?: Maybe<OccupancyType>
  rentAmount?: Maybe<Scalars['Float']>
  state?: Maybe<Scalars['String']>
  street?: Maybe<Scalars['String']>
  type?: Maybe<AddressType>
  zipCode?: Maybe<Scalars['String']>
}

export type CoBorrowerAddressOutput = {
  __typename?: 'CoBorrowerAddressOutput'
  apt?: Maybe<Scalars['String']>
  city?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['Int']>
  moveInDate?: Maybe<Scalars['String']>
  moveOutDate?: Maybe<Scalars['String']>
  state?: Maybe<Scalars['String']>
  street?: Maybe<Scalars['String']>
  type?: Maybe<AddressType>
  zipCode?: Maybe<Scalars['String']>
}

export type CoBorrowerApplicationDetailsSchema = {
  __typename?: 'CoBorrowerApplicationDetailsSchema'
  addresses?: Maybe<Array<Maybe<AddressRevisionSchema>>>
  assets?: Maybe<Array<Maybe<AssetRevisionSchema>>>
  creditAuthorized?: Maybe<Scalars['Boolean']>
  demographics?: Maybe<DemographicsRevisionSchema>
  fullLiabilities?: Maybe<LiabilitySchema>
  id?: Maybe<Scalars['Int']>
  incomes?: Maybe<Array<Maybe<IncomeRevisionSchema>>>
  liabilities?: Maybe<Array<Maybe<LiabilityRevisionSchema>>>
  revision?: Maybe<CoBorrowerApplicationRevisionDetailsSchema>
  userId?: Maybe<Scalars['Int']>
  uuid?: Maybe<Scalars['String']>
}

export type CoBorrowerApplicationRevisionDetailsSchema = {
  __typename?: 'CoBorrowerApplicationRevisionDetailsSchema'
  applicationId?: Maybe<Scalars['Int']>
  coBorrowerId?: Maybe<Scalars['Int']>
  coBorrowerTermsAndCreditAuthorization?: Maybe<Scalars['Boolean']>
  countryOfCitizenship?: Maybe<Scalars['String']>
  createdAt?: Maybe<Scalars['String']>
  creditScore?: Maybe<Scalars['Int']>
  dateOfBirth?: Maybe<Scalars['String']>
  declarationAlimony?: Maybe<Scalars['Boolean']>
  declarationBankrupcy?: Maybe<Scalars['Boolean']>
  declarationBankruptcyType?: Maybe<Scalars['String']>
  declarationBorrowedDown?: Maybe<Scalars['Boolean']>
  declarationBorrowedDownFundsAmount?: Maybe<Scalars['Float']>
  declarationCitizen?: Maybe<Scalars['Boolean']>
  declarationCitizenStatus?: Maybe<Scalars['String']>
  declarationConveyedTitle?: Maybe<Scalars['Boolean']>
  declarationEthnicity?: Maybe<Array<Maybe<Scalars['String']>>>
  declarationFhaLoan?: Maybe<Scalars['Boolean']>
  declarationForeclosures?: Maybe<Scalars['Boolean']>
  declarationGender?: Maybe<Array<Maybe<Scalars['String']>>>
  declarationHomeOwnership?: Maybe<Scalars['Boolean']>
  declarationJudgements?: Maybe<Scalars['Boolean']>
  declarationLawsuits?: Maybe<Scalars['Boolean']>
  declarationLoanDefaults?: Maybe<Scalars['Boolean']>
  declarationLoanForeclosures?: Maybe<Scalars['Boolean']>
  declarationMilitary?: Maybe<Scalars['Boolean']>
  declarationNoteEndorser?: Maybe<Scalars['Boolean']>
  declarationPrimaryResidence?: Maybe<Scalars['Boolean']>
  declarationPropertyTitle?: Maybe<Scalars['String']>
  declarationPropertyType?: Maybe<Scalars['String']>
  declarationRace?: Maybe<Array<Maybe<Scalars['String']>>>
  declarationRelationWithSeller?: Maybe<Scalars['Boolean']>
  declarationResident?: Maybe<Scalars['Boolean']>
  declarationSchooling?: Maybe<Scalars['String']>
  declarationSubjectToLien?: Maybe<Scalars['Boolean']>
  declarationUndisclosedCreditApplicationIndicator?: Maybe<Scalars['Boolean']>
  declarationVaLoan?: Maybe<Scalars['Boolean']>
  email?: Maybe<Scalars['String']>
  financesUsedToQualify?: Maybe<Scalars['Boolean']>
  firstName?: Maybe<Scalars['String']>
  firstTimeHomeBuyer?: Maybe<Scalars['Boolean']>
  id: Scalars['Int']
  lastName?: Maybe<Scalars['String']>
  loanPurpose?: Maybe<Scalars['String']>
  maritalStatus?: Maybe<Scalars['String']>
  marriedToPrimary?: Maybe<Scalars['Boolean']>
  militaryDetails?: Maybe<Array<Maybe<Scalars['String']>>>
  militaryServiceTourDate?: Maybe<Scalars['String']>
  nameAliases?: Maybe<Array<Maybe<NameAlias>>>
  nextRevision?: Maybe<Scalars['Int']>
  nonSpousePropertyRights?: Maybe<Scalars['Boolean']>
  nonSpousePropertyRightsState?: Maybe<Scalars['String']>
  nonSpousePropertyRightsType?: Maybe<Scalars['String']>
  phone?: Maybe<Scalars['String']>
  revisionType?: Maybe<Scalars['String']>
  sameAddressAsPrimary?: Maybe<Scalars['Boolean']>
  ssnAlias?: Maybe<Scalars['String']>
  suffix?: Maybe<Scalars['String']>
  undisclosedBorrowedFundsAmount?: Maybe<Scalars['Float']>
  undisclosedBorrowedFundsIndicator?: Maybe<Scalars['Boolean']>
}

export type CoBorrowerPersonalInfo = {
  addDependentAges?: Maybe<Array<Maybe<DependentAge>>>
  countryOfCitizenship?: Maybe<Scalars['String']>
  dateOfBirth?: Maybe<Scalars['String']>
  declarationCitizenStatus?: Maybe<Scalars['String']>
  email?: Maybe<Scalars['String']>
  firstName?: Maybe<Scalars['String']>
  lastName?: Maybe<Scalars['String']>
  maritalStatus?: Maybe<Scalars['String']>
  marriedToPrimary?: Maybe<Scalars['Boolean']>
  nonSpousePropertyRights?: Maybe<Scalars['Boolean']>
  nonSpousePropertyRightsState?: Maybe<Scalars['String']>
  nonSpousePropertyRightsType?: Maybe<Scalars['String']>
  phone?: Maybe<Scalars['String']>
  suffix?: Maybe<Scalars['String']>
  tcpaDisclosure?: Maybe<Scalars['Boolean']>
}

export type CoBorrowerPersonalInfoOutput = {
  __typename?: 'CoBorrowerPersonalInfoOutput'
  addDependentAges?: Maybe<Array<Maybe<DependentAgeOutput>>>
  dateOfBirth?: Maybe<Scalars['String']>
  declarationCitizenStatus?: Maybe<Scalars['String']>
  email?: Maybe<Scalars['String']>
  firstName?: Maybe<Scalars['String']>
  lastName?: Maybe<Scalars['String']>
  maritalStatus?: Maybe<Scalars['String']>
  marriedToPrimary?: Maybe<Scalars['String']>
  nonSpousePropertyRights?: Maybe<Scalars['String']>
  nonSpousePropertyRightsState?: Maybe<Scalars['String']>
  nonSpousePropertyRightsType?: Maybe<Scalars['String']>
  phone?: Maybe<Scalars['String']>
  tcpaDisclosure?: Maybe<Scalars['Boolean']>
}

export type CommunicationLog = {
  __typename?: 'CommunicationLog'
  email?: Maybe<Scalars['String']>
  messages?: Maybe<Array<Maybe<CommunicationLogMessage>>>
  name?: Maybe<Scalars['String']>
  phone?: Maybe<Scalars['String']>
}

export type CommunicationLogMessage = {
  __typename?: 'CommunicationLogMessage'
  automatedNote?: Maybe<Scalars['String']>
  createdAt?: Maybe<Scalars['String']>
  link?: Maybe<Scalars['String']>
  logType?: Maybe<CommunicationLogType>
  sender?: Maybe<Sender>
  subject?: Maybe<Scalars['String']>
  text?: Maybe<Scalars['String']>
}

export type CommunicationLogResponse = {
  __typename?: 'CommunicationLogResponse'
  borrower?: Maybe<CommunicationLog>
  coBorrower?: Maybe<CommunicationLog>
}

export enum CommunicationLogType {
  Email = 'email',
  Fax = 'fax',
  Phone = 'phone',
  Sms = 'sms',
  Status = 'status',
}

export type CompareCreditScoresResponse = {
  __typename?: 'CompareCreditScoresResponse'
  isEqual?: Maybe<Scalars['Boolean']>
}

export type CompareRateInput = {
  adjustments?: Maybe<Array<Maybe<AdjustmentInput>>>
  adjustmentsTotal?: Maybe<Scalars['Float']>
  apr?: Maybe<Scalars['Float']>
  borrowerName?: Maybe<Scalars['String']>
  cashOut?: Maybe<Scalars['Int']>
  combinedLoanToValue?: Maybe<Scalars['Float']>
  creditScore?: Maybe<Scalars['Int']>
  discountOrCreditAmount?: Maybe<Scalars['Float']>
  discountPoints?: Maybe<Scalars['Float']>
  discountPointsAsDollarAmount?: Maybe<Scalars['Float']>
  escrowFees?: Maybe<Scalars['Float']>
  family?: Maybe<Scalars['String']>
  fees?: Maybe<Array<Maybe<FeesInput>>>
  fewerUpfrontCosts?: Maybe<Scalars['Float']>
  finalPrice?: Maybe<Scalars['Float']>
  isRollInEscrow?: Maybe<Scalars['Boolean']>
  lenderName?: Maybe<Scalars['String']>
  loanAmount?: Maybe<Scalars['Float']>
  loanEstimateSections?: Maybe<LoanEstimateSectionsInput>
  loanOfficer?: Maybe<LoanOfficerDisplayInfoInput>
  loanPurpose?: Maybe<Scalars['String']>
  loanTerm?: Maybe<Scalars['Int']>
  loanToValue?: Maybe<Scalars['Float']>
  lockId?: Maybe<Scalars['String']>
  lockPeriod?: Maybe<Scalars['Int']>
  monthlyEscrowFees?: Maybe<Scalars['Float']>
  monthlyInsurance?: Maybe<Scalars['Float']>
  monthlyPayment?: Maybe<Scalars['Float']>
  monthlyPropertyTax?: Maybe<Scalars['Float']>
  mortgageInsurancePremium?: Maybe<MortgageInsurancePremiumInput>
  originationPoints?: Maybe<Scalars['Float']>
  points?: Maybe<Scalars['Int']>
  principleAndInterestPayment?: Maybe<Scalars['Float']>
  productClass?: Maybe<ProductClass>
  propertyValue?: Maybe<Scalars['Float']>
  quoteType?: Maybe<Scalars['String']>
  rate?: Maybe<Scalars['Float']>
  rollInFees?: Maybe<Scalars['Int']>
  silkFees?: Maybe<Array<Maybe<FeesInput>>>
  subordinateLienAmount?: Maybe<Scalars['Int']>
  term?: Maybe<Scalars['String']>
  totalLenderFees?: Maybe<Scalars['Float']>
  type?: Maybe<Scalars['String']>
}

export type ConditionCheck = {
  __typename?: 'ConditionCheck'
  details?: Maybe<ConditionCheckDetails>
  pass?: Maybe<Scalars['Boolean']>
}

export type ConditionCheckDetails = {
  __typename?: 'ConditionCheckDetails'
  assetRevisionAccountLastFourDigits?: Maybe<
    Array<Maybe<AccountLastFourDigits>>
  >
  assetRevisionAccountNames?: Maybe<Array<Maybe<Scalars['String']>>>
  incomeRevisionEmployerNames?: Maybe<Array<Maybe<Scalars['String']>>>
  ocrAccountLastFourDigits?: Maybe<Array<Maybe<AccountLastFourDigits>>>
  ocrAccountNames?: Maybe<Array<Maybe<Scalars['String']>>>
  ocrEmployerNames?: Maybe<Array<Maybe<Scalars['String']>>>
}

export type ConditionChecks = {
  __typename?: 'ConditionChecks'
  accountLastFourDigits?: Maybe<ConditionCheck>
  accountName?: Maybe<ConditionCheck>
  employerName?: Maybe<ConditionCheck>
}

export type ConditionInput = {
  description?: Maybe<Scalars['String']>
  documentRequests?: Maybe<Array<Maybe<DocumentRequestInput>>>
  expirationDate?: Maybe<Scalars['String']>
  title?: Maybe<Scalars['String']>
  type?: Maybe<ConditionType>
  uuid?: Maybe<Scalars['String']>
}

export type ConditionSchema = {
  __typename?: 'ConditionSchema'
  applicationId?: Maybe<Scalars['Int']>
  checks?: Maybe<ConditionChecks>
  coBorrowerApplicationId?: Maybe<Scalars['Int']>
  createdAt?: Maybe<Scalars['String']>
  description?: Maybe<Scalars['String']>
  documentRequests?: Maybe<Array<Maybe<DocumentRequestSchema>>>
  expirationDate?: Maybe<Scalars['String']>
  title?: Maybe<Scalars['String']>
  type?: Maybe<ConditionType>
  uuid?: Maybe<Scalars['String']>
}

export enum ConditionType {
  PostClosing = 'PostClosing',
  Preliminary = 'Preliminary',
  Underwriting = 'Underwriting',
}

export type ConfigurationFieldInput = {
  hasAutoTextMessagesEnabled?: Maybe<Scalars['Boolean']>
  isClientCareSpecialist?: Maybe<Scalars['Boolean']>
}

export type ConfigurationFields = {
  __typename?: 'ConfigurationFields'
  hasAutoTextMessagesEnabled?: Maybe<Scalars['Boolean']>
  isClientCareSpecialist?: Maybe<Scalars['Boolean']>
}

export type ContactInfo = {
  __typename?: 'ContactInfo'
  at?: Maybe<Scalars['String']>
  type?: Maybe<Scalars['String']>
}

export type ContactTemplate = {
  __typename?: 'ContactTemplate'
  id?: Maybe<Scalars['Int']>
  name?: Maybe<Scalars['String']>
  retoolId?: Maybe<Scalars['Int']>
  retoolUserType?: Maybe<Scalars['String']>
  text?: Maybe<Scalars['String']>
  type?: Maybe<Scalars['String']>
}

export type CreateLoanOfficerInput = {
  NMLS?: Maybe<Scalars['String']>
  calendlyLink?: Maybe<Scalars['String']>
  email: Scalars['String']
  encompassId?: Maybe<Scalars['String']>
  headshot?: Maybe<Scalars['String']>
  isLoanCoordinator?: Maybe<Scalars['Boolean']>
  isManager?: Maybe<Scalars['Boolean']>
  loanCoordinatorId?: Maybe<Scalars['Int']>
  managerId?: Maybe<Scalars['Int']>
  name: Scalars['String']
  phone?: Maybe<Scalars['String']>
  slackUserId?: Maybe<Scalars['String']>
  slackUsername?: Maybe<Scalars['String']>
}

export type CreditData = {
  __typename?: 'CreditData'
  applicationFicoScore?: Maybe<Scalars['Int']>
  borrowersCreditReports?: Maybe<Array<Maybe<CreditReportSchema>>>
}

export type CreditDataSchema = {
  __typename?: 'CreditDataSchema'
  creditReports?: Maybe<Array<Maybe<CreditReportDetailsSchema>>>
  ficoCreditScore?: Maybe<Scalars['Int']>
  ficoCreditScoreTimestamp?: Maybe<Scalars['String']>
  isFicoCreditScoreHard?: Maybe<Scalars['Boolean']>
  softCreditScore?: Maybe<Scalars['Int']>
}

export type CreditReportDetailsSchema = {
  __typename?: 'CreditReportDetailsSchema'
  borrowerClassification?: Maybe<Scalars['String']>
  identifier?: Maybe<Scalars['Float']>
  prequalEquifax?: Maybe<Scalars['Int']>
  prequalExperian?: Maybe<Scalars['Int']>
  prequalIdentifier?: Maybe<Scalars['Float']>
  prequalTransunion?: Maybe<Scalars['Int']>
  provider?: Maybe<Scalars['String']>
  requestType?: Maybe<Scalars['String']>
  score?: Maybe<Scalars['Int']>
  scoreEquifax?: Maybe<Scalars['Int']>
  scoreExperian?: Maybe<Scalars['Int']>
  scoreTransunion?: Maybe<Scalars['Int']>
}

export type CreditReportSchema = {
  __typename?: 'CreditReportSchema'
  borrowerClassification?: Maybe<Scalars['String']>
  scoreEquifax?: Maybe<Scalars['Int']>
  scoreExperian?: Maybe<Scalars['Int']>
  scoreSoft?: Maybe<Scalars['Int']>
  scoreTransunion?: Maybe<Scalars['Int']>
}

export type CycleTimes = {
  __typename?: 'CycleTimes'
  closing?: Maybe<Scalars['String']>
  ctcDate?: Maybe<Scalars['String']>
  disclosure?: Maybe<Scalars['String']>
}

export type DtiCalculationsSchema = {
  __typename?: 'DTICalculationsSchema'
  allLiabilityExpenses?: Maybe<Scalars['Float']>
  includedLiabilityIds?: Maybe<Array<Maybe<Scalars['Int']>>>
  linkedLiabilityExpenses?: Maybe<Scalars['Float']>
  netDTIRentalIncome?: Maybe<Scalars['Float']>
}

export type DateRange = {
  from?: Maybe<Scalars['String']>
  to?: Maybe<Scalars['String']>
}

export type DateRangeInput = {
  from?: Maybe<Scalars['String']>
  to?: Maybe<Scalars['String']>
}

export type DebtToIncomeBreakdown = {
  __typename?: 'DebtToIncomeBreakdown'
  debtToIncome?: Maybe<Scalars['Float']>
  totalMonthlyDebts?: Maybe<Scalars['Float']>
  totalMonthlyIncome?: Maybe<Scalars['Float']>
}

export type DemographicsAnswers = {
  americanIndianTribeDesc?: Maybe<Scalars['String']>
  asianOtherSubtypeDesc?: Maybe<Scalars['String']>
  asianSubtype?: Maybe<Array<Maybe<Scalars['String']>>>
  ethnicity?: Maybe<Array<Maybe<Scalars['String']>>>
  hispanicOtherSubtypeDesc?: Maybe<Scalars['String']>
  hispanicSubtype?: Maybe<Array<Maybe<Scalars['String']>>>
  id?: Maybe<Scalars['Int']>
  language?: Maybe<Array<Maybe<Scalars['String']>>>
  languageOtherDesc?: Maybe<Scalars['String']>
  pacificIslanderOtherSubtypeDesc?: Maybe<Scalars['String']>
  pacificIslanderSubtype?: Maybe<Array<Maybe<Scalars['String']>>>
  race?: Maybe<Array<Maybe<Scalars['String']>>>
  sex?: Maybe<Array<Maybe<Scalars['String']>>>
}

export type DemographicsOutput = {
  __typename?: 'DemographicsOutput'
  americanIndianTribeDesc?: Maybe<Scalars['String']>
  asianOtherSubtypeDesc?: Maybe<Scalars['String']>
  asianSubtype?: Maybe<Array<Maybe<Scalars['String']>>>
  ethnicity?: Maybe<Array<Maybe<Scalars['String']>>>
  hispanicOtherSubtypeDesc?: Maybe<Scalars['String']>
  hispanicSubtype?: Maybe<Array<Maybe<Scalars['String']>>>
  id?: Maybe<Scalars['Int']>
  language?: Maybe<Array<Maybe<Scalars['String']>>>
  languageOtherDesc?: Maybe<Scalars['String']>
  pacificIslanderOtherSubtypeDesc?: Maybe<Scalars['String']>
  pacificIslanderSubtype?: Maybe<Array<Maybe<Scalars['String']>>>
  race?: Maybe<Array<Maybe<Scalars['String']>>>
  sex?: Maybe<Array<Maybe<Scalars['String']>>>
}

export type DemographicsRevisionSchema = {
  __typename?: 'DemographicsRevisionSchema'
  americanIndianTribeDesc?: Maybe<Scalars['String']>
  asianOtherSubtypeDesc?: Maybe<Scalars['String']>
  asianSubtype?: Maybe<Array<Maybe<Scalars['String']>>>
  createdAt?: Maybe<Scalars['String']>
  ethnicity?: Maybe<Array<Maybe<Scalars['String']>>>
  hispanicOtherSubtypeDesc?: Maybe<Scalars['String']>
  hispanicSubtype?: Maybe<Array<Maybe<Scalars['String']>>>
  id?: Maybe<Scalars['Int']>
  language?: Maybe<Array<Maybe<Scalars['String']>>>
  languageOtherDesc?: Maybe<Scalars['String']>
  pacificIslanderOtherSubtypeDesc?: Maybe<Scalars['String']>
  pacificIslanderSubtype?: Maybe<Array<Maybe<Scalars['String']>>>
  race?: Maybe<Array<Maybe<Scalars['String']>>>
  sex?: Maybe<Array<Maybe<Scalars['String']>>>
}

export type DependentAge = {
  dependentAge?: Maybe<Scalars['Float']>
}

export type DependentAgeOutput = {
  __typename?: 'DependentAgeOutput'
  dependentAge?: Maybe<Scalars['Int']>
}

export type DirectMailerAnswers = {
  __typename?: 'DirectMailerAnswers'
  city?: Maybe<Scalars['String']>
  firstName?: Maybe<Scalars['String']>
  lastName?: Maybe<Scalars['String']>
  state?: Maybe<Scalars['String']>
  street?: Maybe<Scalars['String']>
  zipCode?: Maybe<Scalars['String']>
}

export type DisclosureInformation = {
  disclosureText: Scalars['String']
  disclosureType: DisclosureType
}

export type DisclosureTrackingRecipientSchema = {
  __typename?: 'DisclosureTrackingRecipientSchema'
  completedAt?: Maybe<Scalars['String']>
  createdAt?: Maybe<Scalars['String']>
  deliveredAt?: Maybe<Scalars['String']>
  deliveryFailedAt?: Maybe<Scalars['String']>
  deliveryFailedReason?: Maybe<Scalars['String']>
  disclosureTrackingId?: Maybe<Scalars['Int']>
  email?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['Int']>
  ipAddress?: Maybe<Scalars['String']>
  recipientType?: Maybe<Scalars['String']>
  sentAt?: Maybe<Scalars['String']>
}

export type DisclosureTrackingSchema = {
  __typename?: 'DisclosureTrackingSchema'
  applicationId?: Maybe<Scalars['Int']>
  classification?: Maybe<Scalars['String']>
  createdAt?: Maybe<Scalars['String']>
  disclosureType?: Maybe<Scalars['String']>
  encompassId?: Maybe<Scalars['String']>
  envelopeCompletedAt?: Maybe<Scalars['String']>
  envelopeDeliveredAt?: Maybe<Scalars['String']>
  envelopeId?: Maybe<Scalars['String']>
  externalEventId?: Maybe<Scalars['Int']>
  id?: Maybe<Scalars['Int']>
  recipients?: Maybe<Array<Maybe<DisclosureTrackingRecipientSchema>>>
}

export enum DisclosureType {
  AffiliatedBusinessArrangement = 'AffiliatedBusinessArrangement',
  BankrateProAgentSharingConsent = 'BankrateProAgentSharingConsent',
  CreditAuthorization = 'CreditAuthorization',
  ElectronicConsent = 'ElectronicConsent',
  MigrationConsent = 'MigrationConsent',
  PrivacyPolicy = 'PrivacyPolicy',
  SoftCreditConsent = 'SoftCreditConsent',
  Tcpa = 'TCPA',
  TermsOfUse = 'TermsOfUse',
}

export type DocumentExtractSchema = {
  __typename?: 'DocumentExtractSchema'
  applicationGuid?: Maybe<Scalars['String']>
  applicationId?: Maybe<Scalars['Int']>
  createdAt?: Maybe<Scalars['String']>
  extractedData?: Maybe<Scalars['JSON']>
  id: Scalars['Int']
  type?: Maybe<Scalars['String']>
  updatedAt?: Maybe<Scalars['String']>
  uploadGuid?: Maybe<Scalars['String']>
}

export type DocumentRequestInput = {
  description?: Maybe<Scalars['String']>
  title?: Maybe<Scalars['String']>
  uuid?: Maybe<Scalars['String']>
}

export type DocumentRequestNote = {
  __typename?: 'DocumentRequestNote'
  author?: Maybe<DocumentRequestNoteAuthor>
  createdAt?: Maybe<Scalars['String']>
  documentRequestId?: Maybe<Scalars['Int']>
  text?: Maybe<Scalars['String']>
  uuid?: Maybe<Scalars['String']>
}

export type DocumentRequestNoteAuthor = {
  __typename?: 'DocumentRequestNoteAuthor'
  email?: Maybe<Scalars['String']>
  headshotLink?: Maybe<Scalars['String']>
  name?: Maybe<Scalars['String']>
  phone?: Maybe<Scalars['String']>
}

export type DocumentRequestNoteInput = {
  documentRequestUuid: Scalars['String']
  retoolId?: Maybe<Scalars['Int']>
  text?: Maybe<Scalars['String']>
}

export type DocumentRequestSchema = {
  __typename?: 'DocumentRequestSchema'
  attachments?: Maybe<Array<Maybe<AttachmentSchema>>>
  createdAt?: Maybe<Scalars['String']>
  description?: Maybe<Scalars['String']>
  encompassId?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['Int']>
  latestNote?: Maybe<DocumentRequestNote>
  status?: Maybe<Scalars['String']>
  title?: Maybe<Scalars['String']>
  updatedAt?: Maybe<Scalars['String']>
  uuid?: Maybe<Scalars['String']>
}

export enum EmploymentStatus {
  Employed = 'Employed',
  Military = 'Military',
  NotEmployed = 'NotEmployed',
  Other = 'Other',
  SelfEmployed = 'SelfEmployed',
}

export type EncompassLoanImportSchema = {
  __typename?: 'EncompassLoanImportSchema'
  actionDate?: Maybe<Scalars['String']>
  actionTaken?: Maybe<Scalars['String']>
  applicationId?: Maybe<Scalars['Int']>
  appraisalOrderedDate?: Maybe<Scalars['String']>
  appraisalReceivedDate?: Maybe<Scalars['String']>
  ausRunDate?: Maybe<Scalars['String']>
  borrowerEsignDate?: Maybe<Scalars['String']>
  clearToCloseOn?: Maybe<Scalars['String']>
  closingDateConfirmed?: Maybe<Scalars['String']>
  conditionallyApprovedOn?: Maybe<Scalars['String']>
  contractContingencyDate?: Maybe<Scalars['String']>
  contractSigned?: Maybe<Scalars['String']>
  copaSubmitDate?: Maybe<Scalars['String']>
  counterOfferAccepted?: Maybe<Scalars['String']>
  createdAt?: Maybe<Scalars['String']>
  creditedUnderwriter?: Maybe<Scalars['String']>
  deletedAt?: Maybe<Scalars['String']>
  deniedOn?: Maybe<Scalars['String']>
  docDateConfirmed?: Maybe<Scalars['String']>
  docSignDateConfirmed?: Maybe<Scalars['String']>
  dti?: Maybe<Scalars['Float']>
  edisclosureSignedOn?: Maybe<Scalars['String']>
  estClosingDate?: Maybe<Scalars['String']>
  finalCdSentDate?: Maybe<Scalars['String']>
  fundedOn?: Maybe<Scalars['String']>
  gainLossPercentage?: Maybe<Scalars['Float']>
  gainLossTotal?: Maybe<Scalars['Int']>
  hmdaActionTaken?: Maybe<Scalars['String']>
  id: Scalars['Int']
  lenderLoanId?: Maybe<Scalars['String']>
  lenderName?: Maybe<Scalars['String']>
  loEsignDate?: Maybe<Scalars['String']>
  loanAmount?: Maybe<Scalars['Int']>
  loanProduct?: Maybe<Scalars['String']>
  lockExpirationDate?: Maybe<Scalars['String']>
  lockPeriod?: Maybe<Scalars['String']>
  lockedOn?: Maybe<Scalars['String']>
  ltv?: Maybe<Scalars['Float']>
  moveToFundDate?: Maybe<Scalars['String']>
  orderOutsFlood?: Maybe<Scalars['String']>
  orderOutsFraud?: Maybe<Scalars['String']>
  orderOutsTitle?: Maybe<Scalars['String']>
  orderOutsWvoe?: Maybe<Scalars['String']>
  originalLockPeriod?: Maybe<Scalars['Int']>
  preapprovalCreditOnly?: Maybe<Scalars['String']>
  preapprovalDate?: Maybe<Scalars['String']>
  preapprovalPortfolioLoan?: Maybe<Scalars['String']>
  preapprovalUwOnly?: Maybe<Scalars['String']>
  propertyInspectionWaiverEligible?: Maybe<Scalars['Boolean']>
  propertyType?: Maybe<Scalars['String']>
  rate?: Maybe<Scalars['Float']>
  salesContractDate?: Maybe<Scalars['String']>
  submitToCtcDate?: Maybe<Scalars['String']>
  submittedToUnderwritingOn?: Maybe<Scalars['String']>
  suspendedOn?: Maybe<Scalars['String']>
  updatedAt?: Maybe<Scalars['String']>
  vepaSubmitDate?: Maybe<Scalars['String']>
  withdrawnOn?: Maybe<Scalars['String']>
}

export type EnzoResponse = {
  __typename?: 'EnzoResponse'
  activeLeads?: Maybe<Array<Maybe<ActiveLeadSchema>>>
  scheduledLeads?: Maybe<Array<Maybe<ActiveLeadSchema>>>
  unscheduledLeads?: Maybe<Array<Maybe<ActiveLeadSchema>>>
}

export type ErrorDataSchema = {
  __typename?: 'ErrorDataSchema'
  eventName?: Maybe<Scalars['String']>
  exists?: Maybe<Scalars['Boolean']>
  sensitive?: Maybe<Scalars['JSON']>
}

export type ErrorPage = {
  __typename?: 'ErrorPage'
  body?: Maybe<Scalars['String']>
  component: Scalars['String']
  name: Scalars['String']
  primaryCtaLink?: Maybe<StoryblokSchemaLink>
  primaryCtaTitle?: Maybe<Scalars['String']>
  title?: Maybe<Scalars['String']>
}

export enum EscrowOptions {
  InsuranceOnly = 'InsuranceOnly',
  TaxesAndInsurance = 'TaxesAndInsurance',
  TaxesOnly = 'TaxesOnly',
}

export type EventTag = {
  key: Scalars['String']
  value?: Maybe<Scalars['String']>
}

export enum ExistingRateType {
  Adjustable = 'Adjustable',
  Fixed = 'Fixed',
}

export type ExistingUserEmailDetails = {
  __typename?: 'ExistingUserEmailDetails'
  createdAt?: Maybe<Scalars['String']>
  email?: Maybe<Scalars['String']>
  firstName?: Maybe<Scalars['String']>
  lastName?: Maybe<Scalars['String']>
  phoneNumber?: Maybe<Scalars['String']>
  userId?: Maybe<Scalars['Int']>
}

export enum ExperienceType {
  Baseline = 'Baseline',
  Ntl = 'NTL',
}

export type Fees = {
  __typename?: 'Fees'
  amount?: Maybe<Scalars['Float']>
  description?: Maybe<Scalars['String']>
  lineNumber?: Maybe<Scalars['String']>
}

export type FeesInput = {
  amount?: Maybe<Scalars['Float']>
  description?: Maybe<Scalars['String']>
  lineNumber?: Maybe<Scalars['String']>
}

export type FillCoBorrowerResponse = {
  __typename?: 'FillCoBorrowerResponse'
  coBorrowerId: Scalars['Int']
}

export type FillReoResponse = {
  __typename?: 'FillReoResponse'
  realEstateOwnedIds?: Maybe<Array<Maybe<Scalars['Int']>>>
}

export type FilterOptions = {
  iceboxNotes?: Maybe<Scalars['String']>
  reminderType?: Maybe<Scalars['String']>
  statuses?: Maybe<Array<Maybe<Scalars['String']>>>
}

export type GeneratedDocumentsPipeline = {
  __typename?: 'GeneratedDocumentsPipeline'
  applicationId?: Maybe<Scalars['Int']>
  applicationUuid?: Maybe<Scalars['String']>
  createdAt?: Maybe<Scalars['String']>
  createdBy?: Maybe<Scalars['Int']>
  documentClassification?: Maybe<Scalars['String']>
  documentId?: Maybe<Scalars['Int']>
  documentUuid?: Maybe<Scalars['String']>
  loanOfficerName?: Maybe<Scalars['String']>
}

export type GeneratedDocumentsPipelineRequest = {
  applicationId?: Maybe<Scalars['Int']>
  createdAtRange?: Maybe<DateRange>
  createdBy?: Maybe<Scalars['Int']>
  documentClassification?: Maybe<Scalars['String']>
  pagination?: Maybe<Pagination>
  sortBy?: Maybe<Scalars['String']>
  sortDirection?: Maybe<Scalars['String']>
}

export type GeneratedDocumentsPipelineResponse = {
  __typename?: 'GeneratedDocumentsPipelineResponse'
  pagination?: Maybe<PaginationResponse>
  records?: Maybe<Array<Maybe<GeneratedDocumentsPipeline>>>
}

export type GetAnswersResult = {
  __typename?: 'GetAnswersResult'
  applicationAnswers?: Maybe<ApplicationAnswersOutput>
  bankrateAnswers?: Maybe<BankrateAnswers>
  directMailerAnswers?: Maybe<DirectMailerAnswers>
}

export enum GifterRelationship {
  CommunityNonProfit = 'CommunityNonProfit',
  Employer = 'Employer',
  FederalAgency = 'FederalAgency',
  Lender = 'Lender',
  LocalAgency = 'LocalAgency',
  Other = 'Other',
  Parent = 'Parent',
  Relative = 'Relative',
  ReligiousNonProfit = 'ReligiousNonProfit',
  StateAgency = 'StateAgency',
  UnmarriedPartner = 'UnmarriedPartner',
  UnrelatedFriend = 'UnrelatedFriend',
}

export type GoogleAddressComponent = {
  __typename?: 'GoogleAddressComponent'
  componentName?: Maybe<GoogleAddressComponentName>
  componentType?: Maybe<Scalars['String']>
  confirmationLevel?: Maybe<Scalars['String']>
  inferred?: Maybe<Scalars['Boolean']>
  replaced?: Maybe<Scalars['Boolean']>
  spellCorrected?: Maybe<Scalars['Boolean']>
  unexpected?: Maybe<Scalars['Boolean']>
}

export type GoogleAddressComponentName = {
  __typename?: 'GoogleAddressComponentName'
  languageCode?: Maybe<Scalars['String']>
  text?: Maybe<Scalars['String']>
}

export enum GoogleApiStatusCode {
  InvalidRequest = 'INVALID_REQUEST',
  Ok = 'OK',
  ZeroResults = 'ZERO_RESULTS',
}

export type GoogleAutoCompletePrediction = {
  __typename?: 'GoogleAutoCompletePrediction'
  description?: Maybe<Scalars['String']>
}

export type GoogleAutoCompleteResult = {
  __typename?: 'GoogleAutoCompleteResult'
  predictions?: Maybe<Array<Maybe<GoogleAutoCompletePrediction>>>
  status?: Maybe<GoogleApiStatusCode>
}

export type GoogleGeolocationAddressComponent = {
  __typename?: 'GoogleGeolocationAddressComponent'
  long_name?: Maybe<Scalars['String']>
  short_name?: Maybe<Scalars['String']>
  types?: Maybe<Array<Maybe<Scalars['String']>>>
}

export type GoogleGeolocationGeometryComponent = {
  __typename?: 'GoogleGeolocationGeometryComponent'
  location?: Maybe<GoogleGeolocationLocationComponent>
}

export type GoogleGeolocationLocationComponent = {
  __typename?: 'GoogleGeolocationLocationComponent'
  lat?: Maybe<Scalars['Float']>
  lng?: Maybe<Scalars['Float']>
}

export type GoogleGeolocationMatch = {
  __typename?: 'GoogleGeolocationMatch'
  address_components?: Maybe<Array<Maybe<GoogleGeolocationAddressComponent>>>
  geometry?: Maybe<GoogleGeolocationGeometryComponent>
}

export type GoogleGeolocationResult = {
  __typename?: 'GoogleGeolocationResult'
  results?: Maybe<Array<Maybe<GoogleGeolocationMatch>>>
  status?: Maybe<GoogleApiStatusCode>
}

export type GoogleTimeZoneResult = {
  __typename?: 'GoogleTimeZoneResult'
  dstOffset?: Maybe<Scalars['Int']>
  rawOffset?: Maybe<Scalars['Int']>
  status?: Maybe<Scalars['String']>
  timeZoneId?: Maybe<Scalars['String']>
  timeZoneName?: Maybe<Scalars['String']>
}

export type GqlAnswerSet = {
  __typename?: 'GqlAnswerSet'
  answerSetSlug?: Maybe<Scalars['String']>
  answers?: Maybe<Scalars['String']>
  description?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['String']>
  product?: Maybe<Scalars['String']>
  questionnaireSlug?: Maybe<Scalars['String']>
}

export type GqlAnswerSetInput = {
  answerSetSlug: Scalars['String']
  answers: Scalars['String']
  description?: Maybe<Scalars['String']>
  id: Scalars['String']
  product: Scalars['String']
  questionnaireSlug: Scalars['String']
}

export type IncomeOption = {
  __typename?: 'IncomeOption'
  name: Scalars['String']
}

export type IncomeRevisionSchema = {
  __typename?: 'IncomeRevisionSchema'
  bonus?: Maybe<Scalars['Float']>
  commissions?: Maybe<Scalars['Float']>
  companyOwnership?: Maybe<Scalars['Boolean']>
  createdAt?: Maybe<Scalars['String']>
  currentJob?: Maybe<Scalars['Boolean']>
  employedByParty?: Maybe<Scalars['Boolean']>
  employerAddress?: Maybe<Scalars['String']>
  employerCity?: Maybe<Scalars['String']>
  employerIndustry?: Maybe<Scalars['String']>
  employerName?: Maybe<Scalars['String']>
  employerPhone?: Maybe<Scalars['String']>
  employerState?: Maybe<Scalars['String']>
  employerZipcode?: Maybe<Scalars['String']>
  endDate?: Maybe<Scalars['String']>
  grossAnnualIncomeAmount?: Maybe<Scalars['Float']>
  id?: Maybe<Scalars['Int']>
  incomeType?: Maybe<Scalars['String']>
  jobTitle?: Maybe<Scalars['String']>
  nonTaxable?: Maybe<Scalars['Boolean']>
  otherAnnualIncomeAmount?: Maybe<Scalars['Float']>
  otherIncomeSource?: Maybe<Scalars['String']>
  overtime?: Maybe<Scalars['Float']>
  selfEmploymentBusinessType?: Maybe<SelfEmploymentBusinessType>
  startDate?: Maybe<Scalars['String']>
  yearsInThisProfession?: Maybe<Scalars['Int']>
}

export type IncomeSource = {
  bonus?: Maybe<Scalars['Float']>
  commissions?: Maybe<Scalars['Float']>
  companyOwnership?: Maybe<Scalars['Boolean']>
  currentJob?: Maybe<Scalars['Boolean']>
  employedByParty?: Maybe<Scalars['Boolean']>
  employerAddress?: Maybe<Scalars['String']>
  employerCity?: Maybe<Scalars['String']>
  employerIndustry?: Maybe<Scalars['String']>
  employerName?: Maybe<Scalars['String']>
  employerPhone?: Maybe<Scalars['String']>
  employerState?: Maybe<Scalars['String']>
  employerZipcode?: Maybe<Scalars['String']>
  endDate?: Maybe<Scalars['String']>
  grossAnnualIncomeAmount?: Maybe<Scalars['Float']>
  id?: Maybe<Scalars['Int']>
  incomeType?: Maybe<Scalars['String']>
  jobTitle?: Maybe<Scalars['String']>
  nonTaxable?: Maybe<Scalars['Boolean']>
  otherAnnualIncomeAmount?: Maybe<Scalars['Float']>
  otherIncomeSource?: Maybe<Scalars['String']>
  overtime?: Maybe<Scalars['Float']>
  selfEmploymentBusinessType?: Maybe<SelfEmploymentBusinessType>
  startDate?: Maybe<Scalars['String']>
  yearsInThisProfession?: Maybe<Scalars['Int']>
}

export type IncomeSourceOutput = {
  __typename?: 'IncomeSourceOutput'
  companyOwnership?: Maybe<Scalars['String']>
  currentJob?: Maybe<Scalars['Boolean']>
  employedByParty?: Maybe<Scalars['String']>
  employerAddress?: Maybe<Scalars['String']>
  employerCity?: Maybe<Scalars['String']>
  employerIndustry?: Maybe<Scalars['String']>
  employerName?: Maybe<Scalars['String']>
  employerPhone?: Maybe<Scalars['String']>
  employerState?: Maybe<Scalars['String']>
  employerZipcode?: Maybe<Scalars['String']>
  endDate?: Maybe<Scalars['String']>
  grossAnnualIncomeAmount?: Maybe<Scalars['Float']>
  id?: Maybe<Scalars['Int']>
  incomeType?: Maybe<Scalars['String']>
  jobTitle?: Maybe<Scalars['String']>
  otherAnnualIncomeAmount?: Maybe<Scalars['Float']>
  otherIncomeSource?: Maybe<Scalars['String']>
  startDate?: Maybe<Scalars['String']>
  yearsInThisProfession?: Maybe<Scalars['Int']>
}

export type InputFees = {
  amount?: Maybe<Scalars['Float']>
  description?: Maybe<Scalars['String']>
}

export type IntegrationDataResponse = {
  __typename?: 'IntegrationDataResponse'
  ausDocuments?: Maybe<Array<Maybe<DocumentRequestSchema>>>
  extractData?: Maybe<Array<Maybe<DocumentExtractSchema>>>
  rate?: Maybe<LockedRateSchema>
  revision?: Maybe<ApplicationRevisionDetailsSchema>
  syncStatuses?: Maybe<SyncStatuses>
}

export type LatestDonePricingScenario = {
  __typename?: 'LatestDonePricingScenario'
  lockedRate?: Maybe<Rate>
  pricingScenario?: Maybe<PricingScenario>
}

export type LeadData = {
  __typename?: 'LeadData'
  annualIncomeAmount?: Maybe<Scalars['Float']>
  avmPropertyEstimatedValue?: Maybe<Scalars['Int']>
  besmarteeLoanId?: Maybe<Scalars['String']>
  creditData?: Maybe<CreditData>
  creditReportIdentifier?: Maybe<Scalars['String']>
  creditScore?: Maybe<Scalars['Int']>
  debtToIncome?: Maybe<Scalars['Float']>
  escrow?: Maybe<Scalars['Boolean']>
  experienceType?: Maybe<Scalars['String']>
  isRollInEscrow?: Maybe<Scalars['Boolean']>
  loanAmount?: Maybe<Scalars['Float']>
  loanToValue?: Maybe<Scalars['Float']>
  lockedRate?: Maybe<LeadRate>
  notes?: Maybe<Scalars['String']>
  propertyDownPayment?: Maybe<Scalars['Float']>
  propertyPurchasePrice?: Maybe<Scalars['Float']>
  propertyValue?: Maybe<Scalars['Float']>
  refiReason?: Maybe<Scalars['String']>
  rollInFees?: Maybe<Scalars['Boolean']>
  selectedRate?: Maybe<LeadRate>
  timeInHome?: Maybe<Scalars['String']>
  uuid?: Maybe<Scalars['String']>
}

export type LeadFilters = {
  endDate?: Maybe<Scalars['String']>
  loId?: Maybe<Scalars['Int']>
  loanPurpose?: Maybe<Scalars['String']>
  managerId?: Maybe<Scalars['Int']>
  startDate?: Maybe<Scalars['String']>
}

export type LeadInformation = {
  apt?: Maybe<Scalars['String']>
  buyBeforeYouSellAmount?: Maybe<Scalars['Float']>
  buyBeforeYouSellApproved?: Maybe<Scalars['Boolean']>
  cashOut?: Maybe<Scalars['Float']>
  city?: Maybe<Scalars['String']>
  closingDate?: Maybe<Scalars['String']>
  creditScore?: Maybe<Scalars['Int']>
  declarationFHALoan?: Maybe<Scalars['Boolean']>
  declarationMilitary?: Maybe<Scalars['Boolean']>
  declarationVALoan?: Maybe<Scalars['Boolean']>
  desiredRateType?: Maybe<Scalars['String']>
  email: Scalars['String']
  estimatedValue?: Maybe<Scalars['Float']>
  firstName?: Maybe<Scalars['String']>
  landingUrl?: Maybe<Scalars['String']>
  lastName?: Maybe<Scalars['String']>
  leadSource: Scalars['String']
  leadType?: Maybe<LeadTypes>
  loanOfficerId?: Maybe<Scalars['Int']>
  loanPurpose?: Maybe<LoanPurposes>
  phone: Scalars['String']
  phonePin?: Maybe<Scalars['String']>
  propertyDownPayment?: Maybe<Scalars['Float']>
  propertyPurchasePrice?: Maybe<Scalars['Float']>
  propertyType?: Maybe<PropertyTypes>
  propertyUse?: Maybe<PropertyUses>
  referrerUrl?: Maybe<Scalars['String']>
  remainingBalance?: Maybe<Scalars['Float']>
  sourceLeadId: Scalars['String']
  state?: Maybe<Scalars['String']>
  street?: Maybe<Scalars['String']>
  zipCode?: Maybe<Scalars['String']>
}

export type LeadPipeline = {
  __typename?: 'LeadPipeline'
  applicationCreatedAt?: Maybe<Scalars['String']>
  applicationId?: Maybe<Scalars['Int']>
  closingDate?: Maybe<Scalars['String']>
  connectedCallsCount?: Maybe<Scalars['Int']>
  daysToClose?: Maybe<Scalars['Int']>
  email?: Maybe<Scalars['String']>
  experienceType?: Maybe<ExperienceType>
  fullName?: Maybe<Scalars['String']>
  iceboxNotes?: Maybe<Scalars['String']>
  isReturningBorrower?: Maybe<Scalars['Boolean']>
  lastContactedAt?: Maybe<Scalars['String']>
  lastContactedType?: Maybe<Scalars['String']>
  lastRingcentralContactedAt?: Maybe<Scalars['String']>
  lastRingcentralContactedType?: Maybe<Scalars['String']>
  lenderLoanId?: Maybe<Scalars['String']>
  loanOfficerAssignedAt?: Maybe<Scalars['String']>
  loanOfficerName?: Maybe<Scalars['String']>
  loanPurpose?: Maybe<LoanPurpose>
  outBoundCallsCount?: Maybe<Scalars['Int']>
  outBoundSmsCount?: Maybe<Scalars['Int']>
  phone?: Maybe<Scalars['String']>
  relatedApplications?: Maybe<Array<Maybe<RelatedApplications>>>
  reminder?: Maybe<ApplicationReminderSchema>
  secondPhone?: Maybe<Scalars['String']>
  source?: Maybe<Scalars['String']>
  state?: Maybe<Scalars['String']>
  status?: Maybe<Scalars['String']>
  timeToFirstDialMinutes?: Maybe<Scalars['Int']>
  tridDaysLeft?: Maybe<Scalars['Int']>
}

export type LeadPipelineGroup = {
  __typename?: 'LeadPipelineGroup'
  count?: Maybe<Scalars['Int']>
  name?: Maybe<Scalars['String']>
}

export type LeadPipelineGroups = {
  __typename?: 'LeadPipelineGroups'
  reminderType?: Maybe<Array<Maybe<LeadPipelineGroup>>>
}

export type LeadPipelineRequest = {
  allLeads?: Maybe<Scalars['Boolean']>
  applicationId?: Maybe<Scalars['Int']>
  createdFrom?: Maybe<Scalars['String']>
  createdTo?: Maybe<Scalars['String']>
  email?: Maybe<Scalars['String']>
  fetchIcebox?: Maybe<Scalars['Boolean']>
  filterOut?: Maybe<FilterOptions>
  fullName?: Maybe<Scalars['String']>
  lenderLoanId?: Maybe<Scalars['String']>
  loanOfficerId?: Maybe<Scalars['Int']>
  loanPurpose?: Maybe<LoanPurpose>
  managerId?: Maybe<Scalars['Int']>
  pagination?: Maybe<Pagination>
  phone?: Maybe<Scalars['String']>
  sortByField?: Maybe<Scalars['String']>
  sortDirection?: Maybe<Scalars['String']>
  source?: Maybe<Scalars['String']>
  state?: Maybe<Scalars['String']>
  status?: Maybe<Scalars['String']>
}

export type LeadPipelineResponse = {
  __typename?: 'LeadPipelineResponse'
  groups?: Maybe<LeadPipelineGroups>
  leadPipeline?: Maybe<Array<Maybe<LeadPipeline>>>
  pagination?: Maybe<PaginationResponse>
}

export type LeadRate = {
  __typename?: 'LeadRate'
  rate?: Maybe<Scalars['Float']>
  term?: Maybe<Scalars['Int']>
}

export type LeadResponse = {
  __typename?: 'LeadResponse'
  applicationGuid: Scalars['String']
  applicationId: Scalars['Int']
  success: Scalars['Boolean']
}

export enum LeadTypes {
  Branded = 'Branded',
  Internet = 'Internet',
  Live = 'Live',
}

export type LenderBaseFee = {
  __typename?: 'LenderBaseFee'
  amount?: Maybe<Scalars['Float']>
  description?: Maybe<Scalars['String']>
  feeSetId?: Maybe<Scalars['Int']>
  feeType?: Maybe<Scalars['String']>
  hudLine?: Maybe<Scalars['Int']>
  id?: Maybe<Scalars['Int']>
  includeInApr?: Maybe<Scalars['Boolean']>
  userId?: Maybe<Scalars['Int']>
}

export enum Lenders {
  AmeriSave = 'AmeriSave',
  HomePoint = 'HomePoint',
  PennyMac = 'PennyMac',
  Quicken = 'Quicken',
  Sage = 'Sage',
  Uwm = 'UWM',
}

export type LiabilityAnswers = {
  id?: Maybe<Scalars['Int']>
  otherLiabilityAmount?: Maybe<Scalars['Float']>
  otherLiabilityType?: Maybe<Scalars['String']>
}

export type LiabilityOutput = {
  __typename?: 'LiabilityOutput'
  id?: Maybe<Scalars['Int']>
  otherLiabilityAmount?: Maybe<Scalars['Float']>
  otherLiabilityType?: Maybe<Scalars['String']>
}

export type LiabilityRevisionSchema = {
  __typename?: 'LiabilityRevisionSchema'
  accountNumber?: Maybe<Scalars['String']>
  apt?: Maybe<Scalars['String']>
  city?: Maybe<Scalars['String']>
  createdAt?: Maybe<Scalars['String']>
  creditLine?: Maybe<Scalars['Float']>
  creditLoanType?: Maybe<Scalars['String']>
  creditReportId?: Maybe<Scalars['Int']>
  creditor?: Maybe<Scalars['String']>
  currentHousingExpense?: Maybe<Scalars['Boolean']>
  deletedAt?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['Int']>
  includesEscrows?: Maybe<Scalars['Boolean']>
  liabilityType?: Maybe<Scalars['String']>
  monthlyExpense?: Maybe<Scalars['Float']>
  mortgageType?: Maybe<Scalars['String']>
  omit?: Maybe<Scalars['Boolean']>
  otherDescription?: Maybe<Scalars['String']>
  ownershipType?: Maybe<Scalars['String']>
  partOfPurchase?: Maybe<Scalars['Boolean']>
  remainingTermMonths?: Maybe<Scalars['Int']>
  reo?: Maybe<RealEstateOwnedSchema>
  reoId?: Maybe<Scalars['Int']>
  resubordinate?: Maybe<Scalars['Boolean']>
  state?: Maybe<Scalars['String']>
  street?: Maybe<Scalars['String']>
  toBePaidOff?: Maybe<Scalars['Boolean']>
  totalTermMonths?: Maybe<Scalars['Int']>
  unpaidBalance?: Maybe<Scalars['Float']>
  updatedAt?: Maybe<Scalars['String']>
  zip?: Maybe<Scalars['String']>
}

export type LiabilitySchema = {
  __typename?: 'LiabilitySchema'
  liabilitiesList?: Maybe<Array<Maybe<LiabilityRevisionSchema>>>
  totalMortgage?: Maybe<Scalars['Int']>
  totalNonMortgage?: Maybe<Scalars['Int']>
  totalStudentLoan?: Maybe<Scalars['Int']>
}

export enum LiabilityType {
  Alimony = 'Alimony',
  ChildSupport = 'ChildSupport',
  Childcare = 'Childcare',
  CollectionsJudgmentsAndLiens = 'CollectionsJudgmentsAndLiens',
  Heloc = 'HELOC',
  Installment = 'Installment',
  JobRelatedExpenses = 'JobRelatedExpenses',
  LeasePayment = 'LeasePayment',
  MortgageLoan = 'MortgageLoan',
  Open30DayChargeAccount = 'Open30DayChargeAccount',
  Revolving = 'Revolving',
  SeparateMaintenanceExpense = 'SeparateMaintenanceExpense',
  TaxLien = 'TaxLien',
  Taxes = 'Taxes',
}

export type LoanEstimateSectionsInput = {
  A: SectionInput
  B: SectionInput
  C: SectionInput
  D: SectionInput
  E: SectionInput
  F: SectionInput
  G: SectionInput
  H: SectionFeesOptionalInput
  I: SectionInput
  J: SectionInput
  Others: SectionInput
}

export type LoanMatchPlaygroundResponseRefi = {
  __typename?: 'LoanMatchPlaygroundResponseRefi'
  autoRolledInFeeRates?: Maybe<Array<Maybe<Rate>>>
  initialRates?: Maybe<Array<Maybe<Rate>>>
  maxPoints?: Maybe<Scalars['Int']>
  rate?: Maybe<Rate>
  ratesUnderPointThreshold?: Maybe<Array<Maybe<Rate>>>
  savings?: Maybe<LoanMatchSavings>
}

export type LoanMatchRequest = {
  creditScore: Scalars['Int']
  currentMonthlyPayment: Scalars['Float']
  currentRate: Scalars['Float']
  fromPlayground?: Maybe<Scalars['Boolean']>
  loanAmount: Scalars['Float']
  loanPurpose: LoanPurpose
  loanToValue: Scalars['Float']
  propertyType: PropertyType
  propertyUse: PropertyUse
  propertyValue?: Maybe<Scalars['Float']>
  remainingBalance?: Maybe<Scalars['Float']>
  remainingMonths: Scalars['Int']
  state?: Maybe<StateAbbreviation>
  veteran?: Maybe<Veteran>
  zipCode: Scalars['String']
}

export type LoanMatchResponse = {
  __typename?: 'LoanMatchResponse'
  rate?: Maybe<Rate>
  savings?: Maybe<LoanMatchSavings>
}

export type LoanMatchSavings = {
  __typename?: 'LoanMatchSavings'
  costSavingsOverTimeAmount?: Maybe<Scalars['Float']>
  costSavingsYears?: Maybe<Scalars['Int']>
  endingLtv?: Maybe<Scalars['Float']>
  monthlyPaymentSavingsAmount?: Maybe<Scalars['Float']>
  monthlyPaymentSavingsPercent?: Maybe<Scalars['Float']>
  rateSavingsPercent?: Maybe<Scalars['Float']>
  startingLtv?: Maybe<Scalars['Float']>
}

export type LoanOfficerAdminInfo = {
  __typename?: 'LoanOfficerAdminInfo'
  active?: Maybe<Scalars['Boolean']>
  configurationFields?: Maybe<ConfigurationFields>
  email?: Maybe<Scalars['String']>
  headshot?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['String']>
  is_manager?: Maybe<Scalars['Boolean']>
  name?: Maybe<Scalars['String']>
  nmls?: Maybe<Scalars['String']>
  phone?: Maybe<Scalars['String']>
  retoolId?: Maybe<Scalars['Int']>
}

export type LoanOfficerDisplayInfo = {
  __typename?: 'LoanOfficerDisplayInfo'
  calendly_link?: Maybe<Scalars['String']>
  email?: Maybe<Scalars['String']>
  headshot?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['String']>
  name?: Maybe<Scalars['String']>
  nmls?: Maybe<Scalars['String']>
  phone?: Maybe<Scalars['String']>
}

export type LoanOfficerDisplayInfoInput = {
  email?: Maybe<Scalars['String']>
  headshot?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['String']>
  name?: Maybe<Scalars['String']>
  nmls?: Maybe<Scalars['String']>
  phone?: Maybe<Scalars['String']>
}

export type LoanOfficerInput = {
  email?: Maybe<Scalars['String']>
  headshot?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['String']>
  name?: Maybe<Scalars['String']>
  nmls?: Maybe<Scalars['String']>
  phone?: Maybe<Scalars['String']>
}

export type LoanOfficerSmsAuthor = {
  __typename?: 'LoanOfficerSMSAuthor'
  isLO?: Maybe<Scalars['Boolean']>
  name?: Maybe<Scalars['String']>
  phone?: Maybe<Scalars['String']>
}

export type LoanOfficerSmsLog = {
  __typename?: 'LoanOfficerSMSLog'
  messages?: Maybe<Array<Maybe<LoanOfficerSmsMessage>>>
  name?: Maybe<Scalars['String']>
  phone?: Maybe<Scalars['String']>
}

export type LoanOfficerSmsLogResponse = {
  __typename?: 'LoanOfficerSMSLogResponse'
  borrower?: Maybe<LoanOfficerSmsLog>
  coBorrower?: Maybe<LoanOfficerSmsLog>
}

export type LoanOfficerSmsMessage = {
  __typename?: 'LoanOfficerSMSMessage'
  author?: Maybe<LoanOfficerSmsAuthor>
  createdAt?: Maybe<Scalars['String']>
  text?: Maybe<Scalars['String']>
}

export type LoanProgram = {
  __typename?: 'LoanProgram'
  adjustRateTerm?: Maybe<Scalars['Int']>
  class?: Maybe<ProductClass>
  code?: Maybe<Scalars['String']>
  family?: Maybe<Scalars['String']>
  fixRateTerm?: Maybe<Scalars['Int']>
  id?: Maybe<Scalars['Int']>
  initialCap?: Maybe<Scalars['Float']>
  interestOnlyMonths?: Maybe<Scalars['Int']>
  isMortgageInsurancePaidByLender?: Maybe<Scalars['Boolean']>
  lenderName?: Maybe<Scalars['String']>
  lifetimeCap?: Maybe<Scalars['Float']>
  lockOverrides?: Maybe<Array<Maybe<Scalars['Float']>>>
  margin?: Maybe<Scalars['Float']>
  name?: Maybe<Scalars['String']>
  periodicCap?: Maybe<Scalars['Float']>
  prePaymentPenalty?: Maybe<Scalars['Boolean']>
  rateSheetId?: Maybe<Scalars['Int']>
  shouldIncludeHighBalance?: Maybe<Scalars['Boolean']>
  term?: Maybe<Scalars['String']>
  type?: Maybe<Scalars['String']>
}

export enum LoanPurpose {
  Purchase = 'Purchase',
  Refinance = 'Refinance',
}

export enum LoanPurposes {
  Purchase = 'Purchase',
  Refinance = 'Refinance',
}

export type LoanSetupChecklist = {
  __typename?: 'LoanSetupChecklist'
  createdAt?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['Int']>
  items?: Maybe<Array<Maybe<LoanSetupChecklistItem>>>
}

export type LoanSetupChecklistItem = {
  __typename?: 'LoanSetupChecklistItem'
  checkedAt?: Maybe<Scalars['String']>
  checkedBy?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['Int']>
  name?: Maybe<Scalars['String']>
}

export type LoanSetupPipelineRequest = {
  applicationId?: Maybe<Scalars['Int']>
  lenderLoanId?: Maybe<Scalars['String']>
  pagination?: Maybe<PaginationInput>
  sort?: Maybe<SortInput>
}

export type LoanSetupPipelineResponse = {
  __typename?: 'LoanSetupPipelineResponse'
  pagination?: Maybe<PaginationResponse>
  pipeline?: Maybe<Array<Maybe<LoanSetupRecord>>>
}

export type LoanSetupRecord = {
  __typename?: 'LoanSetupRecord'
  applicationId?: Maybe<Scalars['Int']>
  borrowerName?: Maybe<Scalars['String']>
  checklist?: Maybe<LoanSetupChecklist>
  closingDate?: Maybe<Scalars['String']>
  dateLocked?: Maybe<Scalars['String']>
  daysToClose?: Maybe<Scalars['Int']>
  latestAttachmentUploadedAt?: Maybe<Scalars['String']>
  lenderLoanId?: Maybe<Scalars['String']>
  lenderName?: Maybe<Scalars['String']>
  loanOfficerName?: Maybe<Scalars['String']>
  loanPurpose?: Maybe<Scalars['String']>
  loanSetupAdmin?: Maybe<Scalars['String']>
  loanSetupAnalyst?: Maybe<Scalars['String']>
  lockFormId?: Maybe<Scalars['Int']>
  processorId?: Maybe<Scalars['Int']>
  processorName?: Maybe<Scalars['String']>
  trippedTRIDDate?: Maybe<Scalars['String']>
}

export enum LoanType {
  Purchase = 'Purchase',
  Refinance = 'Refinance',
}

export enum LoanTypeAssigned {
  Both = 'Both',
  Purchase = 'Purchase',
  Refinance = 'Refinance',
}

export type LockData = {
  __typename?: 'LockData'
  administrator?: Maybe<Scalars['String']>
  application?: Maybe<ApplicationSchema>
  applicationDemographics?: Maybe<ApplicationDemographics>
  applicationRevision?: Maybe<ApplicationRevisionSchema>
  coBorrowerDemographics?: Maybe<ApplicationDemographics>
  coBorrowerManualLiabilities?: Maybe<
    Array<Maybe<ApplicationLiabilitiesSchema>>
  >
  creditData?: Maybe<CreditData>
  dateLocked?: Maybe<Scalars['String']>
  isFloated?: Maybe<Scalars['Boolean']>
  loanOfficer?: Maybe<LoanOfficerDisplayInfo>
  lockDate?: Maybe<Scalars['String']>
  lockDeskNotes?: Maybe<Scalars['String']>
  lockFormId?: Maybe<Scalars['Int']>
  lockProgress?: Maybe<Scalars['String']>
  manualLiabilities?: Maybe<Array<Maybe<ApplicationLiabilitiesSchema>>>
  notes?: Maybe<Scalars['String']>
  pricingScenario?: Maybe<PricingScenario>
  processingQuestions?: Maybe<Scalars['String']>
  processingTeam?: Maybe<Scalars['String']>
  rate?: Maybe<Rate>
  realEstateOwned?: Maybe<Array<Maybe<RealEstateOwnedSchema>>>
  requestType?: Maybe<LockRequestType>
  tridDaysLeft?: Maybe<Scalars['Int']>
}

export type LockDeskPipeline = {
  __typename?: 'LockDeskPipeline'
  administrator?: Maybe<Scalars['String']>
  applicationId?: Maybe<Scalars['Int']>
  beSmarteeLoanId?: Maybe<Scalars['String']>
  dateLocked?: Maybe<Scalars['String']>
  disclosureSetupAdmin?: Maybe<Scalars['String']>
  experienceType?: Maybe<ExperienceType>
  firstName?: Maybe<Scalars['String']>
  firstTouched?: Maybe<Scalars['String']>
  isErrored?: Maybe<Scalars['Boolean']>
  isFloated?: Maybe<Scalars['Boolean']>
  isManual?: Maybe<Scalars['Boolean']>
  lastName?: Maybe<Scalars['String']>
  lenderLoanId?: Maybe<Scalars['String']>
  lenderName?: Maybe<Scalars['String']>
  loanOfficerName?: Maybe<Scalars['String']>
  loanPurpose?: Maybe<Scalars['String']>
  loanSetupAdmin?: Maybe<Scalars['String']>
  lockDate?: Maybe<Scalars['String']>
  lockDeskNotes?: Maybe<Scalars['String']>
  lockFormId?: Maybe<Scalars['Int']>
  lockProgress?: Maybe<Scalars['String']>
  notes?: Maybe<Scalars['String']>
  paidCompensation?: Maybe<Scalars['Float']>
  processingTeam?: Maybe<Scalars['String']>
  processorId?: Maybe<Scalars['Int']>
  processorName?: Maybe<Scalars['String']>
  propertyPurchasePrice?: Maybe<Scalars['Float']>
  requestType?: Maybe<LockRequestType>
  roles?: Maybe<Array<Maybe<Scalars['String']>>>
}

export type LockDeskPipelineRequest = {
  applicationId?: Maybe<Scalars['Int']>
  besmarteeLoanId?: Maybe<Scalars['String']>
  borrowerName?: Maybe<Scalars['String']>
  createdDateRange?: Maybe<DateRange>
  isFloated?: Maybe<Scalars['Boolean']>
  lenderName?: Maybe<Scalars['String']>
  lockProgress?: Maybe<Scalars['String']>
  lockedDateRange?: Maybe<DateRange>
  lockedTo?: Maybe<Scalars['String']>
  pagination?: Maybe<Pagination>
  processingQueuePipeline?: Maybe<Scalars['Boolean']>
  processorId?: Maybe<Scalars['Int']>
  requestType?: Maybe<Array<Maybe<LockRequestType>>>
}

export type LockDeskPipelineResponse = {
  __typename?: 'LockDeskPipelineResponse'
  lockDeskPipeline?: Maybe<Array<Maybe<LockDeskPipeline>>>
  pagination?: Maybe<PaginationResponse>
}

export enum LockRequestType {
  Cic = 'CIC',
  DoesNotQualify = 'DoesNotQualify',
  Float = 'Float',
  Lock = 'Lock',
  Wip = 'WIP',
}

export type LockedOrFloatedLoansNotESigned = {
  __typename?: 'LockedOrFloatedLoansNotESigned'
  applicationId: Scalars['Int']
  borrowerName?: Maybe<Scalars['String']>
  daysSinceTrippedTrid?: Maybe<Scalars['Int']>
  lenderLoanId?: Maybe<Scalars['String']>
  loanOfficerAssigned?: Maybe<Scalars['String']>
  lockedOnDate?: Maybe<Scalars['String']>
  trippedTridDate?: Maybe<Scalars['String']>
}

export type LockedOrFloatedLoansNotESignedRequest = {
  applicationId?: Maybe<Scalars['Int']>
  filterOutInvalidStatuses?: Maybe<Scalars['Boolean']>
  filterOutProcessorAssigned?: Maybe<Scalars['Boolean']>
  lenderLoanId?: Maybe<Scalars['String']>
  moreThan10DaysSinceTrippedTrid: Scalars['Boolean']
  pagination?: Maybe<PaginationInput>
}

export type LockedOrFloatedLoansNotESignedResponse = {
  __typename?: 'LockedOrFloatedLoansNotESignedResponse'
  lockedOrFloatedLoansNotESigned?: Maybe<
    Array<Maybe<LockedOrFloatedLoansNotESigned>>
  >
  pagination?: Maybe<PaginationResponse>
}

export type LockedRateSchema = {
  __typename?: 'LockedRateSchema'
  adjustments?: Maybe<Array<Maybe<Adjustment>>>
  adjustmentsTotal?: Maybe<Scalars['Float']>
  apr?: Maybe<Scalars['Float']>
  basePrice?: Maybe<Scalars['Float']>
  borrowerName?: Maybe<Scalars['String']>
  cashOut?: Maybe<Scalars['Int']>
  combinedLoanToValue?: Maybe<Scalars['Float']>
  costOfLoan?: Maybe<Scalars['Float']>
  costOfLoanYears?: Maybe<Scalars['Int']>
  createdAt?: Maybe<Scalars['String']>
  creditScore?: Maybe<Scalars['Int']>
  discountOrCreditAmount?: Maybe<Scalars['Float']>
  discountPoints?: Maybe<Scalars['Float']>
  discountPointsAsDollarAmount?: Maybe<Scalars['Float']>
  escrowFees?: Maybe<Scalars['Float']>
  family?: Maybe<Scalars['String']>
  fees?: Maybe<Array<Maybe<Fees>>>
  fewerUpfrontCosts?: Maybe<Scalars['Float']>
  finalPrice?: Maybe<Scalars['Float']>
  fundingFee?: Maybe<Scalars['Float']>
  isRollInEscrow?: Maybe<Scalars['Boolean']>
  lenderName?: Maybe<Scalars['String']>
  loanAmount?: Maybe<Scalars['Float']>
  loanEstimateSections?: Maybe<LoanEstimateSections>
  loanProgramName?: Maybe<Scalars['String']>
  loanPurpose?: Maybe<Scalars['String']>
  loanTerm?: Maybe<Scalars['Int']>
  loanToValue?: Maybe<Scalars['Float']>
  lockPeriod?: Maybe<Scalars['Int']>
  monthlyEscrowFees?: Maybe<Scalars['Float']>
  monthlyInsurance?: Maybe<Scalars['Float']>
  monthlyPayment?: Maybe<Scalars['Float']>
  monthlyPrivateMortgageInsurance?: Maybe<Scalars['Float']>
  monthlyPropertyTax?: Maybe<Scalars['Float']>
  mortgageInsurancePremium?: Maybe<MortgageInsurancePremium>
  originationPoints?: Maybe<Scalars['Float']>
  points?: Maybe<Scalars['Int']>
  principleAndInterestPayment?: Maybe<Scalars['Float']>
  privateMortgageInsuranceQuote?: Maybe<PmiQuote>
  productClass?: Maybe<ProductClass>
  productCode?: Maybe<Scalars['String']>
  propertyValue?: Maybe<Scalars['Float']>
  quoteType?: Maybe<Scalars['String']>
  rate?: Maybe<Scalars['Float']>
  rateSheetPrice?: Maybe<Scalars['Float']>
  rollInFees?: Maybe<Scalars['Int']>
  silkFees?: Maybe<Array<Maybe<Fees>>>
  subordinateLienAmount?: Maybe<Scalars['Int']>
  term?: Maybe<Scalars['String']>
  totalLenderFees?: Maybe<Scalars['Float']>
  type?: Maybe<Scalars['String']>
  updatedAt?: Maybe<Scalars['String']>
}

export enum MeetingType {
  Calendly = 'Calendly',
  FollowUp = 'FollowUp',
}

export type MessageAuthorSchema = {
  __typename?: 'MessageAuthorSchema'
  loanOfficer?: Maybe<AuthorSchema>
  processor?: Maybe<AuthorSchema>
  user?: Maybe<AuthorSchema>
}

export type MessageInput = {
  elloUserId?: Maybe<Scalars['String']>
  retoolId?: Maybe<Scalars['Int']>
  text?: Maybe<Scalars['String']>
  threadUuid?: Maybe<Scalars['String']>
}

export type MessageSchema = {
  __typename?: 'MessageSchema'
  author?: Maybe<MessageAuthorSchema>
  createdAt?: Maybe<Scalars['String']>
  messageUuid?: Maybe<Scalars['String']>
  readAt?: Maybe<Scalars['String']>
  text?: Maybe<Scalars['String']>
}

export type MessageThreadSchema = {
  __typename?: 'MessageThreadSchema'
  createdAt?: Maybe<Scalars['String']>
  isCoborrower?: Maybe<Scalars['Boolean']>
  messages?: Maybe<Array<Maybe<MessageSchema>>>
  threadUuid?: Maybe<Scalars['String']>
}

export type MortgageEstimate = {
  __typename?: 'MortgageEstimate'
  currentBalance?: Maybe<Scalars['Float']>
  estimatedInterestRate?: Maybe<Scalars['String']>
  loanAmount?: Maybe<Scalars['Float']>
  openedDate?: Maybe<Scalars['String']>
  paymentDueAmount?: Maybe<Scalars['Float']>
  paymentScheduleMonthCount?: Maybe<Scalars['Int']>
}

export type MortgageInsurancePremium = {
  __typename?: 'MortgageInsurancePremium'
  adjustments?: Maybe<Array<Maybe<Scalars['String']>>>
  annualDollarPremium?: Maybe<Scalars['Float']>
  annualPercentageRate?: Maybe<Scalars['Float']>
  company?: Maybe<Scalars['String']>
  monthlyDollarPremium?: Maybe<Scalars['Float']>
  monthlyPercentageRate?: Maybe<Scalars['Float']>
  upfrontDollarPremium?: Maybe<Scalars['Float']>
  upfrontPercentageRate?: Maybe<Scalars['Float']>
}

export type MortgageInsurancePremiumInput = {
  adjustments?: Maybe<Array<Maybe<Scalars['String']>>>
  annualDollarPremium?: Maybe<Scalars['Float']>
  annualPercentageRate?: Maybe<Scalars['Float']>
  company?: Maybe<Scalars['String']>
  monthlyDollarPremium?: Maybe<Scalars['Float']>
  monthlyPercentageRate?: Maybe<Scalars['Float']>
  upfrontDollarPremium?: Maybe<Scalars['Float']>
  upfrontPercentageRate?: Maybe<Scalars['Float']>
}

export type MqApiRate = {
  __typename?: 'MqApiRate'
  adjustments?: Maybe<Array<Maybe<Adjustment>>>
  adjustmentsTotal?: Maybe<Scalars['Float']>
  apr?: Maybe<Scalars['Float']>
  aprNoInsurance?: Maybe<Scalars['Float']>
  armDetails?: Maybe<ArmDetails>
  basePrice?: Maybe<Scalars['Float']>
  bestExecutionLoanProgram?: Maybe<Scalars['String']>
  combinedLoanToValue?: Maybe<Scalars['Float']>
  discountOrCreditAmount?: Maybe<Scalars['Float']>
  discountPoints?: Maybe<Scalars['Float']>
  discountPointsAsDollarAmount?: Maybe<Scalars['Float']>
  finalPrice?: Maybe<Scalars['Float']>
  governmentFees?: Maybe<Scalars['Float']>
  hasAdd?: Maybe<Scalars['Boolean']>
  id?: Maybe<Scalars['String']>
  interestOnlyMonths?: Maybe<Scalars['Float']>
  lenderBaseFees?: Maybe<Array<Maybe<LenderBaseFee>>>
  lenderCredit?: Maybe<Scalars['Float']>
  loanAmount?: Maybe<Scalars['Float']>
  loanProgram?: Maybe<LoanProgram>
  loanPurpose?: Maybe<Scalars['String']>
  loanTerm?: Maybe<Scalars['Int']>
  lockId?: Maybe<Scalars['String']>
  lockPeriod?: Maybe<Scalars['Int']>
  matchedDisallowPricingRuleDescription?: Maybe<Scalars['String']>
  mortgageInsurancePremium?: Maybe<MortgageInsurancePremium>
  note?: Maybe<Scalars['String']>
  originationPoints?: Maybe<Scalars['Float']>
  prePaymentPenalty?: Maybe<Scalars['Boolean']>
  principleAndInterestPayment?: Maybe<Scalars['Float']>
  quoteType?: Maybe<Scalars['String']>
  rate?: Maybe<Scalars['Float']>
  rateSheetPrice?: Maybe<Scalars['Float']>
  silkFees?: Maybe<Array<Maybe<Fees>>>
  specialNotes?: Maybe<Array<Maybe<Scalars['String']>>>
  stopEncountered?: Maybe<Scalars['Boolean']>
  totalBaseFees?: Maybe<Scalars['Float']>
  totalLenderFees?: Maybe<Scalars['Float']>
  userId?: Maybe<Scalars['Int']>
}

/** Define Mutations */
export type Mutation = {
  __typename?: 'Mutation'
  /** Bankrate Lead Submission */
  BankrateLeadSubmission?: Maybe<RedirectUrl>
  addCoBorrower?: Maybe<Scalars['Boolean']>
  addCondition?: Maybe<Scalars['Boolean']>
  addMessage?: Maybe<Scalars['Boolean']>
  addTaskToApplication?: Maybe<Scalars['Boolean']>
  /** Assign loan officer to an application */
  assignApplicationLoanOfficer?: Maybe<Scalars['Boolean']>
  assignColorToProcessorAssignment?: Maybe<Scalars['Boolean']>
  assignProcessor?: Maybe<ProcessorAssignment>
  /** Attach lender loan Id to an application */
  attachLenderLoanId?: Maybe<Scalars['Boolean']>
  clearCreditReport?: Maybe<Scalars['Boolean']>
  /** Convert NTL application to Baseline */
  convertNtlToBaseline?: Maybe<Scalars['Boolean']>
  copyCreditReport?: Maybe<Scalars['Boolean']>
  correctLenderLoanId?: Maybe<Scalars['Boolean']>
  correctPhoneNumber?: Maybe<Scalars['Boolean']>
  /** Create Auth0 Account */
  createAuth0Account: Auth0AccountCreationResponse
  createLoanOfficer?: Maybe<AdminLoanOfficer>
  createTask?: Maybe<Scalars['Boolean']>
  deleteAddress?: Maybe<Scalars['Boolean']>
  deleteAsset?: Maybe<Scalars['Boolean']>
  deleteCondition?: Maybe<Scalars['Boolean']>
  deleteDocumentRequest?: Maybe<Scalars['Boolean']>
  deleteIncome?: Maybe<Scalars['Boolean']>
  /** Soft delete loan officer and set active flag to false */
  deleteLoanOfficer?: Maybe<Scalars['Boolean']>
  /** Soft delete processor and set active flag to false */
  deleteProcessor?: Maybe<Scalars['Boolean']>
  deleteRealEstateOwned?: Maybe<Scalars['Boolean']>
  deleteRingCentralAccount?: Maybe<Scalars['Boolean']>
  deleteTask?: Maybe<Scalars['Boolean']>
  editCondition?: Maybe<Scalars['Boolean']>
  editDocumentRequest?: Maybe<Scalars['Boolean']>
  /** Edit loan officer's phone number */
  editLoanOfficerPhone?: Maybe<Scalars['Boolean']>
  /** Fill application */
  fill?: Maybe<Scalars['Boolean']>
  /** Fill application address */
  fillAddress?: Maybe<Array<Maybe<RecordId>>>
  /** Fill co-borrower */
  fillCoBorrower?: Maybe<FillCoBorrowerResponse>
  /** Fill Co-Borrower addresses */
  fillCoBorrowerAddresses: Array<RecordId>
  /** Fill Co-Borrower demographics */
  fillCoBorrowerDemographics?: Maybe<RecordId>
  /** Fill Co-Borrower income */
  fillCoBorrowerIncome: Array<RecordId>
  /** Fill Co-Borrower liabilities */
  fillCoBorrowerLiabilities?: Maybe<Array<Maybe<RecordId>>>
  /** Fill demographics */
  fillDemographics?: Maybe<RecordId>
  /** Fill application income */
  fillIncome?: Maybe<Array<Maybe<RecordId>>>
  /** Fill liabilities */
  fillLiabilities?: Maybe<Array<Maybe<RecordId>>>
  /** Fill real estate owned */
  fillReo?: Maybe<FillReoResponse>
  /** Fill ApplicationSession */
  fillSession: Scalars['Boolean']
  /** Get and save Corelogic AVM Property Info */
  getAndSaveAVMData?: Maybe<AvmPropertyDataResult>
  /** Initialize application */
  initialize?: Maybe<InitializeResponse>
  /**
   * Temporary resolver to be called to initalize a checklist on any
   * loans that were backfilled/not assigned through Retool
   */
  initializeChecklist?: Maybe<Scalars['Boolean']>
  /** Lock WIP Lock Form */
  lockWipLockForm?: Maybe<Scalars['Boolean']>
  /** Manually esign an application's current lock form */
  manualEsign?: Maybe<Scalars['Boolean']>
  markMessageThreadAsRead?: Maybe<Scalars['Boolean']>
  newRelicIncrement?: Maybe<Scalars['Boolean']>
  /** Frontend New Relic Events */
  newRelicTiming?: Maybe<Scalars['Boolean']>
  /** Reassign Client Care Specialists */
  reassignClientCareSpecialists?: Maybe<BulkReassignResponse>
  /** Reassign Icebox leads with auto-assign logic */
  reassignIceboxes?: Maybe<BulkReassignResponse>
  /** Reassign Loan Officer */
  reassignLoanOfficer?: Maybe<ReassignLoanOfficerResponse>
  /** Release the mq_lock_id from an application */
  releaseLockId?: Maybe<Scalars['Boolean']>
  removeCoBorrower?: Maybe<Scalars['Boolean']>
  removeTaskFromApplication?: Maybe<Scalars['Boolean']>
  /** Replicate an application */
  replicateApplication?: Maybe<Scalars['Int']>
  /** Replicate an application V2 */
  replicateApplicationV2?: Maybe<Scalars['Int']>
  /** Saves AVM Property Info */
  saveAVMPropertyInfo?: Maybe<Scalars['Boolean']>
  /** Save answer set */
  saveAnswerSet?: Maybe<Scalars['Boolean']>
  saveApplicationAddressRevision?: Maybe<Scalars['Boolean']>
  /** Save Application Disclosures Consent Revisions */
  saveApplicationDisclosure?: Maybe<Scalars['Boolean']>
  /** Save Application Disclosures Consent Revisions */
  saveApplicationDisclosures?: Maybe<Scalars['Boolean']>
  /** Save application loan officer action revision */
  saveApplicationLoanOfficerActionRevision?: Maybe<Scalars['Boolean']>
  /** Saves an application note with the authenticated user as author */
  saveApplicationNote?: Maybe<Scalars['Boolean']>
  /** Saves an application reminder */
  saveApplicationReminder?: Maybe<Scalars['Boolean']>
  saveApplicationRevision?: Maybe<SaveApplicationRevisionResponse>
  saveAssetRevision?: Maybe<Scalars['Boolean']>
  saveCoBorrowerApplicationAddressRevision?: Maybe<Scalars['Boolean']>
  saveCoBorrowerApplicationDisclosure?: Maybe<Scalars['Boolean']>
  /** Save CoBorrower Application Disclosures Consent Revisions */
  saveCoBorrowerApplicationDisclosures?: Maybe<Scalars['Boolean']>
  saveCoBorrowerApplicationRevision?: Maybe<SaveApplicationRevisionResponse>
  saveCoBorrowerDemographics?: Maybe<Scalars['Boolean']>
  /** Save co borrower SSN alias to co borrower revision */
  saveCoBorrowerSSNAlias: Scalars['Boolean']
  saveContactTemplate?: Maybe<Scalars['Boolean']>
  saveDemographics?: Maybe<Scalars['Boolean']>
  saveDocumentRequestNote?: Maybe<DocumentRequestNote>
  saveDocumentRequestStatus?: Maybe<Scalars['Boolean']>
  saveIncomeRevision?: Maybe<Scalars['Boolean']>
  saveLiabilityRevision?: Maybe<Scalars['Boolean']>
  saveLockForm?: Maybe<Scalars['Boolean']>
  /** Save NTL disqualified reason */
  saveNtlDisqualifiedReason?: Maybe<Scalars['Boolean']>
  savePrelockedRate?: Maybe<Scalars['Boolean']>
  savePricingScenario?: Maybe<Scalars['Boolean']>
  /** Add or update a processor's info */
  saveProcessorInfo?: Maybe<Scalars['Boolean']>
  saveRealEstateOwned?: Maybe<Scalars['Boolean']>
  saveRingCentralAccount?: Maybe<Scalars['Boolean']>
  /** Save SSN alias to application revision */
  saveSSNAlias: Scalars['Boolean']
  /** Save Sage Contact */
  saveSageContact?: Maybe<Scalars['String']>
  /** Save Sage Contact Assignment */
  saveSageContactAssignment?: Maybe<Scalars['Boolean']>
  selectAUSReport?: Maybe<Scalars['Boolean']>
  /** Trigger Lock Desk failure Slack alert */
  sendLockDeskFailureSlackAlert?: Maybe<Scalars['Boolean']>
  sendRingCentralSMSByTemplate?: Maybe<SendSmsResponse>
  /** Set experience type */
  setExperienceType?: Maybe<Scalars['Int']>
  setLoanOfficerConfiguration?: Maybe<Scalars['Boolean']>
  /** Soft delete application disclosure consent revision by type */
  softDeleteApplicationDisclosure?: Maybe<Scalars['Boolean']>
  /** Soft pull Credit Score */
  softPullCreditScore?: Maybe<SoftPullResponse>
  storeAddressValidationEvent?: Maybe<Scalars['Boolean']>
  /** Bankrate Compare Lead Submission (initialize + fill application) */
  submitBRCompareLead?: Maybe<LeadResponse>
  /** General Lead Submission (initialize + fill application) */
  submitLead?: Maybe<LeadResponse>
  /** Submit lock form */
  submitLockForm?: Maybe<Scalars['Boolean']>
  /** Save Rate Selection */
  submitRateSelection?: Maybe<Scalars['Boolean']>
  /**
   * Toggle the sync locked flag on an application's external reference record
   * and returns the status of sync locked
   */
  toggleSyncLocked?: Maybe<Scalars['Boolean']>
  /** Update application session */
  updateApplicationSession?: Maybe<Scalars['Boolean']>
  updateApplicationTask?: Maybe<Scalars['Boolean']>
  updateAttachmentApproval?: Maybe<Scalars['Boolean']>
  /** Update lender loan Id in an application */
  updateLenderLoanId?: Maybe<Scalars['Boolean']>
  /** Update loan setup checklist item */
  updateLoanSetupCheckListItem?: Maybe<Scalars['Boolean']>
  updateLockForm?: Maybe<Scalars['Boolean']>
  /** Fill application */
  updateQStatus?: Maybe<Scalars['Boolean']>
  verifyRateLock?: Maybe<Scalars['Int']>
}

/** Define Mutations */
export type MutationBankrateLeadSubmissionArgs = {
  lead?: Maybe<BankrateLeadRequest>
}

/** Define Mutations */
export type MutationAddCoBorrowerArgs = {
  applicationId: Scalars['Int']
  revision: SaveCoBorrowerApplicationRevisionInputSchema
}

/** Define Mutations */
export type MutationAddConditionArgs = {
  applicationId?: Maybe<Scalars['Int']>
  coBorrowerApplicationId?: Maybe<Scalars['Int']>
  conditionInput?: Maybe<AddConditionInput>
}

/** Define Mutations */
export type MutationAddMessageArgs = {
  applicationId?: Maybe<Scalars['Int']>
  coBorrowerApplicationId?: Maybe<Scalars['Int']>
  message?: Maybe<MessageInput>
}

/** Define Mutations */
export type MutationAddTaskToApplicationArgs = {
  applicationId: Scalars['Int']
  taskId: Scalars['Int']
  userId: Scalars['Int']
}

/** Define Mutations */
export type MutationAssignApplicationLoanOfficerArgs = {
  applicationId: Scalars['Int']
  loanOfficerId: Scalars['Int']
}

/** Define Mutations */
export type MutationAssignColorToProcessorAssignmentArgs = {
  assignmentColor?: Maybe<ProcessorAssignmentColorInput>
}

/** Define Mutations */
export type MutationAssignProcessorArgs = {
  assignmentData?: Maybe<ProcessorAssignmentInput>
}

/** Define Mutations */
export type MutationAttachLenderLoanIdArgs = {
  request: AttachLenderLoanIdRequest
}

/** Define Mutations */
export type MutationClearCreditReportArgs = {
  applicationId?: Maybe<Scalars['Int']>
  coBorrowerId?: Maybe<Scalars['Int']>
  isPrequal?: Maybe<Scalars['Boolean']>
}

/** Define Mutations */
export type MutationCopyCreditReportArgs = {
  applicationId: Scalars['Int']
  crIdentifier: Scalars['String']
  isCoborrower?: Maybe<Scalars['Boolean']>
  isPrequal?: Maybe<Scalars['Boolean']>
}

/** Define Mutations */
export type MutationCorrectLenderLoanIdArgs = {
  applicationId: Scalars['Int']
  lenderLoanId: Scalars['String']
}

/** Define Mutations */
export type MutationCorrectPhoneNumberArgs = {
  applicationId: Scalars['Int']
  phone: Scalars['String']
}

/** Define Mutations */
export type MutationCreateAuth0AccountArgs = {
  applicationId?: Maybe<Scalars['Int']>
  email: Scalars['String']
  firstName?: Maybe<Scalars['String']>
  password?: Maybe<Scalars['String']>
}

/** Define Mutations */
export type MutationCreateLoanOfficerArgs = {
  loanOfficer: CreateLoanOfficerInput
}

/** Define Mutations */
export type MutationCreateTaskArgs = {
  task: SaveTaskInput
}

/** Define Mutations */
export type MutationDeleteAddressArgs = {
  addressId?: Maybe<Scalars['Int']>
  coBorrowerAddressId?: Maybe<Scalars['Int']>
}

/** Define Mutations */
export type MutationDeleteAssetArgs = {
  assetId: Scalars['Int']
}

/** Define Mutations */
export type MutationDeleteConditionArgs = {
  conditionUuid: Scalars['String']
}

/** Define Mutations */
export type MutationDeleteDocumentRequestArgs = {
  documentRequestUuid: Scalars['String']
}

/** Define Mutations */
export type MutationDeleteIncomeArgs = {
  coBorrowerIncomeId?: Maybe<Scalars['Int']>
  incomeId?: Maybe<Scalars['Int']>
}

/** Define Mutations */
export type MutationDeleteLoanOfficerArgs = {
  loanOfficerId: Scalars['Int']
}

/** Define Mutations */
export type MutationDeleteProcessorArgs = {
  processorId: Scalars['Int']
}

/** Define Mutations */
export type MutationDeleteRealEstateOwnedArgs = {
  reoId?: Maybe<Scalars['Int']>
}

/** Define Mutations */
export type MutationDeleteRingCentralAccountArgs = {
  email: Scalars['String']
}

/** Define Mutations */
export type MutationDeleteTaskArgs = {
  taskId: Scalars['Int']
}

/** Define Mutations */
export type MutationEditConditionArgs = {
  conditionEdit?: Maybe<ConditionInput>
}

/** Define Mutations */
export type MutationEditDocumentRequestArgs = {
  docRequestEdit?: Maybe<DocumentRequestInput>
}

/** Define Mutations */
export type MutationEditLoanOfficerPhoneArgs = {
  loanOfficerId: Scalars['Int']
  phone: Scalars['String']
}

/** Define Mutations */
export type MutationFillArgs = {
  answers?: Maybe<ApplicationAnswers>
}

/** Define Mutations */
export type MutationFillAddressArgs = {
  addresses?: Maybe<Array<Maybe<MutationFillAddress>>>
  applicationId?: Maybe<Scalars['Int']>
}

/** Define Mutations */
export type MutationFillCoBorrowerArgs = {
  answers?: Maybe<ApplicationAnswers>
  applicationId?: Maybe<Scalars['Int']>
  coBorrowerId?: Maybe<Scalars['Int']>
}

/** Define Mutations */
export type MutationFillCoBorrowerAddressesArgs = {
  coBorrowerAddresses: Array<CoBorrowerAddress>
  coBorrowerId: Scalars['Int']
}

/** Define Mutations */
export type MutationFillCoBorrowerDemographicsArgs = {
  coBorrowerId: Scalars['Int']
  demographicsAnswers?: Maybe<DemographicsAnswers>
}

/** Define Mutations */
export type MutationFillCoBorrowerIncomeArgs = {
  coBorrowerId: Scalars['Int']
  incomeSources: Array<IncomeSource>
}

/** Define Mutations */
export type MutationFillCoBorrowerLiabilitiesArgs = {
  coBorrowerId: Scalars['Int']
  liabilities?: Maybe<Array<Maybe<LiabilityAnswers>>>
}

/** Define Mutations */
export type MutationFillDemographicsArgs = {
  applicationId?: Maybe<Scalars['Int']>
  demographicsAnswers?: Maybe<DemographicsAnswers>
}

/** Define Mutations */
export type MutationFillIncomeArgs = {
  applicationId?: Maybe<Scalars['Int']>
  incomeSources?: Maybe<Array<Maybe<IncomeSource>>>
}

/** Define Mutations */
export type MutationFillLiabilitiesArgs = {
  applicationId?: Maybe<Scalars['Int']>
  liabilities?: Maybe<Array<Maybe<LiabilityAnswers>>>
}

/** Define Mutations */
export type MutationFillReoArgs = {
  applicationId?: Maybe<Scalars['Int']>
  realEstateOwned?: Maybe<Array<Maybe<RealEstateOwnedAnswer>>>
  realEstateOwnedIds?: Maybe<Array<Maybe<Scalars['Int']>>>
}

/** Define Mutations */
export type MutationFillSessionArgs = {
  questionSet: Scalars['String']
}

/** Define Mutations */
export type MutationGetAndSaveAvmDataArgs = {
  request: AvmPropertyDataRequest
}

/** Define Mutations */
export type MutationInitializeArgs = {
  bankrateLeadId?: Maybe<Scalars['String']>
  fBLeadId?: Maybe<Scalars['String']>
  landingUrl?: Maybe<Scalars['String']>
  leadId?: Maybe<Scalars['Int']>
  leadSource?: Maybe<Scalars['String']>
  leadType?: Maybe<Scalars['String']>
  loanOfficerId?: Maybe<Scalars['Int']>
  questionnaireType?: Maybe<Scalars['String']>
  referrerUrl?: Maybe<Scalars['String']>
  sourceLeadId?: Maybe<Scalars['String']>
  trackingAttributes?: Maybe<Scalars['JSON']>
}

/** Define Mutations */
export type MutationInitializeChecklistArgs = {
  applicationId: Scalars['Int']
}

/** Define Mutations */
export type MutationManualEsignArgs = {
  applicationId: Scalars['Int']
}

/** Define Mutations */
export type MutationMarkMessageThreadAsReadArgs = {
  retoolId?: Maybe<Scalars['Int']>
  threadUuid?: Maybe<Scalars['String']>
}

/** Define Mutations */
export type MutationNewRelicIncrementArgs = {
  name: Scalars['String']
  tags?: Maybe<Array<Maybe<EventTag>>>
}

/** Define Mutations */
export type MutationNewRelicTimingArgs = {
  name: Scalars['String']
  tags?: Maybe<Array<Maybe<EventTag>>>
  value: Scalars['Int']
}

/** Define Mutations */
export type MutationReassignClientCareSpecialistsArgs = {
  applicationGuids?: Maybe<Array<Maybe<Scalars['String']>>>
}

/** Define Mutations */
export type MutationReassignIceboxesArgs = {
  applicationGuids?: Maybe<Array<Maybe<Scalars['String']>>>
}

/** Define Mutations */
export type MutationReassignLoanOfficerArgs = {
  applicationId: Scalars['Int']
  loanOfficerId: Scalars['Int']
}

/** Define Mutations */
export type MutationReleaseLockIdArgs = {
  lockFormId: Scalars['Int']
}

/** Define Mutations */
export type MutationRemoveCoBorrowerArgs = {
  coBorrowerId: Scalars['Int']
}

/** Define Mutations */
export type MutationRemoveTaskFromApplicationArgs = {
  applicationTaskId: Scalars['Int']
}

/** Define Mutations */
export type MutationReplicateApplicationArgs = {
  applicationId: Scalars['Int']
  isSameAddress?: Maybe<Scalars['Boolean']>
}

/** Define Mutations */
export type MutationReplicateApplicationV2Args = {
  applicationId: Scalars['Int']
  options?: Maybe<ReplicateApplicationOptions>
}

/** Define Mutations */
export type MutationSaveAvmPropertyInfoArgs = {
  applicationId: Scalars['Int']
  corelogicPropertyId?: Maybe<Scalars['String']>
  propertyInfo: AvmPropertyDetailsInput
}

/** Define Mutations */
export type MutationSaveAnswerSetArgs = {
  answerSet?: Maybe<GqlAnswerSetInput>
}

/** Define Mutations */
export type MutationSaveApplicationAddressRevisionArgs = {
  applicationId?: Maybe<Scalars['Int']>
  revision?: Maybe<SaveApplicationAddressRevisionInputSchema>
}

/** Define Mutations */
export type MutationSaveApplicationDisclosureArgs = {
  applicationId: Scalars['Int']
  disclosure: DisclosureInformation
}

/** Define Mutations */
export type MutationSaveApplicationDisclosuresArgs = {
  disclosures?: Maybe<Array<Maybe<DisclosureInformation>>>
}

/** Define Mutations */
export type MutationSaveApplicationLoanOfficerActionRevisionArgs = {
  request: SaveApplicationLoanOfficerActionRevisionRequest
}

/** Define Mutations */
export type MutationSaveApplicationNoteArgs = {
  request: ApplicationNoteSaveRequest
}

/** Define Mutations */
export type MutationSaveApplicationReminderArgs = {
  request: ApplicationReminderSaveRequest
}

/** Define Mutations */
export type MutationSaveApplicationRevisionArgs = {
  applicationId?: Maybe<Scalars['Int']>
  revision?: Maybe<SaveApplicationRevisionInputSchema>
}

/** Define Mutations */
export type MutationSaveAssetRevisionArgs = {
  applicationId?: Maybe<Scalars['Int']>
  assets?: Maybe<Array<Maybe<AssetInput>>>
}

/** Define Mutations */
export type MutationSaveCoBorrowerApplicationAddressRevisionArgs = {
  coBorrowerId?: Maybe<Scalars['Int']>
  revision?: Maybe<SaveCoBorrowerApplicationAddressRevisionInputSchema>
}

/** Define Mutations */
export type MutationSaveCoBorrowerApplicationDisclosureArgs = {
  coBorrowerId: Scalars['Int']
  disclosure: DisclosureInformation
}

/** Define Mutations */
export type MutationSaveCoBorrowerApplicationDisclosuresArgs = {
  disclosures?: Maybe<Array<Maybe<DisclosureInformation>>>
}

/** Define Mutations */
export type MutationSaveCoBorrowerApplicationRevisionArgs = {
  coBorrowerId?: Maybe<Scalars['Int']>
  revision?: Maybe<SaveCoBorrowerApplicationRevisionInputSchema>
}

/** Define Mutations */
export type MutationSaveCoBorrowerDemographicsArgs = {
  coBorrowerId: Scalars['Int']
  demographicsAnswers?: Maybe<DemographicsAnswers>
}

/** Define Mutations */
export type MutationSaveCoBorrowerSsnAliasArgs = {
  coBorrowerId: Scalars['Int']
  ssnAlias: Scalars['String']
}

/** Define Mutations */
export type MutationSaveContactTemplateArgs = {
  name: Scalars['String']
  retoolId?: Maybe<Scalars['Int']>
  retoolUserType: Scalars['String']
  text: Scalars['String']
  type: Scalars['String']
}

/** Define Mutations */
export type MutationSaveDemographicsArgs = {
  applicationId: Scalars['Int']
  demographicsAnswers?: Maybe<DemographicsAnswers>
}

/** Define Mutations */
export type MutationSaveDocumentRequestNoteArgs = {
  docRequestNote?: Maybe<DocumentRequestNoteInput>
}

/** Define Mutations */
export type MutationSaveDocumentRequestStatusArgs = {
  docRequestUuid?: Maybe<Scalars['String']>
  status?: Maybe<Scalars['String']>
}

/** Define Mutations */
export type MutationSaveIncomeRevisionArgs = {
  applicationId?: Maybe<Scalars['Int']>
  coBorrowerId?: Maybe<Scalars['Int']>
  incomeSources?: Maybe<Array<Maybe<IncomeSource>>>
}

/** Define Mutations */
export type MutationSaveLiabilityRevisionArgs = {
  applicationId?: Maybe<Scalars['Int']>
  coBorrowerId?: Maybe<Scalars['Int']>
  editorName?: Maybe<Scalars['String']>
  liability?: Maybe<SaveLiabilityRevisionInputSchema>
}

/** Define Mutations */
export type MutationSaveLockFormArgs = {
  applicationId: Scalars['Int']
  cicSlackEnabled?: Maybe<Scalars['Boolean']>
  costOfLoan?: Maybe<Scalars['Float']>
  costOfLoanYears?: Maybe<Scalars['Int']>
  isFloated: Scalars['Boolean']
  isManual?: Maybe<Scalars['Boolean']>
  isNoTalkLock?: Maybe<Scalars['Boolean']>
  lockId?: Maybe<Scalars['String']>
  notes?: Maybe<Scalars['String']>
  pricingScenario: PricingInput
  processingQuestions?: Maybe<Scalars['String']>
  rate: RateInput
  requestType?: Maybe<LockRequestType>
}

/** Define Mutations */
export type MutationSaveNtlDisqualifiedReasonArgs = {
  applicationId?: Maybe<Scalars['Int']>
  reason: Scalars['String']
}

/** Define Mutations */
export type MutationSavePrelockedRateArgs = {
  applicationId: Scalars['Int']
  costOfLoan?: Maybe<Scalars['Float']>
  costOfLoanYears?: Maybe<Scalars['Int']>
  lockId?: Maybe<Scalars['String']>
  rate: RateInput
}

/** Define Mutations */
export type MutationSavePricingScenarioArgs = {
  applicationId: Scalars['Int']
  input: PricingInput
}

/** Define Mutations */
export type MutationSaveProcessorInfoArgs = {
  processorInfo?: Maybe<ProcessorInfoInput>
}

/** Define Mutations */
export type MutationSaveRealEstateOwnedArgs = {
  applicationId?: Maybe<Scalars['Int']>
  coBorrowerId?: Maybe<Scalars['Int']>
  liabilityIds?: Maybe<Array<Maybe<Scalars['Int']>>>
  owner?: Maybe<Scalars['String']>
  reo?: Maybe<SaveRealEstateOwnedInputSchema>
}

/** Define Mutations */
export type MutationSaveRingCentralAccountArgs = {
  elloUserId?: Maybe<Scalars['String']>
  extensionNumber: Scalars['Int']
  jwt: Scalars['String']
  phoneNumber: Scalars['String']
  retoolId?: Maybe<Scalars['Int']>
}

/** Define Mutations */
export type MutationSaveSsnAliasArgs = {
  ssnAlias: Scalars['String']
}

/** Define Mutations */
export type MutationSaveSageContactArgs = {
  contact: SaveSageContactInput
}

/** Define Mutations */
export type MutationSaveSageContactAssignmentArgs = {
  assignment: SaveSageContactAssignmentInput
}

/** Define Mutations */
export type MutationSelectAusReportArgs = {
  applicationId: Scalars['Int']
  ausReportId: Scalars['Int']
}

/** Define Mutations */
export type MutationSendLockDeskFailureSlackAlertArgs = {
  applicationId?: Maybe<Scalars['Int']>
}

/** Define Mutations */
export type MutationSendRingCentralSmsByTemplateArgs = {
  applicationId: Scalars['Int']
  coBorrowerId?: Maybe<Scalars['Int']>
  elloUserId?: Maybe<Scalars['String']>
  retoolId?: Maybe<Scalars['Int']>
  templateId?: Maybe<Scalars['Int']>
}

/** Define Mutations */
export type MutationSetExperienceTypeArgs = {
  applicationId?: Maybe<Scalars['Int']>
  experienceType: ExperienceType
}

/** Define Mutations */
export type MutationSetLoanOfficerConfigurationArgs = {
  request?: Maybe<SetConfigurationArgs>
}

/** Define Mutations */
export type MutationSoftDeleteApplicationDisclosureArgs = {
  applicationId: Scalars['Int']
  disclosureType: DisclosureType
}

/** Define Mutations */
export type MutationSoftPullCreditScoreArgs = {
  applicationId?: Maybe<Scalars['Int']>
  returnMatchingEstimate?: Maybe<Scalars['Boolean']>
}

/** Define Mutations */
export type MutationStoreAddressValidationEventArgs = {
  applicationId: Scalars['Int']
}

/** Define Mutations */
export type MutationSubmitBrCompareLeadArgs = {
  leadInfo?: Maybe<BankrateCompareLeadInformation>
}

/** Define Mutations */
export type MutationSubmitLeadArgs = {
  leadInfo?: Maybe<LeadInformation>
}

/** Define Mutations */
export type MutationSubmitLockFormArgs = {
  applicationId?: Maybe<Scalars['Int']>
  lockRequestType?: Maybe<LockRequestType>
  pricingScenarioInfo?: Maybe<PricingScenarioInfoInput>
  rate?: Maybe<RateInput>
}

/** Define Mutations */
export type MutationSubmitRateSelectionArgs = {
  applicationId?: Maybe<Scalars['Int']>
  rate: RateInput
}

/** Define Mutations */
export type MutationToggleSyncLockedArgs = {
  applicationId: Scalars['Int']
}

/** Define Mutations */
export type MutationUpdateApplicationSessionArgs = {
  applicationGuid: Scalars['String']
}

/** Define Mutations */
export type MutationUpdateApplicationTaskArgs = {
  applicationTaskId: Scalars['Int']
  task: SaveApplicationTaskInput
}

/** Define Mutations */
export type MutationUpdateAttachmentApprovalArgs = {
  attachments?: Maybe<Array<Maybe<UpdateAttachmentInput>>>
}

/** Define Mutations */
export type MutationUpdateLenderLoanIdArgs = {
  request: UpdateLenderLoanIdRequest
}

/** Define Mutations */
export type MutationUpdateLoanSetupCheckListItemArgs = {
  request: UpdateLoanSetupChecklistItemRequest
}

/** Define Mutations */
export type MutationUpdateLockFormArgs = {
  request: UpdateLockFormRequest
}

/** Define Mutations */
export type MutationUpdateQStatusArgs = {
  status: Status
}

/** Define Mutations */
export type MutationVerifyRateLockArgs = {
  request: VerifyRateLockRequest
}

export type MutationFillAddress = {
  apt?: Maybe<Scalars['String']>
  autoCompleteAddressFields?: Maybe<Scalars['String']>
  city?: Maybe<Scalars['String']>
  county?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['Int']>
  isAttached?: Maybe<Scalars['Boolean']>
  moveInDate?: Maybe<Scalars['String']>
  moveOutDate?: Maybe<Scalars['String']>
  numberOfStories?: Maybe<Scalars['Int']>
  occupancyType?: Maybe<OccupancyType>
  rentAmount?: Maybe<Scalars['Float']>
  state?: Maybe<Scalars['String']>
  street?: Maybe<Scalars['String']>
  type?: Maybe<AddressType>
  zipCode?: Maybe<Scalars['String']>
}

export type NameAlias = {
  __typename?: 'NameAlias'
  firstName?: Maybe<Scalars['String']>
  lastName?: Maybe<Scalars['String']>
  middleName?: Maybe<Scalars['String']>
}

export type NameAliasInput = {
  firstName?: Maybe<Scalars['String']>
  lastName?: Maybe<Scalars['String']>
  middleName?: Maybe<Scalars['String']>
}

export type NtlQualResult = {
  __typename?: 'NtlQualResult'
  currentLoanTerm?: Maybe<Scalars['Int']>
  debtToIncome?: Maybe<Scalars['Float']>
  isQualified?: Maybe<Scalars['Boolean']>
  reason?: Maybe<Scalars['String']>
}

export enum OccupancyType {
  NoExpense = 'NoExpense',
  Owned = 'Owned',
  Renting = 'Renting',
}

export type Option = {
  __typename?: 'Option'
  component?: Maybe<Scalars['String']>
  iconName?: Maybe<Scalars['String']>
  inputField?: Maybe<Array<Maybe<QuestionInput>>>
  subOptions?: Maybe<Array<Maybe<QuestionCheckboxMulti>>>
  text: Scalars['String']
  value: Scalars['String']
}

export type OtherPage =
  | ErrorPage
  | RateSelectPage
  | RedirectInterstitial
  | SuccessPage

export enum OwnershipClassification {
  Both = 'Both',
  Primary = 'Primary',
  Secondary = 'Secondary',
}

export type PmiQuote = {
  __typename?: 'PMIQuote'
  firstPremiumPeriod?: Maybe<PmiQuotePeriod>
  secondPremiumPeriod?: Maybe<PmiQuotePeriod>
}

export type PmiQuoteInput = {
  firstPremiumPeriod?: Maybe<PmiQuotePeriodInput>
  secondPremiumPeriod?: Maybe<PmiQuotePeriodInput>
}

export type PmiQuotePeriod = {
  __typename?: 'PMIQuotePeriod'
  durationInMonths?: Maybe<Scalars['Int']>
  monthlyRatePercent?: Maybe<Scalars['Float']>
  premiumAmount?: Maybe<Scalars['Float']>
}

export type PmiQuotePeriodInput = {
  durationInMonths?: Maybe<Scalars['Int']>
  monthlyRatePercent?: Maybe<Scalars['Float']>
  premiumAmount?: Maybe<Scalars['Float']>
}

export type Page = {
  __typename?: 'Page'
  allowSkip?: Maybe<Scalars['Boolean']>
  component?: Maybe<Scalars['String']>
  customPageValidation?: Maybe<Scalars['String']>
  description?: Maybe<Scalars['String']>
  displaySumOfFields?: Maybe<Scalars['Boolean']>
  modifierClass?: Maybe<Scalars['String']>
  name: Scalars['String']
  nextButtonText?: Maybe<Scalars['String']>
  questions?: Maybe<Array<Maybe<Questions>>>
  section?: Maybe<Scalars['String']>
  subTitle?: Maybe<Scalars['String']>
  title?: Maybe<Scalars['String']>
  type: Scalars['String']
  validationErrorMsg?: Maybe<Scalars['String']>
  visibleIf?: Maybe<Scalars['String']>
  visibleIfConstructor?: Maybe<Array<Maybe<VisibleIfCondition>>>
}

export type Pagination = {
  pageSize?: Maybe<Scalars['Int']>
  paginationOffset?: Maybe<Scalars['Int']>
}

export type PaginationInput = {
  offset?: Maybe<Scalars['Int']>
  pageSize?: Maybe<Scalars['Int']>
}

export type PaginationResponse = {
  __typename?: 'PaginationResponse'
  total?: Maybe<Scalars['Int']>
}

export enum PointEnum {
  ClosestTo01 = 'ClosestTo01',
  ClosestTo02 = 'ClosestTo02',
  ClosestTo03 = 'ClosestTo03',
  ClosestToZeroNoFee = 'ClosestToZeroNoFee',
  ClosestToZeroWithFee = 'ClosestToZeroWithFee',
  GreaterThan3Point = 'GreaterThan3Point',
  NotSet = 'NotSet',
}

export type PrequalRevisionSchema = {
  __typename?: 'PrequalRevisionSchema'
  loanAmount?: Maybe<Scalars['Float']>
  maxDownPayment?: Maybe<Scalars['Float']>
  maxPurchasePrice?: Maybe<Scalars['Float']>
  originatedAt?: Maybe<Scalars['String']>
  propertyType?: Maybe<Scalars['String']>
}

export type PricingExceptionSchema = {
  __typename?: 'PricingExceptionSchema'
  amount: Scalars['Float']
  applicationId: Scalars['Int']
  autoAcceptLevel?: Maybe<Scalars['Float']>
  canMatchOrBeat?: Maybe<Scalars['Boolean']>
  comparisonRate?: Maybe<Scalars['JSON']>
  createdAt?: Maybe<Scalars['String']>
  deletedAt?: Maybe<Scalars['String']>
  documentExtractId?: Maybe<Scalars['Int']>
  documentType?: Maybe<Scalars['String']>
  id: Scalars['Int']
  lockBonusUsedInCalculation?: Maybe<Scalars['Float']>
  lockedWithOtherLender?: Maybe<Scalars['Boolean']>
  source: Scalars['String']
  updatedAt?: Maybe<Scalars['String']>
}

export type PricingInput = {
  cashOut: Scalars['Int']
  city: Scalars['String']
  costOfLoanYears?: Maybe<Scalars['Int']>
  creditScore: Scalars['Int']
  debtToIncome: Scalars['Int']
  entitlement?: Maybe<Scalars['Float']>
  escrowOptions?: Maybe<EscrowOptions>
  firstTimeUsingVaLoan?: Maybe<Scalars['Boolean']>
  incomeType: Scalars['String']
  loanAmount: Scalars['Float']
  loanTerm?: Maybe<ProductTerm>
  loanTerms?: Maybe<Array<Maybe<ProductTerm>>>
  loanToValue: Scalars['Float']
  lockId?: Maybe<Scalars['String']>
  lockPeriod?: Maybe<Scalars['Int']>
  monthlyInsurance?: Maybe<Scalars['Float']>
  points?: Maybe<PointEnum>
  product?: Maybe<Scalars['String']>
  productClass?: Maybe<ProductClass>
  productFamilies?: Maybe<Array<Maybe<ProductFamily>>>
  productFamily?: Maybe<ProductFamily>
  propertyDownPayment?: Maybe<Scalars['Float']>
  propertyTax?: Maybe<Scalars['Float']>
  propertyType: PropertyType
  propertyUse: PropertyUse
  propertyValue: Scalars['Int']
  quotingChannel?: Maybe<QuotingChannel>
  remainingBalance?: Maybe<Scalars['Float']>
  rollInEscrow?: Maybe<Scalars['Float']>
  rollInFee: Scalars['Int']
  state: StateAbbreviation
  subordinateLien: Scalars['Int']
  subordinateLienCreditLimit?: Maybe<Scalars['Float']>
  useFeesInChannel?: Maybe<Scalars['Boolean']>
  veteranDisabled?: Maybe<Scalars['Boolean']>
  veteranType?: Maybe<VeteranType>
  waiveEscrow: Scalars['Boolean']
  zip: Scalars['String']
}

export type PricingScenario = {
  __typename?: 'PricingScenario'
  applicationId: Scalars['String']
  cashOut?: Maybe<Scalars['Int']>
  city?: Maybe<Scalars['String']>
  company?: Maybe<Scalars['String']>
  costOfLoanYears?: Maybe<Scalars['Int']>
  creditScore?: Maybe<Scalars['Int']>
  debtToIncome?: Maybe<Scalars['Int']>
  escrowOptions?: Maybe<EscrowOptions>
  firstTimeUsingVaLoan?: Maybe<Scalars['Boolean']>
  incomeType?: Maybe<Scalars['String']>
  loanAmount?: Maybe<Scalars['Float']>
  loanTerm?: Maybe<ProductTerm>
  loanTerms?: Maybe<Array<Maybe<ProductTerm>>>
  loanToValue?: Maybe<Scalars['Float']>
  lockId?: Maybe<Scalars['String']>
  lockPeriod?: Maybe<Scalars['Int']>
  monthlyInsurance?: Maybe<Scalars['Float']>
  points?: Maybe<PointEnum>
  product?: Maybe<Scalars['String']>
  productClass?: Maybe<ProductClass>
  productFamilies?: Maybe<Array<Maybe<ProductFamily>>>
  productFamily?: Maybe<ProductFamily>
  propertyDownPayment?: Maybe<Scalars['Float']>
  propertyTax?: Maybe<Scalars['Float']>
  propertyType?: Maybe<PropertyType>
  propertyUse?: Maybe<PropertyUse>
  propertyValue?: Maybe<Scalars['Int']>
  quotingChannel?: Maybe<QuotingChannel>
  remainingBalance?: Maybe<Scalars['Float']>
  rollInEscrow?: Maybe<Scalars['Float']>
  rollInFee?: Maybe<Scalars['Int']>
  state?: Maybe<StateAbbreviation>
  subordinateLien?: Maybe<Scalars['Int']>
  subordinateLienCreditLimit?: Maybe<Scalars['Float']>
  useFeesInChannel?: Maybe<Scalars['Boolean']>
  veteranDisabled?: Maybe<Scalars['Boolean']>
  veteranType?: Maybe<VeteranType>
  waiveEscrow?: Maybe<Scalars['Boolean']>
  zip?: Maybe<Scalars['String']>
}

export type PricingScenarioInfoInput = {
  debtToIncome?: Maybe<Scalars['Int']>
  remainingBalance?: Maybe<Scalars['Int']>
}

export type ProcessorAssignment = {
  __typename?: 'ProcessorAssignment'
  applicationId?: Maybe<Scalars['Int']>
  createdAt?: Maybe<Scalars['String']>
  displayColor?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['Int']>
  processorId?: Maybe<Scalars['Int']>
}

export type ProcessorAssignmentColorInput = {
  applicationProcessorAssignmentId?: Maybe<Scalars['Int']>
  displayColor?: Maybe<Scalars['String']>
}

export type ProcessorAssignmentInput = {
  applicationId?: Maybe<Scalars['Int']>
  processorId?: Maybe<Scalars['Int']>
}

/** Processor Entity Schemas */
export type ProcessorInfo = {
  __typename?: 'ProcessorInfo'
  active?: Maybe<Scalars['Boolean']>
  activeFilesCount?: Maybe<Scalars['Int']>
  assignmentAllotment?: Maybe<Scalars['Int']>
  calendlyLink?: Maybe<Scalars['String']>
  email?: Maybe<Scalars['String']>
  employerId?: Maybe<Scalars['Int']>
  employerName?: Maybe<Scalars['String']>
  encompassId?: Maybe<Scalars['String']>
  filesLeft?: Maybe<Scalars['Int']>
  headshotLink?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['Int']>
  importantFlag?: Maybe<Scalars['Boolean']>
  latestAssignmentDate?: Maybe<Scalars['String']>
  managerId?: Maybe<Scalars['Int']>
  name?: Maybe<Scalars['String']>
  notes?: Maybe<Scalars['String']>
  phone?: Maybe<Scalars['String']>
  retoolId?: Maybe<Scalars['Int']>
  roles?: Maybe<Array<Maybe<Scalars['String']>>>
  slackUsername?: Maybe<Scalars['String']>
}

export type ProcessorInfoInput = {
  active?: Maybe<Scalars['Boolean']>
  assignmentAllotment?: Maybe<Scalars['Int']>
  besmarteeId?: Maybe<Scalars['String']>
  calendlyLink?: Maybe<Scalars['String']>
  email?: Maybe<Scalars['String']>
  employerId?: Maybe<Scalars['Int']>
  encompassId?: Maybe<Scalars['String']>
  headshotLink?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['Int']>
  importantFlag?: Maybe<Scalars['Boolean']>
  isManager?: Maybe<Scalars['Boolean']>
  managerId?: Maybe<Scalars['Int']>
  name?: Maybe<Scalars['String']>
  notes?: Maybe<Scalars['String']>
  phone?: Maybe<Scalars['String']>
  retoolId?: Maybe<Scalars['Int']>
  roles?: Maybe<Array<Maybe<Scalars['String']>>>
  slackUsername?: Maybe<Scalars['String']>
}

export type ProcessorPipelineRecord = {
  __typename?: 'ProcessorPipelineRecord'
  applicationId?: Maybe<Scalars['Int']>
  borrowerName?: Maybe<Scalars['String']>
  borrowerPhone?: Maybe<Scalars['String']>
  checklist?: Maybe<Array<Maybe<ChecklistTask>>>
  closingDate?: Maybe<Scalars['String']>
  closingLoanOfficerId?: Maybe<Scalars['Int']>
  conditionallyApprovedOn?: Maybe<Scalars['String']>
  ctcDate?: Maybe<Scalars['String']>
  cycleTimes?: Maybe<CycleTimes>
  daysToClose?: Maybe<Scalars['Int']>
  displayColor?: Maybe<Scalars['String']>
  estClosingDate?: Maybe<Scalars['String']>
  fundedDate?: Maybe<Scalars['String']>
  isReLock?: Maybe<Scalars['Boolean']>
  lastContacted?: Maybe<Scalars['String']>
  lastStatusUpdate?: Maybe<Scalars['String']>
  leadStartedDate?: Maybe<Scalars['String']>
  lender?: Maybe<Scalars['String']>
  lenderLoanId?: Maybe<Scalars['String']>
  loName?: Maybe<Scalars['String']>
  loanPurpose?: Maybe<Scalars['String']>
  lockExpiration?: Maybe<Scalars['String']>
  lockExpirationDate?: Maybe<Scalars['String']>
  lockTerm?: Maybe<Scalars['String']>
  lockedDate?: Maybe<Scalars['String']>
  managerId?: Maybe<Scalars['Int']>
  mlo?: Maybe<Scalars['String']>
  notes?: Maybe<Array<Maybe<ApplicationNotesSchema>>>
  processorName?: Maybe<Scalars['String']>
  state?: Maybe<Scalars['String']>
  status?: Maybe<Scalars['String']>
  uwDate?: Maybe<Scalars['String']>
  withdrawnDate?: Maybe<Scalars['String']>
}

/** Processor Pipeline Schemas */
export type ProcessorPipelineRequest = {
  applicationId?: Maybe<Scalars['Int']>
  dateRange?: Maybe<DateRangeInput>
  email?: Maybe<Scalars['String']>
  fetchIcebox?: Maybe<Scalars['Boolean']>
  fundedOn?: Maybe<Scalars['Boolean']>
  includeNonCompleted?: Maybe<Scalars['Boolean']>
  lenderLoanId?: Maybe<Scalars['String']>
  loManagerId?: Maybe<Scalars['Int']>
  loanOfficerId?: Maybe<Scalars['Int']>
  managerId?: Maybe<Scalars['Int']>
  name?: Maybe<Scalars['String']>
  pagination?: Maybe<Pagination>
  phone?: Maybe<Scalars['String']>
  processorId?: Maybe<Scalars['Int']>
  state?: Maybe<Scalars['String']>
  status?: Maybe<Scalars['String']>
  withdrawnOn?: Maybe<Scalars['Boolean']>
}

export type ProcessorPipelineResponse = {
  __typename?: 'ProcessorPipelineResponse'
  pagination?: Maybe<PaginationResponse>
  records?: Maybe<Array<Maybe<ProcessorPipelineRecord>>>
}

export type ProcessorTableRequest = {
  employerId?: Maybe<Scalars['Int']>
  processorId?: Maybe<Scalars['Int']>
  roles?: Maybe<Array<Maybe<Scalars['String']>>>
}

export enum ProductClass {
  Equity = 'Equity',
  HighBalance = 'HighBalance',
  HighBalanceIo = 'HighBalanceIo',
  InterestOnly = 'InterestOnly',
  Jumbo = 'Jumbo',
  JumboIo = 'JumboIo',
  NotSet = 'NotSet',
  Standard = 'Standard',
  Streamline = 'Streamline',
  StreamlineHb = 'StreamlineHb',
  StreamlineIrrrl = 'StreamlineIrrrl',
  StreamlineIrrrlHb = 'StreamlineIrrrlHb',
}

export enum ProductFamily {
  Conventional = 'Conventional',
  ConventionalLpmi = 'ConventionalLpmi',
  ConventionalReno = 'ConventionalReno',
  Fha = 'Fha',
  Fha203K = 'Fha203K',
  HarpDu = 'HarpDu',
  HarpLp = 'HarpLp',
  Heloc = 'Heloc',
  HomeAdvantage = 'HomeAdvantage',
  HomePossible = 'HomePossible',
  HomePossibleLpmi = 'HomePossibleLpmi',
  HomeReady = 'HomeReady',
  HomeReadyLpmi = 'HomeReadyLpmi',
  MyCommunity = 'MyCommunity',
  MyCommunityLpmi = 'MyCommunityLpmi',
  NotSet = 'NotSet',
  Usda = 'Usda',
  Va = 'Va',
}

export enum ProductTerm {
  A1_1 = 'A1_1',
  A2_1 = 'A2_1',
  A3_1 = 'A3_1',
  A5_1 = 'A5_1',
  A5_5 = 'A5_5',
  A5_6 = 'A5_6',
  A7_1 = 'A7_1',
  A7_6 = 'A7_6',
  A10_1 = 'A10_1',
  A10_6 = 'A10_6',
  A15_1 = 'A15_1',
  F10 = 'F10',
  F15 = 'F15',
  F20 = 'F20',
  F25 = 'F25',
  F30 = 'F30',
  F40 = 'F40',
  NotSet = 'NotSet',
}

export type Property = {
  __typename?: 'Property'
  applicationId?: Maybe<Scalars['Int']>
  city: Scalars['String']
  loanPurpose: Scalars['String']
  propertyUse: Scalars['String']
  propertyValue: Scalars['Int']
  state: Scalars['String']
  streetAddress: Scalars['String']
  zip: Scalars['String']
}

export type PropertyTaxInput = {
  propertyTaxDate: Scalars['String']
  propertyTaxEstimate: Scalars['Float']
  propertyTaxFrequency: Scalars['String']
  propertyTaxMonthlyEstimate: Scalars['Float']
  propertyTaxMonths: Scalars['Int']
}

export type PropertyTaxResponse = {
  __typename?: 'PropertyTaxResponse'
  propertyTaxDate: Scalars['String']
  propertyTaxEstimate: Scalars['Int']
  propertyTaxFrequency: Scalars['String']
  propertyTaxMonthlyEstimate: Scalars['Int']
  propertyTaxMonths: Scalars['Int']
}

export type PropertyTaxRetrieve = {
  __typename?: 'PropertyTaxRetrieve'
  applicationId?: Maybe<Scalars['String']>
  fulfillmentPartyOrderIdentifier: Scalars['String']
  respondingPartyOrderIdentifier: Scalars['String']
  submittingPartyOrderIdentifier: Scalars['String']
}

export enum PropertyTaxUse {
  Investment = 'Investment',
  Other = 'Other',
  PrimaryResidence = 'PrimaryResidence',
  SecondHome = 'SecondHome',
}

export enum PropertyType {
  Condo4OrFewerStories = 'Condo4OrFewerStories',
  Condo5OrMoreStories = 'Condo5OrMoreStories',
  Coop = 'Coop',
  Leasehold = 'Leasehold',
  MobileOrManufactured = 'MobileOrManufactured',
  Modular = 'Modular',
  MultiFamily2Units = 'MultiFamily2Units',
  MultiFamily3Units = 'MultiFamily3Units',
  MultiFamily4Units = 'MultiFamily4Units',
  NotSpecified = 'NotSpecified',
  Pud = 'Pud',
  SingleFamily = 'SingleFamily',
  Townhouse = 'Townhouse',
}

export enum PropertyTypes {
  Condo4OrFewerStories = 'Condo4OrFewerStories',
  Condo5OrMoreStories = 'Condo5OrMoreStories',
  Coop = 'Coop',
  Leasehold = 'Leasehold',
  MobileOrManufactured = 'MobileOrManufactured',
  Modular = 'Modular',
  MultiFamily2Units = 'MultiFamily2Units',
  MultiFamily3Units = 'MultiFamily3Units',
  MultiFamily4Units = 'MultiFamily4Units',
  NotSpecified = 'NotSpecified',
  Pud = 'Pud',
  SingleFamily = 'SingleFamily',
  Townhouse = 'Townhouse',
}

export enum PropertyUse {
  InvestmentOrRental = 'InvestmentOrRental',
  NotSpecified = 'NotSpecified',
  PrimaryResidence = 'PrimaryResidence',
  SecondaryOrVacation = 'SecondaryOrVacation',
}

export enum PropertyUses {
  InvestmentOrRental = 'InvestmentOrRental',
  NotSpecified = 'NotSpecified',
  PrimaryResidence = 'PrimaryResidence',
  SecondaryOrVacation = 'SecondaryOrVacation',
}

/** Define queries */
export type Query = {
  __typename?: 'Query'
  /** Get application */
  application: AdminLead
  /** Get application details */
  applicationDetails: ApplicationDetailsSchema
  /** Get the value for DTI */
  calculateDebtToIncome?: Maybe<Scalars['Float']>
  /** Compare soft and hard credit scores */
  compareCreditScores?: Maybe<CompareCreditScoresResponse>
  /** Get Contact Templates */
  contactTemplates?: Maybe<Array<Maybe<ContactTemplate>>>
  /** Get Address Auto-Complete Results */
  getAddressAutoCompleteResults?: Maybe<GoogleAutoCompleteResult>
  /** Get State Results From ZipCode */
  getAddressInfoByZipCode?: Maybe<AddressOutput>
  /** Get answer sets */
  getAnswerSets: Array<Maybe<GqlAnswerSet>>
  /** Get Answers */
  getAnswers?: Maybe<GetAnswersResult>
  /** Get application external references */
  getApplicationExternalEvents?: Maybe<
    Array<Maybe<ApplicationExternalEventSchema>>
  >
  /** Get Application Id from Guid */
  getApplicationIdFromGuid?: Maybe<Scalars['Int']>
  /** Get application loan officer action revision */
  getApplicationLoanOfficerActionRevision?: Maybe<ApplicationLoanOfficerActionRevisionResponse>
  /** Get all notes for an application */
  getApplicationNotes?: Maybe<Array<Maybe<ApplicationNotesSchema>>>
  /** Get application reporting records */
  getApplicationReportingRecords?: Maybe<
    Array<Maybe<ApplicationReportingSchema>>
  >
  /** Get application tasks */
  getApplicationTasks?: Maybe<Array<Maybe<ApplicationTask>>>
  getAutoZeroCashToClose: Array<Maybe<AutoZeroResponse>>
  /** Get Bankrate Mortgage API Rates */
  getBankrateRates?: Maybe<Array<Maybe<BankrateRate>>>
  /** Get User Location */
  getCohesionTipLocation?: Maybe<UserLocation>
  /** Get application Communication logs */
  getCommunicationLogs?: Maybe<CommunicationLogResponse>
  /** Get the value and breakdown for DTI */
  getDebtToIncomeBreakdown?: Maybe<DebtToIncomeBreakdown>
  /** Get enzo leads */
  getEnzoLeads?: Maybe<EnzoResponse>
  /** Get generated documents */
  getGeneratedDocumentsPipeline?: Maybe<GeneratedDocumentsPipelineResponse>
  /** Get Address Auto-Complete Results */
  getGeolocationResults?: Maybe<GoogleGeolocationResult>
  /** Get data for integrations page */
  getIntegrationsData?: Maybe<IntegrationDataResponse>
  /** Get latest AUS Report Data */
  getLatestAUSAnalysisData?: Maybe<AusAnalysisDataResponse>
  /** Get lead data */
  getLeadData?: Maybe<LeadData>
  /** Get pipeline leads */
  getLeadPipeline?: Maybe<LeadPipelineResponse>
  /** Get Licensed States */
  getLicensedStates?: Maybe<Array<Maybe<Scalars['String']>>>
  /** Get Loan Officer Display Information */
  getLoanOfficer?: Maybe<LoanOfficerDisplayInfo>
  /** Get application LOs SMS logs */
  getLoanOfficerSMSLogs?: Maybe<LoanOfficerSmsLogResponse>
  /** Get loan officer information for administrative purposes */
  getLoanOfficers?: Maybe<Array<Maybe<LoanOfficerAdminInfo>>>
  /** Get Loan Setup Pipeline */
  getLoanSetupPipeline?: Maybe<LoanSetupPipelineResponse>
  /** Get lock data */
  getLockData?: Maybe<LockData>
  /** Get lock desk pipeline data */
  getLockDeskPipeline?: Maybe<LockDeskPipelineResponse>
  /** Get locked or floated loans that have not been e-signed */
  getLockedOrFloatedLoansNotESigned?: Maybe<LockedOrFloatedLoansNotESignedResponse>
  /** Get Pricing */
  getPricing: ApplicationPricing
  /** Get Latest Lock or Float Pricing Scenario */
  getPricingBeforeCICRequest?: Maybe<LatestDonePricingScenario>
  /** Get processor pipeline for all loans with a withdrawn or funded date */
  getProcessorPipelineWithAllStatus?: Maybe<ProcessorPipelineResponse>
  /** Get processor pipeline for all loans with a withdrawn or funded date and with loan setup completed status */
  getProcessorPipelineWithLoanSetupCompletedStatus?: Maybe<ProcessorPipelineResponse>
  /** Get processors table for managerial view */
  getProcessorTable?: Maybe<Array<Maybe<ProcessorInfo>>>
  /** Get all Processors */
  getProcessors?: Maybe<Array<Maybe<ProcessorInfo>>>
  /** Get Questionnaire */
  getQuestionnaire?: Maybe<QuestionSet>
  /** Get Rates */
  getRates?: Maybe<Array<Maybe<Rate>>>
  /** Get Refi Home Liability */
  getRefiHomeLiability?: Maybe<RefiHomeLiabilityOutput>
  /** Get All Sage Contacts */
  getSageContacts?: Maybe<Array<Maybe<SageContactSchema>>>
  /** Get Sensitive Error Data for Applications */
  getSensitiveErrorData?: Maybe<Array<Maybe<ErrorDataSchema>>>
  /** Get tasks */
  getTasks?: Maybe<Array<Maybe<Task>>>
  /** Get Trid Days left dashboard */
  getTridDaysLeft?: Maybe<Array<Maybe<TridCountData>>>
  /** Check if address has been validated */
  hasAddressBeenValidated?: Maybe<Scalars['Boolean']>
  /** Has User Account */
  hasExistingAccount?: Maybe<Scalars['Boolean']>
  /** Get NTL hard qualification status */
  ntlQualificationStatus?: Maybe<NtlQualResult>
  /** Get Google Address Validation */
  validateAddress?: Maybe<AddressValidationResponse>
  /** Verify user based on phone number and zip code */
  verifyWithLastFourPhone?: Maybe<VerifyWithLastFourPhoneResult>
}

/** Define queries */
export type QueryApplicationArgs = {
  applicationId: Scalars['Int']
}

/** Define queries */
export type QueryApplicationDetailsArgs = {
  applicationId: Scalars['Int']
  retoolId?: Maybe<Scalars['Int']>
}

/** Define queries */
export type QueryCalculateDebtToIncomeArgs = {
  applicationId: Scalars['Int']
}

/** Define queries */
export type QueryContactTemplatesArgs = {
  retoolUserType: Scalars['String']
  type: Scalars['String']
}

/** Define queries */
export type QueryGetAddressAutoCompleteResultsArgs = {
  address: Scalars['String']
  userLatitude?: Maybe<Scalars['Float']>
  userLongitude?: Maybe<Scalars['Float']>
}

/** Define queries */
export type QueryGetAddressInfoByZipCodeArgs = {
  zipCode: Scalars['String']
}

/** Define queries */
export type QueryGetAnswerSetsArgs = {
  questionnaireSlug: Scalars['String']
}

/** Define queries */
export type QueryGetAnswersArgs = {
  applicationGuid?: Maybe<Scalars['String']>
  bankrateLeadId?: Maybe<Scalars['String']>
  directMailerOfferCode?: Maybe<Scalars['String']>
  isAuthenticated?: Maybe<Scalars['Boolean']>
}

/** Define queries */
export type QueryGetApplicationExternalEventsArgs = {
  applicationId: Scalars['Int']
}

/** Define queries */
export type QueryGetApplicationIdFromGuidArgs = {
  applicationGuid: Scalars['String']
}

/** Define queries */
export type QueryGetApplicationLoanOfficerActionRevisionArgs = {
  request: ApplicationLoanOfficerActionRevisionRequest
}

/** Define queries */
export type QueryGetApplicationNotesArgs = {
  applicationId: Scalars['Int']
}

/** Define queries */
export type QueryGetApplicationReportingRecordsArgs = {
  applicationId: Scalars['Int']
}

/** Define queries */
export type QueryGetApplicationTasksArgs = {
  applicationId: Scalars['Int']
}

/** Define queries */
export type QueryGetAutoZeroCashToCloseArgs = {
  request: AutoZeroInput
}

/** Define queries */
export type QueryGetBankrateRatesArgs = {
  request?: Maybe<RatesRequest>
}

/** Define queries */
export type QueryGetCommunicationLogsArgs = {
  applicationId: Scalars['Int']
}

/** Define queries */
export type QueryGetDebtToIncomeBreakdownArgs = {
  applicationId: Scalars['Int']
}

/** Define queries */
export type QueryGetEnzoLeadsArgs = {
  filters?: Maybe<LeadFilters>
}

/** Define queries */
export type QueryGetGeneratedDocumentsPipelineArgs = {
  request: GeneratedDocumentsPipelineRequest
}

/** Define queries */
export type QueryGetGeolocationResultsArgs = {
  address: Scalars['String']
}

/** Define queries */
export type QueryGetIntegrationsDataArgs = {
  applicationId: Scalars['Int']
}

/** Define queries */
export type QueryGetLatestAusAnalysisDataArgs = {
  applicationId: Scalars['Int']
}

/** Define queries */
export type QueryGetLeadDataArgs = {
  applicationId: Scalars['Int']
}

/** Define queries */
export type QueryGetLeadPipelineArgs = {
  leadsRequest: LeadPipelineRequest
}

/** Define queries */
export type QueryGetLicensedStatesArgs = {
  productName?: Maybe<Scalars['String']>
}

/** Define queries */
export type QueryGetLoanOfficerArgs = {
  bankrateLeadId?: Maybe<Scalars['String']>
}

/** Define queries */
export type QueryGetLoanOfficerSmsLogsArgs = {
  applicationId: Scalars['Int']
}

/** Define queries */
export type QueryGetLoanSetupPipelineArgs = {
  request?: Maybe<LoanSetupPipelineRequest>
}

/** Define queries */
export type QueryGetLockDataArgs = {
  lockFormId: Scalars['Int']
}

/** Define queries */
export type QueryGetLockDeskPipelineArgs = {
  request?: Maybe<LockDeskPipelineRequest>
}

/** Define queries */
export type QueryGetLockedOrFloatedLoansNotESignedArgs = {
  request?: Maybe<LockedOrFloatedLoansNotESignedRequest>
}

/** Define queries */
export type QueryGetPricingArgs = {
  applicationId: Scalars['Int']
}

/** Define queries */
export type QueryGetPricingBeforeCicRequestArgs = {
  applicationId: Scalars['Int']
}

/** Define queries */
export type QueryGetProcessorPipelineWithAllStatusArgs = {
  request?: Maybe<ProcessorPipelineRequest>
}

/** Define queries */
export type QueryGetProcessorPipelineWithLoanSetupCompletedStatusArgs = {
  request?: Maybe<ProcessorPipelineRequest>
}

/** Define queries */
export type QueryGetProcessorTableArgs = {
  request?: Maybe<ProcessorTableRequest>
}

/** Define queries */
export type QueryGetQuestionnaireArgs = {
  bankrateLeadId?: Maybe<Scalars['String']>
  hostName?: Maybe<Scalars['String']>
  id: Scalars['String']
}

/** Define queries */
export type QueryGetRatesArgs = {
  request?: Maybe<RatesRequest>
}

/** Define queries */
export type QueryGetSageContactsArgs = {
  occupation: Scalars['String']
}

/** Define queries */
export type QueryGetSensitiveErrorDataArgs = {
  applicationId: Scalars['Int']
}

/** Define queries */
export type QueryGetTridDaysLeftArgs = {
  request: TridCountRequest
}

/** Define queries */
export type QueryHasAddressBeenValidatedArgs = {
  applicationId: Scalars['Int']
}

/** Define queries */
export type QueryHasExistingAccountArgs = {
  email?: Maybe<Scalars['String']>
}

/** Define queries */
export type QueryNtlQualificationStatusArgs = {
  isPrequalEnabled?: Maybe<Scalars['Boolean']>
}

/** Define queries */
export type QueryValidateAddressArgs = {
  address: AddressValidationInputSchema
}

/** Define queries */
export type QueryVerifyWithLastFourPhoneArgs = {
  applicationGuid: Scalars['String']
  lastFourPhone: Scalars['String']
  zipCodeVerify: Scalars['String']
}

export type QuestionApiCallLoader = QuestionBase & {
  __typename?: 'QuestionAPICallLoader'
  apiCall?: Maybe<Scalars['String']>
  component: Scalars['String']
  content?: Maybe<Scalars['String']>
  continueOnError?: Maybe<Scalars['Boolean']>
  errorMessage?: Maybe<Scalars['String']>
  infoContent?: Maybe<Scalars['String']>
  infoTitle?: Maybe<Scalars['String']>
  infoWarning?: Maybe<Scalars['Boolean']>
  minimumTimeout?: Maybe<Scalars['String']>
  modifierClass?: Maybe<Scalars['String']>
  questionName: QuestionName
  subTitle?: Maybe<Scalars['String']>
  title?: Maybe<Scalars['String']>
  validations?: Maybe<Array<Maybe<Validations>>>
  visibleIf?: Maybe<Scalars['String']>
  visibleIfConstructor?: Maybe<Array<Maybe<VisibleIfCondition>>>
}

export type QuestionAddressAutoComplete = QuestionBase & {
  __typename?: 'QuestionAddressAutoComplete'
  component: Scalars['String']
  content?: Maybe<Scalars['String']>
  infoContent?: Maybe<Scalars['String']>
  infoTitle?: Maybe<Scalars['String']>
  infoWarning?: Maybe<Scalars['Boolean']>
  maxLength?: Maybe<Scalars['String']>
  modifierClass?: Maybe<Scalars['String']>
  placeholder?: Maybe<Scalars['String']>
  questionName: QuestionName
  subTitle?: Maybe<Scalars['String']>
  title?: Maybe<Scalars['String']>
  validations?: Maybe<Array<Maybe<Validations>>>
  visibleIf?: Maybe<Scalars['String']>
  visibleIfConstructor?: Maybe<Array<Maybe<VisibleIfCondition>>>
}

export type QuestionAddressFieldsAutoComplete = QuestionBase & {
  __typename?: 'QuestionAddressFieldsAutoComplete'
  component: Scalars['String']
  content?: Maybe<Scalars['String']>
  infoContent?: Maybe<Scalars['String']>
  infoTitle?: Maybe<Scalars['String']>
  infoWarning?: Maybe<Scalars['Boolean']>
  maxLength?: Maybe<Scalars['String']>
  modifierClass?: Maybe<Scalars['String']>
  placeholder?: Maybe<Scalars['String']>
  questionName: QuestionName
  subTitle?: Maybe<Scalars['String']>
  title?: Maybe<Scalars['String']>
  validations?: Maybe<Array<Maybe<Validations>>>
  visibleIf?: Maybe<Scalars['String']>
  visibleIfConstructor?: Maybe<Array<Maybe<VisibleIfCondition>>>
}

export type QuestionAmazonBenefits = QuestionBase & {
  __typename?: 'QuestionAmazonBenefits'
  component: Scalars['String']
  content?: Maybe<Scalars['String']>
  infoContent?: Maybe<Scalars['String']>
  infoTitle?: Maybe<Scalars['String']>
  infoWarning?: Maybe<Scalars['Boolean']>
  modifierClass?: Maybe<Scalars['String']>
  questionName: QuestionName
  subTitle?: Maybe<Scalars['String']>
  title?: Maybe<Scalars['String']>
  validations?: Maybe<Array<Maybe<Validations>>>
  visibleIf?: Maybe<Scalars['String']>
  visibleIfConstructor?: Maybe<Array<Maybe<VisibleIfCondition>>>
}

export type QuestionBase = {
  component: Scalars['String']
  content?: Maybe<Scalars['String']>
  infoContent?: Maybe<Scalars['String']>
  infoTitle?: Maybe<Scalars['String']>
  infoWarning?: Maybe<Scalars['Boolean']>
  modifierClass?: Maybe<Scalars['String']>
  questionName: QuestionName
  subTitle?: Maybe<Scalars['String']>
  title?: Maybe<Scalars['String']>
  validations?: Maybe<Array<Maybe<Validations>>>
  visibleIf?: Maybe<Scalars['String']>
  visibleIfConstructor?: Maybe<Array<Maybe<VisibleIfCondition>>>
}

export type QuestionBinaryButton = QuestionBase & {
  __typename?: 'QuestionBinaryButton'
  component: Scalars['String']
  content?: Maybe<Scalars['String']>
  defaultYes?: Maybe<Scalars['Boolean']>
  infoContent?: Maybe<Scalars['String']>
  infoTitle?: Maybe<Scalars['String']>
  infoWarning?: Maybe<Scalars['Boolean']>
  modifierClass?: Maybe<Scalars['String']>
  questionName: QuestionName
  subTitle?: Maybe<Scalars['String']>
  title?: Maybe<Scalars['String']>
  validations?: Maybe<Array<Maybe<Validations>>>
  visibleIf?: Maybe<Scalars['String']>
  visibleIfConstructor?: Maybe<Array<Maybe<VisibleIfCondition>>>
}

export type QuestionCheckbox = QuestionBase & {
  __typename?: 'QuestionCheckbox'
  checked?: Maybe<Scalars['String']>
  component: Scalars['String']
  content?: Maybe<Scalars['String']>
  infoContent?: Maybe<Scalars['String']>
  infoTitle?: Maybe<Scalars['String']>
  infoWarning?: Maybe<Scalars['Boolean']>
  modifierClass?: Maybe<Scalars['String']>
  questionName: QuestionName
  subTitle?: Maybe<Scalars['String']>
  title?: Maybe<Scalars['String']>
  validations?: Maybe<Array<Maybe<Validations>>>
  visibleIf?: Maybe<Scalars['String']>
  visibleIfConstructor?: Maybe<Array<Maybe<VisibleIfCondition>>>
}

export type QuestionCheckboxMulti = QuestionBase & {
  __typename?: 'QuestionCheckboxMulti'
  bottomContent?: Maybe<Scalars['String']>
  component: Scalars['String']
  content?: Maybe<Scalars['String']>
  defaultOption?: Maybe<Scalars['String']>
  infoContent?: Maybe<Scalars['String']>
  infoTitle?: Maybe<Scalars['String']>
  infoWarning?: Maybe<Scalars['Boolean']>
  modifierClass?: Maybe<Scalars['String']>
  multiAnswerEnabled?: Maybe<Scalars['Boolean']>
  options?: Maybe<Array<Maybe<Option>>>
  questionName: QuestionName
  subTitle?: Maybe<Scalars['String']>
  title?: Maybe<Scalars['String']>
  validations?: Maybe<Array<Maybe<Validations>>>
  visibleIf?: Maybe<Scalars['String']>
  visibleIfConstructor?: Maybe<Array<Maybe<VisibleIfCondition>>>
}

export type QuestionCompareLeRateSelection = QuestionBase & {
  __typename?: 'QuestionCompareLERateSelection'
  component: Scalars['String']
  content?: Maybe<Scalars['String']>
  infoContent?: Maybe<Scalars['String']>
  infoTitle?: Maybe<Scalars['String']>
  infoWarning?: Maybe<Scalars['Boolean']>
  modifierClass?: Maybe<Scalars['String']>
  questionName: QuestionName
  redirectToSlug?: Maybe<Scalars['String']>
  subTitle?: Maybe<Scalars['String']>
  title?: Maybe<Scalars['String']>
  validations?: Maybe<Array<Maybe<Validations>>>
  visibleIf?: Maybe<Scalars['String']>
  visibleIfConstructor?: Maybe<Array<Maybe<VisibleIfCondition>>>
}

export type QuestionCreateAccount = QuestionBase & {
  __typename?: 'QuestionCreateAccount'
  component: Scalars['String']
  content?: Maybe<Scalars['String']>
  infoContent?: Maybe<Scalars['String']>
  infoTitle?: Maybe<Scalars['String']>
  infoWarning?: Maybe<Scalars['Boolean']>
  modifierClass?: Maybe<Scalars['String']>
  questionName: QuestionName
  questions?: Maybe<Array<Maybe<Questions>>>
  subTitle?: Maybe<Scalars['String']>
  title?: Maybe<Scalars['String']>
  validations?: Maybe<Array<Maybe<Validations>>>
  visibleIf?: Maybe<Scalars['String']>
  visibleIfConstructor?: Maybe<Array<Maybe<VisibleIfCondition>>>
}

export type QuestionGroup = QuestionBase & {
  __typename?: 'QuestionGroup'
  autoRepeatValidationKey?: Maybe<Scalars['String']>
  component: Scalars['String']
  content?: Maybe<Scalars['String']>
  infoContent?: Maybe<Scalars['String']>
  infoTitle?: Maybe<Scalars['String']>
  infoWarning?: Maybe<Scalars['Boolean']>
  modifierClass?: Maybe<Scalars['String']>
  questionName: QuestionName
  questions?: Maybe<Array<Maybe<Questions>>>
  subTitle?: Maybe<Scalars['String']>
  textForDeleteButton?: Maybe<Scalars['String']>
  textForRepeatButton?: Maybe<Scalars['String']>
  title?: Maybe<Scalars['String']>
  titleForRepeatButton?: Maybe<Scalars['String']>
  validations?: Maybe<Array<Maybe<Validations>>>
  visibleIf?: Maybe<Scalars['String']>
  visibleIfConstructor?: Maybe<Array<Maybe<VisibleIfCondition>>>
}

export type QuestionHidden = QuestionBase & {
  __typename?: 'QuestionHidden'
  component: Scalars['String']
  content?: Maybe<Scalars['String']>
  infoContent?: Maybe<Scalars['String']>
  infoTitle?: Maybe<Scalars['String']>
  infoWarning?: Maybe<Scalars['Boolean']>
  modifierClass?: Maybe<Scalars['String']>
  questionName: QuestionName
  subTitle?: Maybe<Scalars['String']>
  title?: Maybe<Scalars['String']>
  validations?: Maybe<Array<Maybe<Validations>>>
  value: Scalars['String']
  visibleIf?: Maybe<Scalars['String']>
  visibleIfConstructor?: Maybe<Array<Maybe<VisibleIfCondition>>>
}

export type QuestionIncome = QuestionBase & {
  __typename?: 'QuestionIncome'
  component: Scalars['String']
  content?: Maybe<Scalars['String']>
  infoContent?: Maybe<Scalars['String']>
  infoTitle?: Maybe<Scalars['String']>
  infoWarning?: Maybe<Scalars['Boolean']>
  modifierClass?: Maybe<Scalars['String']>
  options?: Maybe<Array<Maybe<Option>>>
  questionName: QuestionName
  subTitle?: Maybe<Scalars['String']>
  title?: Maybe<Scalars['String']>
  validations?: Maybe<Array<Maybe<Validations>>>
  visibleIf?: Maybe<Scalars['String']>
  visibleIfConstructor?: Maybe<Array<Maybe<VisibleIfCondition>>>
}

export type QuestionInfoAccordion = QuestionBase & {
  __typename?: 'QuestionInfoAccordion'
  accordionContent?: Maybe<Scalars['String']>
  component: Scalars['String']
  content?: Maybe<Scalars['String']>
  infoContent?: Maybe<Scalars['String']>
  infoTitle?: Maybe<Scalars['String']>
  infoWarning?: Maybe<Scalars['Boolean']>
  modifierClass?: Maybe<Scalars['String']>
  questionName: QuestionName
  subTitle?: Maybe<Scalars['String']>
  title?: Maybe<Scalars['String']>
  validations?: Maybe<Array<Maybe<Validations>>>
  visibleIf?: Maybe<Scalars['String']>
  visibleIfConstructor?: Maybe<Array<Maybe<VisibleIfCondition>>>
}

export type QuestionInput = QuestionBase & {
  __typename?: 'QuestionInput'
  component: Scalars['String']
  content?: Maybe<Scalars['String']>
  infoContent?: Maybe<Scalars['String']>
  infoTitle?: Maybe<Scalars['String']>
  infoWarning?: Maybe<Scalars['Boolean']>
  inputType?: Maybe<Scalars['String']>
  maxLength?: Maybe<Scalars['String']>
  modifierClass?: Maybe<Scalars['String']>
  placeholder?: Maybe<Scalars['String']>
  questionName: QuestionName
  subTitle?: Maybe<Scalars['String']>
  title?: Maybe<Scalars['String']>
  validations?: Maybe<Array<Maybe<Validations>>>
  visibleIf?: Maybe<Scalars['String']>
  visibleIfConstructor?: Maybe<Array<Maybe<VisibleIfCondition>>>
}

export type QuestionMultiButton = QuestionBase & {
  __typename?: 'QuestionMultiButton'
  component: Scalars['String']
  content?: Maybe<Scalars['String']>
  infoContent?: Maybe<Scalars['String']>
  infoTitle?: Maybe<Scalars['String']>
  infoWarning?: Maybe<Scalars['Boolean']>
  modifierClass?: Maybe<Scalars['String']>
  options?: Maybe<Array<Maybe<Option>>>
  questionName: QuestionName
  subTitle?: Maybe<Scalars['String']>
  title?: Maybe<Scalars['String']>
  validations?: Maybe<Array<Maybe<Validations>>>
  visibleIf?: Maybe<Scalars['String']>
  visibleIfConstructor?: Maybe<Array<Maybe<VisibleIfCondition>>>
}

export type QuestionMultiSelectButton = QuestionBase & {
  __typename?: 'QuestionMultiSelectButton'
  component: Scalars['String']
  content?: Maybe<Scalars['String']>
  infoContent?: Maybe<Scalars['String']>
  infoTitle?: Maybe<Scalars['String']>
  infoWarning?: Maybe<Scalars['Boolean']>
  modifierClass?: Maybe<Scalars['String']>
  options?: Maybe<Array<Maybe<Option>>>
  questionName: QuestionName
  subTitle?: Maybe<Scalars['String']>
  title?: Maybe<Scalars['String']>
  validations?: Maybe<Array<Maybe<Validations>>>
  visibleIf?: Maybe<Scalars['String']>
  visibleIfConstructor?: Maybe<Array<Maybe<VisibleIfCondition>>>
}

export type QuestionNtlRateSelection = QuestionBase & {
  __typename?: 'QuestionNTLRateSelection'
  component: Scalars['String']
  content?: Maybe<Scalars['String']>
  infoContent?: Maybe<Scalars['String']>
  infoTitle?: Maybe<Scalars['String']>
  infoWarning?: Maybe<Scalars['Boolean']>
  modifierClass?: Maybe<Scalars['String']>
  questionName: QuestionName
  subTitle?: Maybe<Scalars['String']>
  title?: Maybe<Scalars['String']>
  validations?: Maybe<Array<Maybe<Validations>>>
  visibleIf?: Maybe<Scalars['String']>
  visibleIfConstructor?: Maybe<Array<Maybe<VisibleIfCondition>>>
}

export type QuestionName = {
  __typename?: 'QuestionName'
  items?: Maybe<Array<Maybe<Scalars['String']>>>
  name: Scalars['String']
  plugin?: Maybe<Scalars['String']>
}

export type QuestionPlaidAssets = QuestionBase & {
  __typename?: 'QuestionPlaidAssets'
  component: Scalars['String']
  content?: Maybe<Scalars['String']>
  infoContent?: Maybe<Scalars['String']>
  infoTitle?: Maybe<Scalars['String']>
  infoWarning?: Maybe<Scalars['Boolean']>
  modifierClass?: Maybe<Scalars['String']>
  questionName: QuestionName
  subTitle?: Maybe<Scalars['String']>
  title?: Maybe<Scalars['String']>
  validations?: Maybe<Array<Maybe<Validations>>>
  visibleIf?: Maybe<Scalars['String']>
  visibleIfConstructor?: Maybe<Array<Maybe<VisibleIfCondition>>>
}

export type QuestionRadio = QuestionBase & {
  __typename?: 'QuestionRadio'
  component: Scalars['String']
  content?: Maybe<Scalars['String']>
  defaultValue?: Maybe<Scalars['String']>
  infoContent?: Maybe<Scalars['String']>
  infoTitle?: Maybe<Scalars['String']>
  infoWarning?: Maybe<Scalars['Boolean']>
  modifierClass?: Maybe<Scalars['String']>
  options?: Maybe<Array<Maybe<Option>>>
  questionName: QuestionName
  subTitle?: Maybe<Scalars['String']>
  title?: Maybe<Scalars['String']>
  validations?: Maybe<Array<Maybe<Validations>>>
  visibleIf?: Maybe<Scalars['String']>
  visibleIfConstructor?: Maybe<Array<Maybe<VisibleIfCondition>>>
}

export type QuestionRateSelection = QuestionBase & {
  __typename?: 'QuestionRateSelection'
  component: Scalars['String']
  content?: Maybe<Scalars['String']>
  infoContent?: Maybe<Scalars['String']>
  infoTitle?: Maybe<Scalars['String']>
  infoWarning?: Maybe<Scalars['Boolean']>
  modifierClass?: Maybe<Scalars['String']>
  questionName: QuestionName
  subTitle?: Maybe<Scalars['String']>
  title?: Maybe<Scalars['String']>
  validations?: Maybe<Array<Maybe<Validations>>>
  visibleIf?: Maybe<Scalars['String']>
  visibleIfConstructor?: Maybe<Array<Maybe<VisibleIfCondition>>>
}

export type QuestionSsn = QuestionBase & {
  __typename?: 'QuestionSSN'
  component: Scalars['String']
  content?: Maybe<Scalars['String']>
  infoContent?: Maybe<Scalars['String']>
  infoTitle?: Maybe<Scalars['String']>
  infoWarning?: Maybe<Scalars['Boolean']>
  modifierClass?: Maybe<Scalars['String']>
  questionName: QuestionName
  subTitle?: Maybe<Scalars['String']>
  title?: Maybe<Scalars['String']>
  validations?: Maybe<Array<Maybe<Validations>>>
  visibleIf?: Maybe<Scalars['String']>
  visibleIfConstructor?: Maybe<Array<Maybe<VisibleIfCondition>>>
}

export type QuestionSelect = QuestionBase & {
  __typename?: 'QuestionSelect'
  component: Scalars['String']
  content?: Maybe<Scalars['String']>
  infoContent?: Maybe<Scalars['String']>
  infoTitle?: Maybe<Scalars['String']>
  infoWarning?: Maybe<Scalars['Boolean']>
  modifierClass?: Maybe<Scalars['String']>
  options?: Maybe<Array<Maybe<Option>>>
  questionName: QuestionName
  subTitle?: Maybe<Scalars['String']>
  title?: Maybe<Scalars['String']>
  validations?: Maybe<Array<Maybe<Validations>>>
  visibleIf?: Maybe<Scalars['String']>
  visibleIfConstructor?: Maybe<Array<Maybe<VisibleIfCondition>>>
}

export type QuestionSet = {
  __typename?: 'QuestionSet'
  flowId?: Maybe<Scalars['String']>
  otherPages?: Maybe<Array<Maybe<OtherPage>>>
  pages?: Maybe<Array<Maybe<Page>>>
  title: Scalars['String']
}

export type QuestionStateSelect = QuestionBase & {
  __typename?: 'QuestionStateSelect'
  component: Scalars['String']
  content?: Maybe<Scalars['String']>
  infoContent?: Maybe<Scalars['String']>
  infoTitle?: Maybe<Scalars['String']>
  infoWarning?: Maybe<Scalars['Boolean']>
  modifierClass?: Maybe<Scalars['String']>
  questionName: QuestionName
  subTitle?: Maybe<Scalars['String']>
  title?: Maybe<Scalars['String']>
  validations?: Maybe<Array<Maybe<Validations>>>
  visibleIf?: Maybe<Scalars['String']>
  visibleIfConstructor?: Maybe<Array<Maybe<VisibleIfCondition>>>
}

export type QuestionToggle = QuestionBase & {
  __typename?: 'QuestionToggle'
  component: Scalars['String']
  content?: Maybe<Scalars['String']>
  infoContent?: Maybe<Scalars['String']>
  infoTitle?: Maybe<Scalars['String']>
  infoWarning?: Maybe<Scalars['Boolean']>
  modifierClass?: Maybe<Scalars['String']>
  questionName: QuestionName
  subTitle?: Maybe<Scalars['String']>
  title?: Maybe<Scalars['String']>
  toggled: Scalars['String']
  validations?: Maybe<Array<Maybe<Validations>>>
  visibleIf?: Maybe<Scalars['String']>
  visibleIfConstructor?: Maybe<Array<Maybe<VisibleIfCondition>>>
}

export type QuestionUploader = QuestionBase & {
  __typename?: 'QuestionUploader'
  component: Scalars['String']
  content?: Maybe<Scalars['String']>
  documentType?: Maybe<Scalars['String']>
  infoContent?: Maybe<Scalars['String']>
  infoTitle?: Maybe<Scalars['String']>
  infoWarning?: Maybe<Scalars['Boolean']>
  modifierClass?: Maybe<Scalars['String']>
  questionName: QuestionName
  subTitle?: Maybe<Scalars['String']>
  title?: Maybe<Scalars['String']>
  topSubHeader?: Maybe<Scalars['String']>
  uploadButtonText?: Maybe<Scalars['String']>
  uploadErrorText?: Maybe<Scalars['String']>
  validations?: Maybe<Array<Maybe<Validations>>>
  visibleIf?: Maybe<Scalars['String']>
  visibleIfConstructor?: Maybe<Array<Maybe<VisibleIfCondition>>>
}

export type Questions =
  | QuestionApiCallLoader
  | QuestionAddressAutoComplete
  | QuestionAddressFieldsAutoComplete
  | QuestionAmazonBenefits
  | QuestionBinaryButton
  | QuestionCheckbox
  | QuestionCheckboxMulti
  | QuestionCompareLeRateSelection
  | QuestionCreateAccount
  | QuestionGroup
  | QuestionHidden
  | QuestionIncome
  | QuestionInfoAccordion
  | QuestionInput
  | QuestionMultiButton
  | QuestionMultiSelectButton
  | QuestionNtlRateSelection
  | QuestionPlaidAssets
  | QuestionRadio
  | QuestionRateSelection
  | QuestionSsn
  | QuestionSelect
  | QuestionStateSelect
  | QuestionToggle
  | QuestionUploader

export enum QuotingChannel {
  Bankrate = 'Bankrate',
  CampaignRateTable = 'CampaignRateTable',
  Costco = 'Costco',
  CostcoExecutive = 'CostcoExecutive',
  Default = 'Default',
  FreeRateUpdate = 'FreeRateUpdate',
  LendingTreeLoanExplorer = 'LendingTreeLoanExplorer',
  LoanPricerOnly = 'LoanPricerOnly',
  LoanTek = 'LoanTek',
  NotChannelSpecific = 'NotChannelSpecific',
  NotSpecified = 'NotSpecified',
  Test = 'Test',
  TestValidation = 'TestValidation',
  Utility1 = 'Utility1',
  Utility2 = 'Utility2',
  Utility3 = 'Utility3',
  Utility4 = 'Utility4',
  Utility5 = 'Utility5',
  Zillow = 'Zillow',
}

export type Rate = {
  __typename?: 'Rate'
  adjustments?: Maybe<Array<Maybe<Adjustment>>>
  adjustmentsTotal?: Maybe<Scalars['Float']>
  apr?: Maybe<Scalars['Float']>
  armDetails?: Maybe<ArmDetails>
  basePrice?: Maybe<Scalars['Float']>
  borrowerName?: Maybe<Scalars['String']>
  cashOut?: Maybe<Scalars['Int']>
  combinedLoanToValue?: Maybe<Scalars['Float']>
  costOfLoan?: Maybe<Scalars['Float']>
  costOfLoanYears?: Maybe<Scalars['Int']>
  creditScore?: Maybe<Scalars['Int']>
  discountOrCreditAmount?: Maybe<Scalars['Float']>
  discountPoints?: Maybe<Scalars['Float']>
  discountPointsAsDollarAmount?: Maybe<Scalars['Float']>
  escrowFees?: Maybe<Scalars['Float']>
  family?: Maybe<Scalars['String']>
  fees?: Maybe<Array<Maybe<Fees>>>
  fewerUpfrontCosts?: Maybe<Scalars['Float']>
  fhaMipMonthlyPayment?: Maybe<Scalars['Float']>
  finalPrice?: Maybe<Scalars['Float']>
  fundingFee?: Maybe<Scalars['Float']>
  id?: Maybe<Scalars['String']>
  insurancePremium?: Maybe<Scalars['Float']>
  isRollInEscrow?: Maybe<Scalars['Boolean']>
  lenderName?: Maybe<Scalars['String']>
  loanAmount?: Maybe<Scalars['Float']>
  loanEstimateSections?: Maybe<LoanEstimateSections>
  loanOfficer?: Maybe<LoanOfficerDisplayInfo>
  loanProgramName?: Maybe<Scalars['String']>
  loanPurpose?: Maybe<Scalars['String']>
  loanTerm?: Maybe<Scalars['Int']>
  loanToValue?: Maybe<Scalars['Float']>
  lockId?: Maybe<Scalars['String']>
  lockPeriod?: Maybe<Scalars['Int']>
  matchedDisallowPricingRuleDescription?: Maybe<Scalars['String']>
  monthlyEscrowFees?: Maybe<Scalars['Float']>
  monthlyInsurance?: Maybe<Scalars['Float']>
  monthlyPayment?: Maybe<Scalars['Float']>
  monthlyPrivateMortgageInsurance?: Maybe<Scalars['Float']>
  monthlyPropertyTax?: Maybe<Scalars['Float']>
  mortgageInsurancePremium?: Maybe<MortgageInsurancePremium>
  originationPoints?: Maybe<Scalars['Float']>
  points?: Maybe<Scalars['Int']>
  principleAndInterestPayment?: Maybe<Scalars['Float']>
  privateMortgageInsuranceQuote?: Maybe<PmiQuote>
  productClass?: Maybe<ProductClass>
  productCode?: Maybe<Scalars['String']>
  propertyValue?: Maybe<Scalars['Float']>
  quoteType?: Maybe<Scalars['String']>
  rate?: Maybe<Scalars['Float']>
  rateSheetPrice?: Maybe<Scalars['Float']>
  rollInFees?: Maybe<Scalars['Int']>
  silkFees?: Maybe<Array<Maybe<Fees>>>
  startingAdjustedPrice?: Maybe<Scalars['Float']>
  startingAdjustedRate?: Maybe<Scalars['Float']>
  stopEncountered?: Maybe<Scalars['Boolean']>
  subordinateLienAmount?: Maybe<Scalars['Int']>
  term?: Maybe<Scalars['String']>
  totalLenderFees?: Maybe<Scalars['Float']>
  type?: Maybe<Scalars['String']>
  undiscountedRate?: Maybe<Scalars['Float']>
}

export enum RateExecutionMethod {
  ByRate = 'ByRate',
  JustRemoveStops = 'JustRemoveStops',
}

export type RateInput = {
  adjustments?: Maybe<Array<Maybe<AdjustmentInput>>>
  adjustmentsTotal?: Maybe<Scalars['Float']>
  apr?: Maybe<Scalars['Float']>
  armDetails?: Maybe<ArmDetailsInput>
  basePrice?: Maybe<Scalars['Float']>
  borrowerName?: Maybe<Scalars['String']>
  cashOut?: Maybe<Scalars['Int']>
  combinedLoanToValue?: Maybe<Scalars['Float']>
  costOfLoan?: Maybe<Scalars['Float']>
  costOfLoanYears?: Maybe<Scalars['Int']>
  discountOrCreditAmount?: Maybe<Scalars['Float']>
  discountPoints?: Maybe<Scalars['Float']>
  discountPointsAsDollarAmount?: Maybe<Scalars['Float']>
  escrowFees?: Maybe<Scalars['Float']>
  family?: Maybe<Scalars['String']>
  fees?: Maybe<Array<Maybe<InputFees>>>
  fewerUpfrontCosts?: Maybe<Scalars['Float']>
  fhaMipMonthlyPayment?: Maybe<Scalars['Float']>
  finalPrice?: Maybe<Scalars['Float']>
  fundingFee?: Maybe<Scalars['Float']>
  id?: Maybe<Scalars['String']>
  isRollInEscrow?: Maybe<Scalars['Boolean']>
  lenderName?: Maybe<Scalars['String']>
  loanAmount?: Maybe<Scalars['Float']>
  loanEstimateSections?: Maybe<LoanEstimateSectionsInput>
  loanOfficer?: Maybe<LoanOfficerInput>
  loanProgramName?: Maybe<Scalars['String']>
  loanPurpose?: Maybe<Scalars['String']>
  loanTerm?: Maybe<Scalars['Int']>
  loanToValue?: Maybe<Scalars['Float']>
  lockId?: Maybe<Scalars['String']>
  lockPeriod?: Maybe<Scalars['Int']>
  matchedDisallowPricingRuleDescription?: Maybe<Scalars['String']>
  monthlyEscrowFees?: Maybe<Scalars['Float']>
  monthlyInsurance?: Maybe<Scalars['Float']>
  monthlyPayment?: Maybe<Scalars['Float']>
  monthlyPrivateMortgageInsurance?: Maybe<Scalars['Float']>
  monthlyPropertyTax?: Maybe<Scalars['Float']>
  mortgageInsurancePremium?: Maybe<MortgageInsurancePremiumInput>
  originationPoints?: Maybe<Scalars['Float']>
  points?: Maybe<Scalars['Float']>
  principleAndInterestPayment?: Maybe<Scalars['Float']>
  privateMortgageInsuranceQuote?: Maybe<PmiQuoteInput>
  productClass?: Maybe<ProductClass>
  productCode?: Maybe<Scalars['String']>
  propertyValue?: Maybe<Scalars['Float']>
  quoteType?: Maybe<Scalars['String']>
  rate?: Maybe<Scalars['Float']>
  rateSheetPrice?: Maybe<Scalars['Float']>
  rollInFees?: Maybe<Scalars['Int']>
  silkFees?: Maybe<Array<Maybe<InputFees>>>
  startingAdjustedPrice?: Maybe<Scalars['Float']>
  startingAdjustedRate?: Maybe<Scalars['Float']>
  stopEncountered?: Maybe<Scalars['Boolean']>
  subordinateLienAmount?: Maybe<Scalars['Int']>
  term?: Maybe<Scalars['String']>
  totalLenderFees?: Maybe<Scalars['Float']>
  type?: Maybe<Scalars['String']>
  undiscountedRate?: Maybe<Scalars['Float']>
}

export type RateSelectPage = {
  __typename?: 'RateSelectPage'
  component: Scalars['String']
  variations: Array<RateSelectPageVariation>
}

export type RateSelectPageVariation = {
  __typename?: 'RateSelectPageVariation'
  cta: Scalars['String']
  subTitle: Scalars['String']
  title: Scalars['String']
  variation: Scalars['String']
}

export type RateSelectionSchema = {
  __typename?: 'RateSelectionSchema'
  createdAt?: Maybe<Scalars['String']>
  rate?: Maybe<Scalars['Float']>
  rateFamily?: Maybe<Scalars['String']>
  rateTerm?: Maybe<Scalars['Int']>
  rateType?: Maybe<Scalars['String']>
}

export type RatesRequest = {
  address?: Maybe<Scalars['String']>
  amcProduct?: Maybe<Scalars['String']>
  applicationId?: Maybe<Scalars['Int']>
  borrowerName?: Maybe<Scalars['String']>
  cashOut?: Maybe<Scalars['Int']>
  city?: Maybe<Scalars['String']>
  closingFeeAnswers?: Maybe<Array<Maybe<ClosingFeeAnswer>>>
  closingFeeClient?: Maybe<Scalars['String']>
  combinedLoanToValue?: Maybe<Scalars['Float']>
  costOfLoanYears?: Maybe<Scalars['Int']>
  county?: Maybe<Scalars['String']>
  creditScore?: Maybe<Scalars['Int']>
  debtToIncome?: Maybe<Scalars['Int']>
  escrowOptions?: Maybe<Scalars['String']>
  excludeForcedFeeQuotes?: Maybe<Scalars['Boolean']>
  executionMethod?: Maybe<RateExecutionMethod>
  financeUpFrontMortgageInsurancePremium?: Maybe<Scalars['Boolean']>
  fipsCode?: Maybe<Scalars['String']>
  firstTimeBuyer?: Maybe<Scalars['Boolean']>
  homeownersInsuranceMonths?: Maybe<Scalars['Int']>
  ignoreStops?: Maybe<Scalars['Boolean']>
  includeZeroAmountAdjustments?: Maybe<Scalars['Boolean']>
  isLockQuote?: Maybe<Scalars['Boolean']>
  leadId?: Maybe<Scalars['Int']>
  loanAmount?: Maybe<Scalars['Float']>
  loanOfficerId?: Maybe<Scalars['Int']>
  loanPurpose: LoanPurpose
  loanTerm?: Maybe<Array<Maybe<ProductTerm>>>
  loanToValue?: Maybe<Scalars['Float']>
  lockId?: Maybe<Scalars['String']>
  lockPeriod?: Maybe<Scalars['Int']>
  monthlyInsurance?: Maybe<Scalars['Float']>
  monthlyPropertyTax?: Maybe<Scalars['Float']>
  newConstruction?: Maybe<Scalars['Boolean']>
  points?: Maybe<Array<Maybe<PointEnum>>>
  productFamily?: Maybe<Array<Maybe<ProductFamily>>>
  propertyDownPayment?: Maybe<Scalars['Float']>
  propertyPurchasePrice?: Maybe<Scalars['Float']>
  propertyTaxEstimate?: Maybe<PropertyTaxInput>
  propertyType?: Maybe<PropertyType>
  propertyUse?: Maybe<PropertyUse>
  propertyValue?: Maybe<Scalars['Int']>
  quotingChannel?: Maybe<QuotingChannel>
  recordingOfficeId?: Maybe<Scalars['String']>
  remainingBalance?: Maybe<Scalars['Float']>
  rollInEscrow?: Maybe<Scalars['Float']>
  rollInFees?: Maybe<Scalars['Float']>
  selfEmployed?: Maybe<Scalars['Boolean']>
  skipSilkFees?: Maybe<Scalars['Boolean']>
  source?: Maybe<RatesRequestSource>
  state?: Maybe<StateAbbreviation>
  subJurisdiction?: Maybe<Scalars['String']>
  subordinateLienAmount?: Maybe<Scalars['Float']>
  subordinateLienCreditLimit?: Maybe<Scalars['Float']>
  veteran?: Maybe<Veteran>
  waiveEscrow?: Maybe<Scalars['Boolean']>
  willRollInEscrow?: Maybe<Scalars['Boolean']>
  willRollInFees?: Maybe<Scalars['Boolean']>
  zipCode: Scalars['String']
}

export enum RatesRequestSource {
  Ello = 'ELLO',
  Full1003 = 'FULL1003',
  Loanmatch = 'LOANMATCH',
  Retool = 'RETOOL',
}

export type RealEstateOwnedAnswer = {
  apt?: Maybe<Scalars['String']>
  city?: Maybe<Scalars['String']>
  numberOfUnits?: Maybe<Scalars['Int']>
  propertyOwners?: Maybe<Scalars['String']>
  state?: Maybe<Scalars['String']>
  street?: Maybe<Scalars['String']>
  zipCode?: Maybe<Scalars['String']>
}

export type RealEstateOwnedOutput = {
  __typename?: 'RealEstateOwnedOutput'
  apt?: Maybe<Scalars['String']>
  city?: Maybe<Scalars['String']>
  propertyOwners?: Maybe<Scalars['String']>
  state?: Maybe<Scalars['String']>
  street?: Maybe<Scalars['String']>
  zipCode?: Maybe<Scalars['String']>
}

export type RealEstateOwnedSchema = {
  __typename?: 'RealEstateOwnedSchema'
  apt?: Maybe<Scalars['String']>
  city?: Maybe<Scalars['String']>
  createdAt?: Maybe<Scalars['String']>
  currentOccupancy?: Maybe<Scalars['String']>
  deletedAt?: Maybe<Scalars['String']>
  dtiCalculations?: Maybe<DtiCalculationsSchema>
  expenses?: Maybe<Scalars['Float']>
  grossRentalIncome?: Maybe<Scalars['Float']>
  hoaDuesMonthly?: Maybe<Scalars['Float']>
  id: Scalars['Int']
  intendedOccupancy?: Maybe<Scalars['String']>
  intendedType?: Maybe<Scalars['String']>
  isCurrent?: Maybe<Scalars['Boolean']>
  isSubject?: Maybe<Scalars['Boolean']>
  liabilities?: Maybe<Array<Maybe<LiabilityRevisionSchema>>>
  maintenanceExpensesMonthly?: Maybe<Scalars['Float']>
  netProceeds?: Maybe<Scalars['Float']>
  netRentalIncome?: Maybe<Scalars['Float']>
  numberOfUnits?: Maybe<Scalars['Int']>
  occupancyRate?: Maybe<Scalars['Float']>
  ownerId?: Maybe<Scalars['Int']>
  propertyInsuranceMonthly?: Maybe<Scalars['Float']>
  propertyOwners?: Maybe<Scalars['String']>
  propertyTaxesMonthly?: Maybe<Scalars['Float']>
  propertyType?: Maybe<Scalars['String']>
  propertyValue?: Maybe<Scalars['Float']>
  state?: Maybe<Scalars['String']>
  status?: Maybe<Scalars['String']>
  street?: Maybe<Scalars['String']>
  type?: Maybe<Scalars['String']>
  updatedAt?: Maybe<Scalars['String']>
  zip?: Maybe<Scalars['String']>
  zipCode?: Maybe<Scalars['String']>
}

export type ReassignLoanOfficerResponse = {
  __typename?: 'ReassignLoanOfficerResponse'
  assignmentId?: Maybe<Scalars['Int']>
}

export type RecordId = {
  __typename?: 'RecordId'
  id?: Maybe<Scalars['Int']>
}

export type RedirectInterstitial = {
  __typename?: 'RedirectInterstitial'
  body?: Maybe<Scalars['String']>
  component: Scalars['String']
  time?: Maybe<Scalars['Int']>
  title?: Maybe<Scalars['String']>
}

export type RedirectUrl = {
  __typename?: 'RedirectUrl'
  redirectUrl: Scalars['String']
}

export type RefiHomeLiabilityOutput = {
  __typename?: 'RefiHomeLiabilityOutput'
  monthly_expense?: Maybe<Scalars['Float']>
  remaining_term_months?: Maybe<Scalars['Int']>
  total_term_months?: Maybe<Scalars['Int']>
  unpaid_balance?: Maybe<Scalars['Float']>
}

export type RelatedApplications = {
  __typename?: 'RelatedApplications'
  id?: Maybe<Scalars['Int']>
  type?: Maybe<Scalars['String']>
}

export type ReplicateApplicationBorrowerIds = {
  applicationId?: Maybe<Scalars['Int']>
  coborrowerId?: Maybe<Scalars['Int']>
}

export type ReplicateApplicationOptions = {
  coborrowerId?: Maybe<ReplicateApplicationBorrowerIds>
  loId?: Maybe<Scalars['Int']>
  primaryBorrowerId?: Maybe<ReplicateApplicationBorrowerIds>
  reappReason?: Maybe<Scalars['String']>
}

export type SmsFrom = {
  __typename?: 'SMSFrom'
  extensionNumber?: Maybe<Scalars['String']>
  location?: Maybe<Scalars['String']>
  name?: Maybe<Scalars['String']>
  phoneNumber?: Maybe<Scalars['String']>
}

export type SmsLog = {
  __typename?: 'SMSLog'
  conversationId?: Maybe<Scalars['String']>
  creationTime?: Maybe<Scalars['String']>
  deliveryErrorCode?: Maybe<Scalars['String']>
  direction?: Maybe<Scalars['String']>
  from?: Maybe<SmsFrom>
  id?: Maybe<Scalars['String']>
  lastModifiedTime?: Maybe<Scalars['String']>
  messageStatus?: Maybe<Scalars['String']>
  priority?: Maybe<Scalars['String']>
  readStatus?: Maybe<Scalars['String']>
  smsDeliveryTime?: Maybe<Scalars['String']>
  smsSendingAttemptsCount?: Maybe<Scalars['Int']>
  subject?: Maybe<Scalars['String']>
  to?: Maybe<Array<Maybe<SmsTo>>>
  type?: Maybe<Scalars['String']>
}

export type SmsTo = {
  __typename?: 'SMSTo'
  extensionNumber?: Maybe<Scalars['String']>
  location?: Maybe<Scalars['String']>
  messageStatus?: Maybe<Scalars['String']>
  name?: Maybe<Scalars['String']>
  phoneNumber?: Maybe<Scalars['String']>
  target?: Maybe<Scalars['Boolean']>
}

export type SageContactSchema = {
  __typename?: 'SageContactSchema'
  broker?: Maybe<Scalars['String']>
  company?: Maybe<Scalars['String']>
  createdAt?: Maybe<Scalars['String']>
  deletedAt?: Maybe<Scalars['String']>
  email?: Maybe<Scalars['String']>
  firstName?: Maybe<Scalars['String']>
  id: Scalars['String']
  jobTitle?: Maybe<Scalars['String']>
  joinDate?: Maybe<Scalars['String']>
  lastName?: Maybe<Scalars['String']>
  occupation?: Maybe<Scalars['String']>
  phone?: Maybe<Scalars['String']>
  realStateLicense?: Maybe<Scalars['String']>
  referrer?: Maybe<Scalars['String']>
  updatedAt?: Maybe<Scalars['String']>
  yearsExperience?: Maybe<Scalars['Int']>
}

export type SaveApplicationAddressRevisionInputSchema = {
  apt?: Maybe<Scalars['String']>
  city?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['Int']>
  isAttached?: Maybe<Scalars['Boolean']>
  isMailingAddress?: Maybe<Scalars['Boolean']>
  moveInDate?: Maybe<Scalars['String']>
  moveOutDate?: Maybe<Scalars['String']>
  numberOfStories?: Maybe<Scalars['Int']>
  occupancyType?: Maybe<OccupancyType>
  rentAmount?: Maybe<Scalars['Float']>
  state?: Maybe<Scalars['String']>
  street?: Maybe<Scalars['String']>
  type?: Maybe<Scalars['String']>
  zip?: Maybe<Scalars['String']>
}

export type SaveApplicationLoanOfficerActionRevisionRequest = {
  actionStatus?: Maybe<Scalars['String']>
  applicationId: Scalars['Int']
  contactStatus?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['Int']>
  retoolId: Scalars['Int']
}

export type SaveApplicationRevisionInputSchema = {
  abadDisclosure?: Maybe<Scalars['Boolean']>
  address?: Maybe<Scalars['String']>
  address2?: Maybe<Scalars['String']>
  amortizationType?: Maybe<Scalars['String']>
  appraisalFee?: Maybe<Scalars['Float']>
  cashOut?: Maybe<Scalars['Float']>
  city?: Maybe<Scalars['String']>
  closingDate?: Maybe<Scalars['String']>
  contractMustCloseBy?: Maybe<Scalars['String']>
  countryOfCitizenship?: Maybe<Scalars['String']>
  county?: Maybe<Scalars['String']>
  creditScore?: Maybe<Scalars['Int']>
  dateOfBirth?: Maybe<Scalars['String']>
  debtToIncome?: Maybe<Scalars['Float']>
  declarationAlimony?: Maybe<Scalars['Boolean']>
  declarationBankrupcy?: Maybe<Scalars['Boolean']>
  declarationBankruptcyType?: Maybe<Scalars['String']>
  declarationBorrowedDown?: Maybe<Scalars['Boolean']>
  declarationBorrowedDownFundsAmount?: Maybe<Scalars['Float']>
  declarationCitizen?: Maybe<Scalars['Boolean']>
  declarationCitizenStatus?: Maybe<Scalars['String']>
  declarationConveyedTitle?: Maybe<Scalars['Boolean']>
  declarationEthnicity?: Maybe<Array<Maybe<Scalars['String']>>>
  declarationFhaLoan?: Maybe<Scalars['Boolean']>
  declarationForeclosures?: Maybe<Scalars['Boolean']>
  declarationGender?: Maybe<Array<Maybe<Scalars['String']>>>
  declarationHomeOwnership?: Maybe<Scalars['Boolean']>
  declarationJudgements?: Maybe<Scalars['Boolean']>
  declarationLawsuits?: Maybe<Scalars['Boolean']>
  declarationLoanDefaults?: Maybe<Scalars['Boolean']>
  declarationLoanForeclosures?: Maybe<Scalars['Boolean']>
  declarationMilitary?: Maybe<Scalars['Boolean']>
  declarationNoteEndorser?: Maybe<Scalars['Boolean']>
  declarationPrimaryResidence?: Maybe<Scalars['Boolean']>
  declarationPropertyTitle?: Maybe<Scalars['String']>
  declarationPropertyType?: Maybe<Scalars['String']>
  declarationRace?: Maybe<Array<Maybe<Scalars['String']>>>
  declarationRelationWithSeller?: Maybe<Scalars['Boolean']>
  declarationResident?: Maybe<Scalars['Boolean']>
  declarationSchooling?: Maybe<Scalars['String']>
  declarationSubjectToLien?: Maybe<Scalars['Boolean']>
  declarationUndisclosedCreditApplicationIndicator?: Maybe<Scalars['Boolean']>
  declarationVaLoan?: Maybe<Scalars['Boolean']>
  dependentsAges?: Maybe<Array<Maybe<Scalars['Int']>>>
  dueDiligence?: Maybe<Scalars['Float']>
  earnestMoney?: Maybe<Scalars['Float']>
  email?: Maybe<Scalars['String']>
  escrow?: Maybe<Scalars['Boolean']>
  expectedRentalIncome?: Maybe<Scalars['Float']>
  expenseFirstMortgage?: Maybe<Scalars['Float']>
  expenseHoaDues?: Maybe<Scalars['Float']>
  expenseHomeInsurance?: Maybe<Scalars['Float']>
  expenseMortgageInsurance?: Maybe<Scalars['Float']>
  expensePropertyTax?: Maybe<Scalars['Float']>
  expenseRent?: Maybe<Scalars['Float']>
  expenseSecondMortgage?: Maybe<Scalars['Float']>
  fipsCode?: Maybe<Scalars['String']>
  firstName?: Maybe<Scalars['String']>
  firstTimeHomeBuyer?: Maybe<Scalars['Boolean']>
  forbearance?: Maybe<Scalars['Boolean']>
  frozenCreditCheck?: Maybe<Scalars['Boolean']>
  hasRefinancedBefore?: Maybe<Scalars['Boolean']>
  homeBusiness?: Maybe<Scalars['Boolean']>
  iceBox?: Maybe<Scalars['Boolean']>
  interestRate?: Maybe<Scalars['Float']>
  isCurrentAddress?: Maybe<Scalars['Boolean']>
  isCurrentPropertyListed?: Maybe<Scalars['Boolean']>
  isRollInEscrow?: Maybe<Scalars['Boolean']>
  jointApplicantIntention?: Maybe<Scalars['Boolean']>
  lastName?: Maybe<Scalars['String']>
  latestLoanAmount?: Maybe<Scalars['Float']>
  latestLoanDate?: Maybe<Scalars['String']>
  leadGoal?: Maybe<Scalars['String']>
  loDisclosureAcknowledgedBy?: Maybe<Scalars['String']>
  loDisclosureAcknowledgedDate?: Maybe<Scalars['String']>
  loPriorityLevel?: Maybe<Scalars['String']>
  loanAmount?: Maybe<Scalars['Float']>
  loanPurpose?: Maybe<Scalars['String']>
  loanToValue?: Maybe<Scalars['Float']>
  maritalStatus?: Maybe<Scalars['String']>
  mergeExistingUserEmail?: Maybe<Scalars['Boolean']>
  militaryDetails?: Maybe<Array<Maybe<Scalars['String']>>>
  militaryServiceMember?: Maybe<Scalars['String']>
  militaryServiceTourDate?: Maybe<Scalars['String']>
  moveExplanation?: Maybe<Scalars['String']>
  nameAliases?: Maybe<Array<Maybe<NameAliasInput>>>
  needToSellBeforePurchase?: Maybe<Scalars['Boolean']>
  nextRevision?: Maybe<Scalars['Int']>
  nonSpousePropertyRights?: Maybe<Scalars['Boolean']>
  nonSpousePropertyRightsState?: Maybe<Scalars['String']>
  nonSpousePropertyRightsType?: Maybe<Scalars['String']>
  notes?: Maybe<Scalars['String']>
  originalCost?: Maybe<Scalars['Float']>
  originalLoanTerm?: Maybe<Scalars['Float']>
  phone?: Maybe<Scalars['String']>
  preapprovedWithOtherLender?: Maybe<Scalars['Boolean']>
  previousCashOut?: Maybe<Scalars['Boolean']>
  previousRefinance?: Maybe<Scalars['Boolean']>
  previousRefinanceCloseDate?: Maybe<Scalars['String']>
  promotionMentioned?: Maybe<Scalars['Boolean']>
  propertyAttachment?: Maybe<Scalars['String']>
  propertyCondoDesignType?: Maybe<Scalars['String']>
  propertyDownPayment?: Maybe<Scalars['Float']>
  propertyInspectionWaiver?: Maybe<Scalars['Boolean']>
  propertyNumberOfStories?: Maybe<Scalars['String']>
  propertyPurchasePrice?: Maybe<Scalars['Float']>
  propertyType?: Maybe<Scalars['String']>
  propertyUse?: Maybe<Scalars['String']>
  propertyValue?: Maybe<Scalars['Float']>
  propertyYearBuilt?: Maybe<Scalars['Int']>
  propertyYearPurchased?: Maybe<Scalars['Int']>
  proposedExpenseFirstMortgage?: Maybe<Scalars['Float']>
  proposedExpenseHoaDues?: Maybe<Scalars['Float']>
  proposedExpenseHomeInsurance?: Maybe<Scalars['Float']>
  proposedExpenseMortgageInsurance?: Maybe<Scalars['Float']>
  proposedExpensePropertyTax?: Maybe<Scalars['Float']>
  proposedExpenseSecondMortgage?: Maybe<Scalars['Float']>
  purchaseStage?: Maybe<Scalars['String']>
  refiReason?: Maybe<Scalars['String']>
  refinanceExplanation?: Maybe<Scalars['String']>
  remainingBalance?: Maybe<Scalars['Float']>
  revisionType?: Maybe<Scalars['String']>
  rollInFees?: Maybe<Scalars['Boolean']>
  sellerCredit?: Maybe<Scalars['Float']>
  ssnAlias?: Maybe<Scalars['String']>
  state?: Maybe<Scalars['String']>
  submissionAuthorization?: Maybe<Scalars['Boolean']>
  suffix?: Maybe<Scalars['String']>
  tcpaDisclosure?: Maybe<Scalars['Boolean']>
  termsAndCreditAuthorization?: Maybe<Scalars['Boolean']>
  termsOfUseDisclosure?: Maybe<Scalars['Boolean']>
  timeInHome?: Maybe<Scalars['String']>
  titleHolderLegalNames?: Maybe<Array<Maybe<Scalars['String']>>>
  titleMannerHeld?: Maybe<Scalars['String']>
  totalAssets?: Maybe<Scalars['Float']>
  undisclosedBorrowedFundsAmount?: Maybe<Scalars['Float']>
  undisclosedBorrowedFundsIndicator?: Maybe<Scalars['Boolean']>
  zip?: Maybe<Scalars['String']>
}

export type SaveApplicationRevisionResponse = {
  __typename?: 'SaveApplicationRevisionResponse'
  existingUserEmailDetails?: Maybe<ExistingUserEmailDetails>
  success: Scalars['Boolean']
}

export type SaveApplicationTaskInput = {
  applicationId?: Maybe<Scalars['Int']>
  status?: Maybe<TaskStatus>
  taskId?: Maybe<Scalars['Int']>
  userId?: Maybe<Scalars['Int']>
}

export type SaveCoBorrowerApplicationAddressRevisionInputSchema = {
  apt?: Maybe<Scalars['String']>
  city?: Maybe<Scalars['String']>
  coBorrowerId?: Maybe<Scalars['Int']>
  id?: Maybe<Scalars['Int']>
  isMailingAddress?: Maybe<Scalars['Boolean']>
  moveInDate?: Maybe<Scalars['String']>
  moveOutDate?: Maybe<Scalars['String']>
  occupancyType?: Maybe<OccupancyType>
  rentAmount?: Maybe<Scalars['Float']>
  state?: Maybe<Scalars['String']>
  street?: Maybe<Scalars['String']>
  type?: Maybe<Scalars['String']>
  zip?: Maybe<Scalars['String']>
}

export type SaveCoBorrowerApplicationRevisionInputSchema = {
  countryOfCitizenship?: Maybe<Scalars['String']>
  creditScore?: Maybe<Scalars['Int']>
  dateOfBirth?: Maybe<Scalars['String']>
  declarationAlimony?: Maybe<Scalars['Boolean']>
  declarationBankrupcy?: Maybe<Scalars['Boolean']>
  declarationBankruptcyType?: Maybe<Scalars['String']>
  declarationBorrowedDown?: Maybe<Scalars['Boolean']>
  declarationBorrowedDownFundsAmount?: Maybe<Scalars['Float']>
  declarationCitizen?: Maybe<Scalars['Boolean']>
  declarationCitizenStatus?: Maybe<Scalars['String']>
  declarationConveyedTitle?: Maybe<Scalars['Boolean']>
  declarationEthnicity?: Maybe<Array<Maybe<Scalars['String']>>>
  declarationFhaLoan?: Maybe<Scalars['Boolean']>
  declarationForeclosures?: Maybe<Scalars['Boolean']>
  declarationGender?: Maybe<Array<Maybe<Scalars['String']>>>
  declarationHomeOwnership?: Maybe<Scalars['Boolean']>
  declarationJudgements?: Maybe<Scalars['Boolean']>
  declarationLawsuits?: Maybe<Scalars['Boolean']>
  declarationLoanDefaults?: Maybe<Scalars['Boolean']>
  declarationLoanForeclosures?: Maybe<Scalars['Boolean']>
  declarationMilitary?: Maybe<Scalars['Boolean']>
  declarationNoteEndorser?: Maybe<Scalars['Boolean']>
  declarationPrimaryResidence?: Maybe<Scalars['Boolean']>
  declarationPropertyTitle?: Maybe<Scalars['String']>
  declarationPropertyType?: Maybe<Scalars['String']>
  declarationRace?: Maybe<Array<Maybe<Scalars['String']>>>
  declarationRelationWithSeller?: Maybe<Scalars['Boolean']>
  declarationResident?: Maybe<Scalars['Boolean']>
  declarationSchooling?: Maybe<Scalars['String']>
  declarationSubjectToLien?: Maybe<Scalars['Boolean']>
  declarationUndisclosedCreditApplicationIndicator?: Maybe<Scalars['Boolean']>
  declarationVaLoan?: Maybe<Scalars['Boolean']>
  email?: Maybe<Scalars['String']>
  financesUsedToQualify?: Maybe<Scalars['Boolean']>
  firstName?: Maybe<Scalars['String']>
  firstTimeHomeBuyer?: Maybe<Scalars['Boolean']>
  lastName?: Maybe<Scalars['String']>
  maritalStatus?: Maybe<Scalars['String']>
  marriedToPrimary?: Maybe<Scalars['Boolean']>
  mergeExistingUserEmail?: Maybe<Scalars['Boolean']>
  militaryDetails?: Maybe<Array<Maybe<Scalars['String']>>>
  militaryServiceTourDate?: Maybe<Scalars['String']>
  nameAliases?: Maybe<Array<Maybe<NameAliasInput>>>
  nextRevision?: Maybe<Scalars['Int']>
  nonSpousePropertyRights?: Maybe<Scalars['Boolean']>
  nonSpousePropertyRightsState?: Maybe<Scalars['String']>
  nonSpousePropertyRightsType?: Maybe<Scalars['String']>
  phone?: Maybe<Scalars['String']>
  revisionType?: Maybe<Scalars['String']>
  sameAddressAsPrimary?: Maybe<Scalars['Boolean']>
  ssnAlias?: Maybe<Scalars['String']>
  suffix?: Maybe<Scalars['String']>
  termsAndCreditAuthorization?: Maybe<Scalars['Boolean']>
  undisclosedBorrowedFundsAmount?: Maybe<Scalars['Float']>
  undisclosedBorrowedFundsIndicator?: Maybe<Scalars['Boolean']>
}

export type SaveLiabilityRevisionInputSchema = {
  accountNumber?: Maybe<Scalars['String']>
  apt?: Maybe<Scalars['String']>
  city?: Maybe<Scalars['String']>
  creditLine?: Maybe<Scalars['Float']>
  creditLoanType?: Maybe<Scalars['String']>
  creditReportId?: Maybe<Scalars['Int']>
  creditor?: Maybe<Scalars['String']>
  currentHousingExpense?: Maybe<Scalars['Boolean']>
  id?: Maybe<Scalars['Int']>
  includesEscrows?: Maybe<Scalars['Boolean']>
  liabilityType?: Maybe<Scalars['String']>
  monthlyExpense?: Maybe<Scalars['Float']>
  mortgageType?: Maybe<Scalars['String']>
  omit?: Maybe<Scalars['Boolean']>
  otherDescription?: Maybe<Scalars['String']>
  ownershipType?: Maybe<Scalars['String']>
  partOfPurchase?: Maybe<Scalars['Boolean']>
  remainingTermMonths?: Maybe<Scalars['Int']>
  reoId?: Maybe<Scalars['Int']>
  resubordinate?: Maybe<Scalars['Boolean']>
  state?: Maybe<Scalars['String']>
  street?: Maybe<Scalars['String']>
  toBePaidOff?: Maybe<Scalars['Boolean']>
  totalTermMonths?: Maybe<Scalars['Int']>
  unpaidBalance?: Maybe<Scalars['Float']>
  zip?: Maybe<Scalars['String']>
}

export type SaveRealEstateOwnedInputSchema = {
  application_id?: Maybe<Scalars['Int']>
  apt?: Maybe<Scalars['String']>
  city?: Maybe<Scalars['String']>
  currentOccupancy?: Maybe<Scalars['String']>
  expenses?: Maybe<Scalars['Float']>
  grossRentalIncome?: Maybe<Scalars['Float']>
  hoaDuesMonthly?: Maybe<Scalars['Float']>
  id?: Maybe<Scalars['Int']>
  intendedOccupancy?: Maybe<Scalars['String']>
  intendedType?: Maybe<Scalars['String']>
  isCurrent?: Maybe<Scalars['Boolean']>
  isSubject?: Maybe<Scalars['Boolean']>
  maintenanceExpensesMonthly?: Maybe<Scalars['Float']>
  netProceeds?: Maybe<Scalars['Float']>
  netRentalIncome?: Maybe<Scalars['Float']>
  numberOfUnits?: Maybe<Scalars['Int']>
  occupancyRate?: Maybe<Scalars['Float']>
  propertyInsuranceMonthly?: Maybe<Scalars['Float']>
  propertyTaxesMonthly?: Maybe<Scalars['Float']>
  propertyType?: Maybe<Scalars['String']>
  propertyValue?: Maybe<Scalars['Float']>
  state?: Maybe<Scalars['String']>
  status?: Maybe<Scalars['String']>
  street?: Maybe<Scalars['String']>
  type?: Maybe<Scalars['String']>
  zip?: Maybe<Scalars['String']>
}

export type SaveSageContactAssignmentInput = {
  applicationId?: Maybe<Scalars['Int']>
  assignmentId?: Maybe<Scalars['String']>
  borrowerPhone?: Maybe<Scalars['String']>
  contactId?: Maybe<Scalars['String']>
  dateEnded?: Maybe<Scalars['String']>
  dateSigned?: Maybe<Scalars['String']>
  referredBorrower?: Maybe<Scalars['Boolean']>
}

export type SaveSageContactInput = {
  broker?: Maybe<Scalars['String']>
  city?: Maybe<Scalars['String']>
  company?: Maybe<Scalars['String']>
  contactId?: Maybe<Scalars['String']>
  email?: Maybe<Scalars['String']>
  firstName?: Maybe<Scalars['String']>
  jobTitle?: Maybe<Scalars['String']>
  joinDate?: Maybe<Scalars['String']>
  lastName?: Maybe<Scalars['String']>
  occupation?: Maybe<Scalars['String']>
  phone?: Maybe<Scalars['String']>
  realStateLicense?: Maybe<Scalars['String']>
  referrer?: Maybe<Scalars['String']>
  state?: Maybe<Scalars['String']>
  yearsExperience?: Maybe<Scalars['Int']>
}

export type SaveTaskInput = {
  configuration?: Maybe<Scalars['JSON']>
  name?: Maybe<Scalars['String']>
  type?: Maybe<TaskType>
}

export type Section = {
  __typename?: 'Section'
  fees: Array<SectionFees>
  total: Scalars['Float']
}

export type SectionFees = {
  __typename?: 'SectionFees'
  amount: Scalars['Float']
  description: Scalars['String']
  lineNumber?: Maybe<Scalars['String']>
}

export type SectionFeesInput = {
  amount: Scalars['Float']
  description: Scalars['String']
  lineNumber?: Maybe<Scalars['String']>
}

export type SectionFeesOptional = {
  __typename?: 'SectionFeesOptional'
  fees?: Maybe<Array<SectionFees>>
  total: Scalars['Float']
}

export type SectionFeesOptionalInput = {
  fees?: Maybe<Array<SectionFeesInput>>
  total: Scalars['Float']
}

export type SectionInput = {
  fees: Array<SectionFeesInput>
  total: Scalars['Float']
}

export enum SelfEmploymentBusinessType {
  Corporation = 'Corporation',
  Llc = 'LLC',
  Partnership = 'Partnership',
  SCorp = 'SCorp',
  SoleProprietorship = 'SoleProprietorship',
}

export type SendSmsResponse = {
  __typename?: 'SendSMSResponse'
  from?: Maybe<Scalars['String']>
  message?: Maybe<Scalars['String']>
  to?: Maybe<Array<Maybe<Scalars['String']>>>
}

export type Sender = {
  __typename?: 'Sender'
  isLO?: Maybe<Scalars['Boolean']>
  name?: Maybe<Scalars['String']>
  phone?: Maybe<Scalars['String']>
}

export type SetConfigurationArgs = {
  configurationFields?: Maybe<ConfigurationFieldInput>
  loanOfficerId?: Maybe<Scalars['Int']>
}

export type SoftPullResponse = {
  __typename?: 'SoftPullResponse'
  mortgageRateEstimate?: Maybe<Array<Maybe<MortgageEstimate>>>
  success: Scalars['Boolean']
}

export enum SortDirectionType {
  Asc = 'ASC',
  Desc = 'DESC',
}

export type SortInput = {
  direction?: Maybe<SortDirectionType>
  field?: Maybe<Scalars['String']>
}

export enum StateAbbreviation {
  Ak = 'AK',
  Al = 'AL',
  Ar = 'AR',
  Az = 'AZ',
  Ca = 'CA',
  Co = 'CO',
  Ct = 'CT',
  Dc = 'DC',
  De = 'DE',
  Fl = 'FL',
  Ga = 'GA',
  Hi = 'HI',
  Ia = 'IA',
  Id = 'ID',
  Il = 'IL',
  In = 'IN',
  Ks = 'KS',
  Ky = 'KY',
  La = 'LA',
  Ma = 'MA',
  Md = 'MD',
  Me = 'ME',
  Mi = 'MI',
  Mn = 'MN',
  Mo = 'MO',
  Ms = 'MS',
  Mt = 'MT',
  Nc = 'NC',
  Nd = 'ND',
  Ne = 'NE',
  Nh = 'NH',
  Nj = 'NJ',
  Nm = 'NM',
  Nv = 'NV',
  Ny = 'NY',
  Oh = 'OH',
  Ok = 'OK',
  Or = 'OR',
  Pa = 'PA',
  Ri = 'RI',
  Sc = 'SC',
  Sd = 'SD',
  Tn = 'TN',
  Tx = 'TX',
  Ut = 'UT',
  Va = 'VA',
  Vt = 'VT',
  Wa = 'WA',
  Wi = 'WI',
  Wv = 'WV',
  Wy = 'WY',
}

export type StateResult = {
  __typename?: 'StateResult'
  state?: Maybe<Scalars['String']>
}

export enum Status {
  NtlRatesDisplayed = 'NTLRatesDisplayed',
  QCreateAccountSectionTouched = 'QCreateAccountSectionTouched',
  QDeclarationsSectionTouched = 'QDeclarationsSectionTouched',
  QEmploymentSectionTouched = 'QEmploymentSectionTouched',
  QExpensesSectionTouched = 'QExpensesSectionTouched',
  QFormCompleted = 'QFormCompleted',
  QNoRatesDisplayed = 'QNoRatesDisplayed',
  QPersonalInfoSectionTouched = 'QPersonalInfoSectionTouched',
  QPropertyInfoSectionTouched = 'QPropertyInfoSectionTouched',
  QRateSelected = 'QRateSelected',
  QRatesDisplayed = 'QRatesDisplayed',
  AdditionalConditionsSent = 'additionalConditionsSent',
  AlfieCompleted = 'alfieCompleted',
  ApplicationCreated = 'applicationCreated',
  ApplyFlowCompleted = 'applyFlowCompleted',
  ApplyFlowTouched = 'applyFlowTouched',
  Approved = 'approved',
  BsmApplicationCreated = 'bsmApplicationCreated',
  BsmApplicationStarted = 'bsmApplicationStarted',
  BsmApplicationVerified = 'bsmApplicationVerified',
  BsmAppraisal = 'bsmAppraisal',
  BsmArchived = 'bsmArchived',
  BsmCanceled = 'bsmCanceled',
  BsmChoseLoan = 'bsmChoseLoan',
  BsmClosed = 'bsmClosed',
  BsmClosing = 'bsmClosing',
  BsmDeclined = 'bsmDeclined',
  BsmImportWaiting = 'bsmImportWaiting',
  BsmOnHold = 'bsmOnHold',
  BsmPendingCancellation = 'bsmPendingCancellation',
  BsmPendingEscalatedReview = 'bsmPendingEscalatedReview',
  BsmPendingWithdrawal = 'bsmPendingWithdrawal',
  BsmSkippedImportBankAccount = 'bsmSkippedImportBankAccount',
  BsmSubmittedApplication = 'bsmSubmittedApplication',
  BsmUnderwriting = 'bsmUnderwriting',
  BsmVerifiedIncomeAndExpenses = 'bsmVerifiedIncomeAndExpenses',
  BsmWaitingForCoBorrower = 'bsmWaitingForCoBorrower',
  BsmWithdrawn = 'bsmWithdrawn',
  CallRequested = 'callRequested',
  ClearToClose = 'clearToClose',
  ClearToPurchase = 'clearToPurchase',
  ConditionReview = 'conditionReview',
  CounterOfferApproved = 'counterOfferApproved',
  DocsBack = 'docsBack',
  DocsDrawn = 'docsDrawn',
  DocsOrdered = 'docsOrdered',
  DocsOut = 'docsOut',
  DocumentCheck = 'documentCheck',
  DocumentCheckFailed = 'documentCheckFailed',
  FinalDocs = 'finalDocs',
  FinalUnderwriting = 'finalUnderwriting',
  Funded = 'funded',
  InUnderwriting = 'inUnderwriting',
  InboundCallAnswered = 'inboundCallAnswered',
  InboundCallUnanswered = 'inboundCallUnanswered',
  InvestorConditions = 'investorConditions',
  InvestorConditionsSent = 'investorConditionsSent',
  LeadReceived = 'leadReceived',
  LenderAdditionalInfoNeeded = 'lenderAdditionalInfoNeeded',
  LenderAdverse = 'lenderAdverse',
  LenderApplicationApprovedNotAccepted = 'lenderApplicationApprovedNotAccepted',
  LenderApplicationLocked = 'lenderApplicationLocked',
  LenderApproved = 'lenderApproved',
  LenderApprovedPendingLock = 'lenderApprovedPendingLock',
  LenderApprovedWithConditions = 'lenderApprovedWithConditions',
  LenderApprovedWithConditionsNewConditions = 'lenderApprovedWithConditionsNewConditions',
  LenderCicPending = 'lenderCICPending',
  LenderCancelled = 'lenderCancelled',
  LenderCancelledDuplicate = 'lenderCancelledDuplicate',
  LenderCancelledOther = 'lenderCancelledOther',
  LenderClearToClose = 'lenderClearToClose',
  LenderClosed = 'lenderClosed',
  LenderClosedPackageReceivedFromTitle = 'lenderClosedPackageReceivedFromTitle',
  LenderClosing = 'lenderClosing',
  LenderClosingConditionsAvailable = 'lenderClosingConditionsAvailable',
  LenderClosingDisclosureSent = 'lenderClosingDisclosureSent',
  LenderClosingOrSigningHasBeenScheduled = 'lenderClosingOrSigningHasBeenScheduled',
  LenderClosingPackageReceived = 'lenderClosingPackageReceived',
  LenderCollateralReview = 'lenderCollateralReview',
  LenderCollateralSentToWarehouse = 'lenderCollateralSentToWarehouse',
  LenderCompletion = 'lenderCompletion',
  LenderConditionalApproval = 'lenderConditionalApproval',
  LenderConditionallyApproved = 'lenderConditionallyApproved',
  LenderCreditPackageSubmitted = 'lenderCreditPackageSubmitted',
  LenderCreditReview = 'lenderCreditReview',
  LenderDeclined = 'lenderDeclined',
  LenderDenied = 'lenderDenied',
  LenderDiligenceReview = 'lenderDiligenceReview',
  LenderDisclosureSubmitted = 'lenderDisclosureSubmitted',
  LenderDocsOut = 'lenderDocsOut',
  LenderDocsOutToSettlementAgent = 'lenderDocsOutToSettlementAgent',
  LenderEscalatedReview = 'lenderEscalatedReview',
  LenderFileClosedForIncompleteness = 'lenderFileClosedForIncompleteness',
  LenderFileImported = 'lenderFileImported',
  LenderFileImportedProblemUpload = 'lenderFileImportedProblemUpload',
  LenderFinalApproval = 'lenderFinalApproval',
  LenderFinalClosingDisclosureInReview = 'lenderFinalClosingDisclosureInReview',
  LenderFinalClosingDisclosureReviewComplete = 'lenderFinalClosingDisclosureReviewComplete',
  LenderFullConditionsSignOff = 'lenderFullConditionsSignOff',
  LenderFullPackageReceivedAwaitingReview = 'lenderFullPackageReceivedAwaitingReview',
  LenderFullPackageReceivedIncomplete = 'lenderFullPackageReceivedIncomplete',
  LenderFunded = 'lenderFunded',
  LenderFundingComplete = 'lenderFundingComplete',
  LenderInClosing = 'lenderInClosing',
  LenderInPreclear = 'lenderInPreclear',
  LenderIncompleteSubmission = 'lenderIncompleteSubmission',
  LenderInquiryOnly = 'lenderInquiryOnly',
  LenderIntentToProceedReviewCompleted = 'lenderIntentToProceedReviewCompleted',
  LenderLeOnlyDisclosure = 'lenderLEOnlyDisclosure',
  LenderLoanCreated = 'lenderLoanCreated',
  LenderLoanDisbursedByLender = 'lenderLoanDisbursedByLender',
  LenderLoanRegistered = 'lenderLoanRegistered',
  LenderLoanRescinded = 'lenderLoanRescinded',
  LenderLoanSubmitted = 'lenderLoanSubmitted',
  LenderPmDocsOut = 'lenderPMDocsOut',
  LenderPmFunded = 'lenderPMFunded',
  LenderPmSuspended = 'lenderPMSuspended',
  LenderPartialConditionsSignOff = 'lenderPartialConditionsSignOff',
  LenderPostClosing = 'lenderPostClosing',
  LenderPreclearQueue = 'lenderPreclearQueue',
  LenderRegistered = 'lenderRegistered',
  LenderRegistrationIncomplete = 'lenderRegistrationIncomplete',
  LenderRejected = 'lenderRejected',
  LenderRequestClosingDocs = 'lenderRequestClosingDocs',
  LenderRequestForDisbursement = 'lenderRequestForDisbursement',
  LenderRescinded = 'lenderRescinded',
  LenderSetup = 'lenderSetup',
  LenderStarted = 'lenderStarted',
  LenderSubmittedToUnderwriting = 'lenderSubmittedToUnderwriting',
  LenderSuspended = 'lenderSuspended',
  LenderUwmSuspended = 'lenderUWMSuspended',
  LenderUnderwriting = 'lenderUnderwriting',
  LenderUnknownStatus = 'lenderUnknownStatus',
  LenderUnprocessedCancellation = 'lenderUnprocessedCancellation',
  LenderUnprocessedDecline = 'lenderUnprocessedDecline',
  LenderWaitingForFullPackage = 'lenderWaitingForFullPackage',
  LenderWholesaleClearToClose = 'lenderWholesaleClearToClose',
  LenderWithdrawn = 'lenderWithdrawn',
  LoanCanceled = 'loanCanceled',
  LoanClosed = 'loanClosed',
  LoanDenied = 'loanDenied',
  LoanOnHold = 'loanOnHold',
  LoanOpen = 'loanOpen',
  LoanPurchased = 'loanPurchased',
  LoanRejected = 'loanRejected',
  LoanShipped = 'loanShipped',
  LoanSold = 'loanSold',
  LoanSuspended = 'loanSuspended',
  LoanWithdrawn = 'loanWithdrawn',
  LockRejected = 'lockRejected',
  LockRequested = 'lockRequested',
  Migrated = 'migrated',
  PhoneFlowCompleted = 'phoneFlowCompleted',
  PhoneFlowTouched = 'phoneFlowTouched',
  PreApproved = 'preApproved',
  PreDocQc = 'preDocQC',
  PrePricingFlowCompleted = 'prePricingFlowCompleted',
  PrePricingFlowTouched = 'prePricingFlowTouched',
  PrePricingNoRatesDisplayed = 'prePricingNoRatesDisplayed',
  PrePricingRatesDisplayed = 'prePricingRatesDisplayed',
  PreProcessing = 'preProcessing',
  PreQual = 'preQual',
  PreUnderwriting = 'preUnderwriting',
  Processing = 'processing',
  ReApp = 'reApp',
  ReadyForSale = 'readyForSale',
  Recorded = 'recorded',
  Registered = 'registered',
  SageQbsmTakeoverNoRatesDisplayed = 'sageQBSMTakeoverNoRatesDisplayed',
  SageQbsmTakeoverRatesDisplayed = 'sageQBSMTakeoverRatesDisplayed',
  SageQNoRatesDisplayed = 'sageQNoRatesDisplayed',
  SageQRateSelected = 'sageQRateSelected',
  SageQRatesDisplayed = 'sageQRatesDisplayed',
  Submitted = 'submitted',
}

export type StoryblokSchemaLink = {
  __typename?: 'StoryblokSchemaLink'
  cached_url?: Maybe<Scalars['String']>
  fieldtype?: Maybe<Scalars['String']>
  linktype?: Maybe<Scalars['String']>
  url?: Maybe<Scalars['String']>
}

export type SuccessPage = {
  __typename?: 'SuccessPage'
  body?: Maybe<Scalars['String']>
  component: Scalars['String']
  name: Scalars['String']
  primaryCtaLink?: Maybe<StoryblokSchemaLink>
  primaryCtaTitle?: Maybe<Scalars['String']>
  secondaryCtaLink?: Maybe<StoryblokSchemaLink>
  secondaryCtaTitle?: Maybe<Scalars['String']>
  subtitle?: Maybe<Scalars['String']>
  title?: Maybe<Scalars['String']>
}

export type SyncStatuses = {
  __typename?: 'SyncStatuses'
  conditions?: Maybe<ApplicationExternalEventSchema>
  conversations?: Maybe<ApplicationExternalEventSchema>
  creation?: Maybe<ApplicationExternalEventSchema>
  credit?: Maybe<ApplicationExternalEventSchema>
  eConsent?: Maybe<ApplicationExternalEventSchema>
  import?: Maybe<ApplicationExternalEventSchema>
  license?: Maybe<ApplicationExternalEventSchema>
  pricingScenario?: Maybe<ApplicationExternalEventSchema>
  template?: Maybe<ApplicationExternalEventSchema>
  texas?: Maybe<ApplicationExternalEventSchema>
  title?: Maybe<ApplicationExternalEventSchema>
}

export type Task = {
  __typename?: 'Task'
  configuration?: Maybe<Scalars['JSON']>
  id?: Maybe<Scalars['Int']>
  name?: Maybe<Scalars['String']>
  type?: Maybe<TaskType>
}

export enum TaskStatus {
  Completed = 'completed',
  InProgress = 'inProgress',
  Requested = 'requested',
}

export enum TaskType {
  MiniFlow = 'miniFlow',
}

export type TimeZoneResult = {
  __typename?: 'TimeZoneResult'
  offSetTime?: Maybe<Scalars['Int']>
  timeZoneName?: Maybe<Scalars['String']>
}

export type TrackingLink = {
  __typename?: 'TrackingLink'
  link?: Maybe<Scalars['String']>
  pixel?: Maybe<Scalars['String']>
}

export type TridCountData = {
  __typename?: 'TridCountData'
  address?: Maybe<Scalars['String']>
  applicationId?: Maybe<Scalars['Int']>
  dateTriggered?: Maybe<Scalars['String']>
  dueDate?: Maybe<Scalars['String']>
  firstName?: Maybe<Scalars['String']>
  iceboxId?: Maybe<Scalars['Int']>
  iceboxNote?: Maybe<Scalars['String']>
  lastName?: Maybe<Scalars['String']>
  lenderLoanId?: Maybe<Scalars['String']>
  lenderName?: Maybe<Scalars['String']>
  loanOfficerName?: Maybe<Scalars['String']>
  mslName?: Maybe<Scalars['String']>
  source?: Maybe<Scalars['String']>
  state?: Maybe<Scalars['String']>
  tridDaysLeft?: Maybe<Scalars['Int']>
}

export type TridCountRequest = {
  excludeIcebox?: Maybe<Scalars['Boolean']>
  triggeredOnOrAfter: Scalars['String']
}

export type TridMetadataSchema = {
  __typename?: 'TridMetadataSchema'
  applicationId?: Maybe<Scalars['Int']>
  createdAt?: Maybe<Scalars['String']>
  dateTriggered?: Maybe<Scalars['String']>
  deletedAt?: Maybe<Scalars['String']>
  disclosedAt?: Maybe<Scalars['String']>
  id: Scalars['Int']
  income?: Maybe<Scalars['Boolean']>
  loanAmount?: Maybe<Scalars['Boolean']>
  name?: Maybe<Scalars['Boolean']>
  propertyAddress?: Maybe<Scalars['Boolean']>
  propertyValue?: Maybe<Scalars['Boolean']>
  ssnAlias?: Maybe<Scalars['Boolean']>
  tridCount?: Maybe<Scalars['Int']>
  updatedAt?: Maybe<Scalars['String']>
}

export type UpdateAttachmentInput = {
  isAccepted?: Maybe<Scalars['Boolean']>
  uuid?: Maybe<Scalars['String']>
}

export type UpdateLenderLoanIdRequest = {
  applicationId: Scalars['Int']
  lenderLoanId: Scalars['String']
}

export type UpdateLoanSetupChecklistItemRequest = {
  checkedAt: Scalars['String']
  checkedBy: Scalars['Int']
  checklistItemId: Scalars['Int']
}

export type UpdateLockFormRequest = {
  administrator?: Maybe<Scalars['String']>
  applicationId: Scalars['Int']
  besmarteeLoanId?: Maybe<Scalars['String']>
  dateLocked?: Maybe<Scalars['String']>
  disclosureSetupAdmin?: Maybe<Scalars['String']>
  isManual?: Maybe<Scalars['Boolean']>
  lenderLoanId?: Maybe<Scalars['String']>
  loanSetupAdmin?: Maybe<Scalars['String']>
  loanSetupAnalyst?: Maybe<Scalars['String']>
  lockDeskNotes?: Maybe<Scalars['String']>
  lockFormId: Scalars['Int']
  lockProgress?: Maybe<Scalars['String']>
  manualEsign?: Maybe<Scalars['String']>
  notes?: Maybe<Scalars['String']>
  processingTeam?: Maybe<Scalars['String']>
}

export type User = {
  __typename?: 'User'
  auth0Id?: Maybe<Scalars['String']>
  createdAt?: Maybe<Scalars['String']>
  deletedAt?: Maybe<Scalars['String']>
  email?: Maybe<Scalars['String']>
  id: Scalars['Int']
  ipAddress?: Maybe<Scalars['String']>
  timeZone?: Maybe<Scalars['String']>
  timeZoneOffset?: Maybe<Scalars['Float']>
  updatedAt?: Maybe<Scalars['String']>
}

export type UserApplicationSchema = {
  __typename?: 'UserApplicationSchema'
  address?: Maybe<Scalars['String']>
  address2?: Maybe<Scalars['String']>
  applicationGuid?: Maybe<Scalars['String']>
  applicationId?: Maybe<Scalars['Int']>
  borrowerInfo?: Maybe<BorrowerInfoSchema>
  borrowerPortalStatus?: Maybe<Scalars['String']>
  city?: Maybe<Scalars['String']>
  coBorrowerInfo?: Maybe<BorrowerInfoSchema>
  createdAt?: Maybe<Scalars['String']>
  elloStatus?: Maybe<Scalars['String']>
  isIceBox?: Maybe<Scalars['Boolean']>
  lenderLoanId?: Maybe<Scalars['String']>
  loanPurpose?: Maybe<Scalars['String']>
  state?: Maybe<Scalars['String']>
  zip?: Maybe<Scalars['String']>
}

export type UserLocation = {
  __typename?: 'UserLocation'
  userLatitude?: Maybe<Scalars['Float']>
  userLongitude?: Maybe<Scalars['Float']>
}

export type ValidationCondition = {
  __typename?: 'ValidationCondition'
  is: Scalars['String']
  reference: Scalars['String']
}

export type ValidationRule = {
  __typename?: 'ValidationRule'
  component?: Maybe<Scalars['String']>
  name: Scalars['String']
  params: Scalars['String']
}

export type Validations = {
  __typename?: 'Validations'
  component?: Maybe<Scalars['String']>
  condition?: Maybe<Array<Maybe<ValidationCondition>>>
  rules?: Maybe<Array<Maybe<ValidationRule>>>
  validatorType: Scalars['String']
}

export type VerifyRateLockRequest = {
  discountOrCreditAmount?: Maybe<Scalars['Float']>
  discountPoints?: Maybe<Scalars['Float']>
  discountPointsAsDollarAmount?: Maybe<Scalars['Float']>
  lockFormId: Scalars['Int']
  lockId?: Maybe<Scalars['String']>
  principleAndInterestPayment?: Maybe<Scalars['Float']>
  rate?: Maybe<Scalars['Float']>
}

export type VerifyWithLastFourPhoneResult = {
  __typename?: 'VerifyWithLastFourPhoneResult'
  applicationId?: Maybe<Scalars['Int']>
  hasAccount?: Maybe<Scalars['Boolean']>
  phoneVerified?: Maybe<Scalars['Boolean']>
  zipCodeVerified?: Maybe<Scalars['Boolean']>
}

export type Veteran = {
  disabled?: Maybe<Scalars['Boolean']>
  firstTimeUsingVaLoan?: Maybe<Scalars['Boolean']>
  type?: Maybe<VeteranType>
}

export enum VeteranType {
  NationalGuard = 'NationalGuard',
  NotApplicable = 'NotApplicable',
  RegularMilitary = 'RegularMilitary',
  Reserves = 'Reserves',
  SpouseOfRegularMilitary = 'SpouseOfRegularMilitary',
  SpouseOfReserveMilitary = 'SpouseOfReserveMilitary',
}

export type VisibleIfCondition =
  | VisibleIfMultiCondition
  | VisibleIfPrebuiltCondition
  | VisibleIfSingleCondition

export type VisibleIfMultiCondition = {
  __typename?: 'VisibleIfMultiCondition'
  conditions: Array<VisibleIfCondition>
  operator: Scalars['String']
}

export type VisibleIfPrebuiltCondition = {
  __typename?: 'VisibleIfPrebuiltCondition'
  name: Scalars['String']
}

export type VisibleIfSingleCondition = {
  __typename?: 'VisibleIfSingleCondition'
  answer: Scalars['String']
  conditional: Scalars['String']
  question: Scalars['String']
}

export type InitializeResponse = {
  __typename?: 'initializeResponse'
  applicationGuid: Scalars['String']
  applicationId: Scalars['Int']
  applicationLeadSource?: Maybe<Scalars['String']>
}

export type LoanEstimateSections = {
  __typename?: 'loanEstimateSections'
  A: Section
  B: Section
  C: Section
  D: Section
  E: Section
  F: Section
  G: Section
  H: SectionFeesOptional
  I: Section
  J: Section
  Others: Section
}
