import {
  EventName,
  FormContext,
  FormName,
  FormBrand,
} from '@mortgage-pos/types'

export const eventTypeMap = {
  [EventName.Identify]: 'core.Identify.v1',
  [EventName.CohesionBSMBridge]: 'bankrate.CohesionBSMBridge.v1',
  [EventName.FormStarted]: 'redventures.usertracking.v3.FormStarted',
  [EventName.FormContinued]: 'redventures.usertracking.v3.FormContinued',
  [EventName.FormSubmitted]: 'redventures.usertracking.v3.FormSubmitted',
  [EventName.FieldInputted]: 'redventures.usertracking.v3.FieldInputted',
  [EventName.FormErrored]: 'redventures.usertracking.v3.FormErrored',
  [EventName.LoanTekCall]: 'sage.LoanTekCall.v2',
  [EventName.LoanTekErrored]: 'sage.LoanTekErrored.v1',
  [EventName.LoanTekResponse]: 'sage.LoanTekResponse.v2',
  [EventName.BeSmarteePortal]: 'sage.BeSmarteePortal.v1',
  [EventName.LOAssigned]: 'sage.LOAssigned.v1',
  [EventName.LOAssignedV2]: 'sage.LOAssigned.v2',
  [EventName.AccountCreated]: 'sage.AccountCreated.v1',
  [EventName.ApplicationStarted]: 'sage.applicationStarted.v1',
  [EventName.ApplicationStartedV2]: 'sage.applicationStarted.v2',
  [EventName.ApplicationRevised]: 'sage.ApplicationRevised.v2',
  [EventName.ApplicationStatusUpdated]: 'sage.applicationStatusUpdated.v1',
  [EventName.LeadRecieved]: 'sage.LeadReceived.v1',
  [EventName.VelocifyLeadUpdated]: 'interest.VelocifyLeadUpdated.v2',
  [EventName.VelocifyLeadCompleted]: 'interest.VelocifyLeadCompleted.v1',
  [EventName.QApplicationLOAssigned]: 'bankrate.QApplication.LOAssigned.v1',
  [EventName.QApplicationLoanTekCall]: 'bankrate.QApplication.LoanTekCall.v2',
  [EventName.QApplicationLeadReceived]: 'bankrate.QApplication.LeadReceived.v1',
  [EventName.QApplicationLoanTekErrored]:
    'bankrate.QApplication.LoanTekErrored.v1',
  [EventName.QApplicationLoanTekResponse]:
    'bankrate.QApplication.LoanTekResponse.v2',
  [EventName.QApplicationApplicationStarted]:
    'bankrate.QApplication.applicationStarted.v1',
  [EventName.QApplicationApplicationRevised]:
    'bankrate.QApplication.ApplicationRevised.v2',
  [EventName.QApplicationStatusUpdated]:
    'bankrate.QApplication.applicationStatusUpdated.v1',
  [EventName.ProcessorAssigned]: 'sage.ProcessorAssigned.v1',
  [EventName.ProcessorAssignedV2]: 'sage.ProcessorAssigned.v2',
  [EventName.DocumentUploaded]: 'sage.DocumentUploaded.v1',
  [EventName.MessageSent]: 'sage.MessageSent.v2',
  [EventName.LoanLocked]: 'sage.LoanLocked.v1',
  [EventName.lockDeskCompleted]: 'sage.LockDeskCompleted.v1',
  [EventName.DocumentAdded]: 'sage.DocumentAdded.v1',
  [EventName.DocumentReviewed]: 'sage.DocumentReviewed.v1',
  [EventName.BorrowerLogin]: 'sage.BorrowerLogin.v1',
  [EventName.ElementClicked]: 'redventures.usertracking.v3.ElementClicked',
  [EventName.ElementViewed]: 'redventures.usertracking.v3.ElementViewed',
  [EventName.ProductClicked]: 'redventures.ecommerce.v1.ProductClicked',
  [EventName.ProductViewed]: 'redventures.ecommerce.v1.ProductViewed',
  [EventName.WelcomeEmailV3]: 'sage.WelcomeEmail.v3',
  [EventName.ConditionCreated]: 'sage.ConditionCreated.v2',
  [EventName.ConditionCompleted]: 'sage.ConditionCompleted.v1',
  [EventName.ConditionAction]: 'sage.ConditionAction.v1',
  [EventName.PreQualify]: 'sage.ApplicationPrequalified.v1',
  [EventName.PageViewed]: 'redventures.usertracking.v2.PageViewed',
  [EventName.LoanToUnderwriting]: 'sage.LoanToUnderwriting.v1',
  [EventName.LoanFunded]: 'sage.LoanFunded.v1',
  [EventName.LoanEsigned]: 'sage.LenderEsigned.v1',
  [EventName.LoanConditionallyApproved]: 'sage.LoanConditionallyApproved.v1',
  [EventName.LoanClearToClose]: 'sage.LoanClearToClose.v1',
  [EventName.LenderLocked]: 'sage.LenderLocked.v1',
  [EventName.LockedRate]: 'sage.LockedRate.v3',
  [EventName.ApplicationRevisedV3]: 'sage.ApplicationRevised.v3',
  [EventName.Login]: 'identity.Login.v1',
  [EventName.Logout]: 'identity.Logout.v1',
  [EventName.SignUp]: 'identity.SignUp.v1',
  [EventName.PasswordReset]: 'identity.PasswordReset.v1',
  [EventName.CustomerContacted]: 'sage.CustomerContacted.v1',
  [EventName.PlaidAccountConnected]: 'sage.PlaidAccountConnected.v1',
  [EventName.UploadDocumentManually]: 'sage.UploadDocumentManually.v1',
}

export const formContext: FormContext = {
  formVersion: '0',
  formId: 'default',
  formName: FormName.AlfieApplication,
  formType: 'Lead_Form',
  formBrand: FormBrand.Sage,
}

export const baseExternalIdData = {
  collection: 'users',
  encoding: 'none',
}
